import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { AlarmModel } from '@models/alarm.model';
import { ScheduleModel } from '@models/schedule.model';

@Injectable()
export class AlarmService {
  constructor(private http: HttpClient) {
  }

  public getAlarms(filters?: AlarmModel.QueryFilters): Observable<AlarmModel.AlarmDocument[]> {
    let url = `${api.alarm.crud}`;
    if (filters?.query) {
      url = `${url}?query=${filters.query}`;
    }
    return this.http.get<AlarmModel.AlarmDocument[]>(url);
  }

  public create(dto: AlarmModel.AlarmCreateDto): Observable<AlarmModel.AlarmDocument> {
    let url = `${api.alarm.crud}?`;
    return this.http.post<AlarmModel.AlarmDocument>(url, dto);
  }

  public enable(alarmId: string, enabled: boolean): Observable<AlarmModel.AlarmDocument> {
    let url = `${api.alarm.crud}/toggle/${alarmId}`;
    return this.http.post<AlarmModel.AlarmDocument>(url, { enabled });
  }

  public delete(alarmId: string): Observable<boolean> {
    let url = `${api.alarm.crud}/${alarmId}`;
    return this.http.delete<boolean>(url);
  }

  public getAlarmById(id: string): Observable<AlarmModel.AlarmDocument> {
    let url = `${api.alarm.crud}/${id}`;
    return this.http.get<AlarmModel.AlarmDocument>(url);
  }

  public update(dto: AlarmModel.AlarmDocument): Observable<boolean> {
    return this.http.patch<boolean>(`${api.alarm.crud}/${dto._id}`, dto);
  }

  public syncEdges(alarmId: string): Observable<any> {
    return this.http.post<boolean>(api.alarm.syncEdges(alarmId), {});
  }
}

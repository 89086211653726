<div *ngIf="{
isExpanded:isExpanded$|async
} as conditions" class="location-row flex-col"
>
  <div class="flex-row space-between-center c-p" (click)="toggleExpand()">
    <div class="flex-row start-center gap-8">
      <span class="ui-icon-location"></span>
      {{ location.name }}
    </div>
    <span class="ui-icon-expand-arrow"></span>
  </div>
  <div *ngIf="conditions.isExpanded" class="mt-16 gap-8">
    @for (edge of edges; track edge._id; let i = $index) {
      <core-row [core]="edge" [isExpanded]="i === 0">

      </core-row>
    }
  </div>
</div>

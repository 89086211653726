import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LocationModel } from '../../../../../../locations/location.model';

@Component({
  selector: 'v2-camera-row',
  templateUrl: './camera-row.component.html',
  styleUrl: './camera-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraRowComponent {
  @Input() camera: LocationModel.LocationCameraItem;
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Edge } from '../../../../../../edge/edge.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocationModel } from '../../../../../../locations/location.model';
import { WallV2Model } from '@models/wall-v2.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../store/app.state';

@Component({
  selector: 'core-row',
  templateUrl: './core-row.component.html',
  styleUrl: './core-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreRowComponent implements OnInit {
  @Input() core: Edge.EdgeItem;
  @Input() isExpanded: boolean = false;
  public isExpanded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isExpanded);
  public wallCdkDropDataType = WallV2Model.WallCdkDropDataType;
  public draggableIndex$: BehaviorSubject<number> = new BehaviorSubject(null);
  public selectSelectedCameraInTilesMap$: Observable<Dictionary<boolean>> = this.store$.select(WallV2Selectors.selectSelectedCameraInTilesMap);

  constructor(private store$: Store<AppState>) {
  }

  public ngOnInit() {
    this.isExpanded$.next(this.isExpanded);
  }

  get cameras(): LocationModel.LocationCameraItem[] {
    return Object.values(this.core.cameras ?? {});
  }

  public toggleExpand() {
    const val = this.isExpanded$.getValue();
    this.isExpanded$.next(!val);
  }

  public onDragStart(index: number) {
    this.draggableIndex$.next(index);
  }

  public onDragEnd() {
    this.draggableIndex$.next(null);
  }

  protected readonly Object = Object;
}

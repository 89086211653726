<div class="location-module">
  <ui-header [breadCrumbs]="breadCrumb" *ngrxLet="viewType$; let viewType">
    <ng-container *ngIf="true; else oldView">
      <div class="view-menu flex-row gap-8">
        <div (click)="setView(ViewType.Home)" [class.selected]="viewType === ViewType.Home">Cameras</div>
        <div (click)="setView(ViewType.Map)" [class.selected]="viewType === ViewType.Map">Map</div>
        <div (click)="setView(ViewType.Floors)" [class.selected]="viewType === ViewType.Floors">Floor plans</div>
        <div (click)="setView(ViewType.Devices)" [class.selected]="viewType === ViewType.Devices">Devices</div>
      </div>
    </ng-container>
    <ng-template #oldView>
      <!-- old view -->
      <div class="flex-row gap-8 header-actions">
        @switch (viewType) {
          @case (ViewType.Devices) {
            <span class="ui-icon-list-view ui-mat-icon-button-16"
                  [matMenuTriggerFor]="menu"
                  matTooltip="List view"
                  matTooltipClass="sidebar-tooltip"
            ></span>
          }
          @case (ViewType.Floors) {
            <span class="ui-icon-floor-view ui-mat-icon-button-16"
                  [matMenuTriggerFor]="menu"
                  matTooltip="Floor plan view"
                  matTooltipClass="sidebar-tooltip"
            ></span>
          }
          @case (ViewType.Map) {
            <span class="ui-icon-map-view ui-mat-icon-button-16"
                  [matMenuTriggerFor]="menu"
                  matTooltip="Map view"
                  matTooltipClass="sidebar-tooltip"
            ></span>
          }
        }
        <!--        <mat-select [value]="viewType"-->
        <!--                    placeholder="Type"-->
        <!--                    (selectionChange)="locationViewChange($event)"-->

        <!--        >-->
        <!--          <mat-select-trigger>-->

        <!--          </mat-select-trigger>-->
        <!--          <mat-option [value]="ViewType.Devices">List</mat-option>-->
        <!--          <mat-option [value]="ViewType.Floors">Floors</mat-option>-->
        <!--          <mat-option [value]="ViewType.Map">Map</mat-option>-->
        <!--        </mat-select>-->

        <mat-menu #menu="matMenu">
          <button mat-menu-item
                  [class.menu-selected]="viewType === ViewType.Devices"
                  (click)="setView(ViewType.Devices)"
          >
            List
          </button>
          <button mat-menu-item [class.menu-selected]="viewType === ViewType.Floors" (click)="setView(ViewType.Floors)">
            Floors
          </button>
          <button mat-menu-item [class.menu-selected]="viewType === ViewType.Map" (click)="setView(ViewType.Map)">Map
          </button>
        </mat-menu>
        <span class="ui-icon-add-location ui-mat-icon-button-16"
              *ngIf="(isLocationCreatePermission | async) && viewType === ViewType.Devices"
              (click)="addLocation()"
              matTooltip="Add location"
              matTooltipClass="sidebar-tooltip"
        ></span>
      </div>
    </ng-template>
  </ui-header>
  <div class="w-100 h-100 router-container">
    <router-outlet></router-outlet>
  </div>
</div>

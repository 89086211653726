<div class="access-control-panel flex-col gap-8">
  <!-- Status Dropdown -->
  <mat-form-field appearance="outline" class="w-100">
    <mat-select [formControl]="statusControl">
      <mat-option value="open">Open</mat-option>
      <mat-option value="closed">Closed</mat-option>
    </mat-select>
  </mat-form-field>



  <!-- Locations and Doors -->
  <div class="selector-wrapper">


    <div class="flex-col gap-8">
      <ng-container *ngFor="let location of locations">
        <div (click)="toggleLocation(location)"
             class="flex-col start-center w-100 location-row gap-8">
          <div class="flex-row space-between-center w-100 c-p">
            <div class="flex-row gap-8">
              <mat-checkbox color="primary"
                            [checked]="locationSelected(location)"
                            [indeterminate]="!locationSelected(location) && locationSomeSelected(location)"
                            (click)="$event.preventDefault(); $event.stopPropagation(); toggleLocationSelection(location)">
              </mat-checkbox>
              <span class="ui-icon-location"></span>
              <div>{{ location.name }}</div>
            </div>
            <div class="flex-row center-center expand-arrow"
                 [class.collapsed]="!isExpanded(location.locationId)">
              <span class="ui-icon-expand-arrow"></span>
            </div>
          </div>

          <div *ngIf="isExpanded(location.locationId)"
               class="flex-col start-center w-100"
               (click)="$event.stopPropagation();">
            <div *ngFor="let door of filtered(location.doors)"
                 class="flex-row space-between-center door-row c-p"
                 (click)="toggleDoorSelection(door)"
                 [class.disabled]="doorDisabled(door)">
              <div class="flex-row gap-8 start-center">
                <mat-checkbox color="primary"
                              [checked]="isDoorSelected(door)"
                              (click)="$event.stopPropagation(); $event.preventDefault(); toggleDoorSelection(door)">
                </mat-checkbox>
                <div class="camera-snapshot">
                  <img *ngIf="(getDoorImage(door) | async) && !imgLoadFailed"
                       [lazyLoad]="(getDoorImage(door) | async)"
                       (onStateChange)="imgStateChange($event)"
                       #snapshot
                  />
                  <div *ngIf="!(getDoorImage(door) | async) || !imageLoader && imgLoadFailed"
                       class="flex-row center-center failed-img h-100 w-100"
                  >
                    <span class="ui-icon-camera-off"></span>
                  </div>
                </div>

                <div>{{ door.doorName }}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Person Name Input -->
  <mat-form-field appearance="outline" class="w-100 gap-8">
    <input matInput
           [formControl]="personNameControl"
           placeholder="Type person name">
  </mat-form-field>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, lastValueFrom, Observable, take, takeUntil } from 'rxjs';
import { CameraEdgeDetails, CameraLiveViewResponse, CameraStartRequest, CameraStartWebrtcPlaybackRequest, CameraStreamExistsRequest, EdgeCamera } from './camera.model';
import { HttpService } from '../core/http.service';
import { environment } from '../../environments/environment';
import { TokenDataStatus } from '../core/messaging.interfaces';
import { StartCameraLiveViewToken } from '../core/sessions/start-camera-live-view-session';
import { select, Store } from '@ngrx/store';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { Region } from '@enums/shared.enum';
import { PLAYBACK_TIMEOUT } from '../shared/playback-player/playback-player.component';
import { PtzModels } from './ptz.model';
import { SQSMsgInfo } from '../core/interfaces';
import { HttpClient } from '@angular/common/http';
import { LiveStreamModels } from '@models/live-stream.model';
import { api } from '@consts/url.const';
import { hlsDefaults } from '@consts/hls.const';
import { SessionStorageService } from '../core/session-storage.service';
import { SHARE_ACCESS_TOKEN_KEY } from '../authentication/authentication.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CamerasService {
  private cameraViewSubject = new BehaviorSubject<{ locationId: string; edgeId: string; cameraId: string } | undefined>(undefined);
  public cameraView$: Observable<{ locationId: string; edgeId: string; cameraId: string } | undefined> = this.cameraViewSubject
    .asObservable()
    .pipe(
      filter(info => !!info?.locationId && !!info?.edgeId && !!info.cameraId),
      take(1),
    );

  private currentCameraSubject = new BehaviorSubject<EdgeCamera.CameraDocument | undefined>(undefined);
  public currentCamera$: Observable<EdgeCamera.CameraDocument | undefined> = this.currentCameraSubject.asObservable()
    .pipe(
      filter(camera => !!camera),
      take(1),
    );

  private edgeDetailsSubject = new BehaviorSubject<CameraEdgeDetails | undefined>(undefined);
  public edgeDetails$: Observable<CameraEdgeDetails | undefined> = this.edgeDetailsSubject.asObservable()
    .pipe(
      filter(edgeDetails => !!edgeDetails),
      take(1),
    );

  constructor(private httpService: HttpService, private store: Store, private http: HttpClient,
              private sessionStorage: SessionStorageService) {
  }

  navigateToCameraView(locationId: string, edgeId: string, cameraId: string) {
    this.cameraViewSubject.next({ locationId, edgeId, cameraId });
  }

  // selectCamera(edgeDetails: CameraEdgeDetails, camera: EdgeCamera.CameraDocument) {
  //   this.edgeDetailsSubject.next(edgeDetails);
  //   this.currentCameraSubject.next(camera);
  // }

  // startCamera(edgeId: string, locationId: string, cameraId: string) {
  //   const url = `${environment.apiUrl}/cameras/start`;
  //   const data: CameraStartRequest = {
  //     cameraId,
  //     edgeId,
  //     locationId
  //   }
  //   return this.httpService.http.post(url, data);
  // }

  // stopCamera(edgeId: string, locationId: string, cameraId: string) {
  //   const url = `${environment.apiUrl}/cameras/stop`;
  //   const data: CameraStartRequest = {
  //     cameraId,
  //     edgeId,
  //     locationId
  //   }
  //   return this.httpService.http.post(url, data);
  // }

  startLiveView(edgeId: string, locationId: string, cameraId: string, scale = false, hq = false) {
    const url = `${environment.apiUrl}/cameras/camera/live-view/start${scale ? '-scale' : ''}`;
    const data: CameraStartRequest = {
      cameraId,
      edgeId,
      locationId,
      hq,
    };
    return this.httpService.http.post<CameraLiveViewResponse>(url, data, {
      params: {
        sharedToken: true,
      },
    });
  }

  startWebrtcPlayback(edgeId: string, locationId: string, cameraId: string, ts: number, sessionId?: string) {
    const url = `${environment.apiUrl}/cameras/camera/playback/webrtc-start`;
    const data: CameraStartWebrtcPlaybackRequest = {
      cameraId,
      edgeId,
      locationId,
      sessionId,
      ts,
    };
    return this.httpService.http.post<CameraLiveViewResponse>(url, data, {
      params: {
        sharedToken: true,
      },
    });
  }

  stopWebrtcPlayback(edgeId: string, locationId: string, cameraId: string, sessionId?: string) {
    const url = `${environment.apiUrl}/cameras/camera/playback/webrtc-stop`;
    const data: CameraStartWebrtcPlaybackRequest = {
      cameraId,
      edgeId,
      locationId,
      sessionId,
    };
    return this.httpService.http.post<CameraLiveViewResponse>(url, data, {
      params: {
        sharedToken: true,
      },
    });
  }

  stopLiveView(edgeId: string, locationId: string, cameraId: string) {
    const url = `${environment.apiUrl}/cameras/camera/live-view/stop`;
    const data: CameraStartRequest = {
      cameraId,
      edgeId,
      locationId,
    };
    return this.httpService.http.post<CameraLiveViewResponse>(url, data);
  }

  startLiveViewScale(edgeId: string, locationId: string, cameraId: string) {
    const url = `${environment.apiUrl}/cameras/camera/live-view/start-scale`;
    const data: CameraStartRequest = {
      cameraId,
      edgeId,
      locationId,
    };
    return this.httpService.http.post<CameraLiveViewResponse>(url, data);
  }

  stopLiveViewScale(edgeId: string, locationId: string, cameraId: string) {
    const url = `${environment.apiUrl}/cameras/camera/live-view/stop-scale`;
    const data: CameraStartRequest = {
      cameraId,
      edgeId,
      locationId,
    };
    return this.httpService.http.post<CameraLiveViewResponse>(url, data);
  }

  pollCameraUpload(token: string) {
    const url = `${environment.apiUrl}/sessions/${token}`;
    return this.httpService.poll<StartCameraLiveViewToken.AllSessionData>(
      url,
      5000,
      data => {
        return data.status === TokenDataStatus.COMPLETED;
      },
      30000,
    );
  }

  getEdge(edgeId: string) {
    return this.store.pipe(untilDestroyed(this), select(EdgeSelectors.selectEdgeById(edgeId)), take(1));
  }

  /**
   * TODO: Make .head instead of post for the stream file itself
   */
  pollStreamExists(data: {
    edgeId: string;
    cameraId: string;
    sessionId?: string;
    ts?: number;
    scale?: boolean;
    local?: boolean;
    localUrl?: string;
    region: Region;
    tag?: number;
  }) {
    const payload: CameraStreamExistsRequest = {
      edgeId: data.edgeId,
      cameraId: data.cameraId,
      sessionId: data.sessionId,
      scale: data.scale,
      region: data.region,
    };

    if (data.local) {

      let url;
      if (data.sessionId) {
        url = `${data.localUrl}/streams/playback/${data.edgeId}/${data.cameraId}/${data.sessionId}/s0.ts`;
        const sUrl = `${data.localUrl}/streams/playback/${data.edgeId}/${data.cameraId}/${data.sessionId}/s.m3u8`;
        return this.httpService.pollStreamGetPlayback(sUrl, 1000, PLAYBACK_TIMEOUT, !!data.sessionId, data.ts);
      } else {
        url = `${data.localUrl}/streams/${data.scale ? 'scale/' : 'scaleHQ/'}${data.edgeId}/${data.cameraId}/video/manifest.m3u8`;

        return this.httpService.pollStreamGet(url, 250, 40000, !!data.sessionId, data.ts);
      }
    } else {

      const url = `${this.getStreamPrefix(data.region)}/${data.scale ? 'video_streams_scale' : data.sessionId ? 'video_recording' : 'video_streams_scale_hq'
      }/${data.edgeId}/${data.cameraId}/${data.sessionId ? `${data.sessionId}/s.m3u8` : 's.m3u8'}`;
      const sUrl = `${this.getStreamPrefix(data.region)}/${data.scale ? 'video_streams_scale' : data.sessionId ? 'video_recording' : 'video_streams_scale_hq'
      }/${data.edgeId}/${data.cameraId}/${data.sessionId ? data.sessionId : '/'}s.m3u8`;

      if (data.sessionId) {
        return this.httpService.pollStreamGetPlayback(url, 1000, PLAYBACK_TIMEOUT, !!data.sessionId, data.ts);
      }
      return this.httpService.pollStreamGet(url, 250, 40000, !!data.sessionId, data.ts);
    }
  }

  pollPlaybackExists(url: string) {
    return this.httpService.pollStreamHead(url, 5000, 50000);
  }

  getCameraSnapshot(cameraId: string) {
    return this.store.pipe(select(CameraSelectors.getCameraSnapshotById(cameraId)));
  }

  getDefaultCameraSnapshot(cameraId: string) {
    return this.store.pipe(select(CameraSelectors.getDefaultCameraSnapshotById(cameraId)));
  }

  getCameraName(cameraId: string): Observable<string> {
    return this.store.pipe(select(CameraSelectors.selectCameraNameById(cameraId)));
  }

  getStreamPrefix(region: Region) {
    switch (region) {
      case Region.ISRAEL:
        return environment.streamsUrlEu;
      case Region.US_EAST:
      case Region.US_WEST:
        return environment.streamsUrl;
      default:
        return environment.streamsUrl;
    }
  }

  cameraPtz(request: PtzModels.PtzMoveRequestBody) {
    const url = `${environment.apiUrl}/onvif/ptz-move`;
    return this.http.post<SQSMsgInfo>(url, request)
      .subscribe();
  }

  cameraPtzSaveHome(request: PtzModels.PtzHomeRequestBody) {
    const url = `${environment.apiUrl}/onvif/ptz-set-home`;
    return this.http.post<SQSMsgInfo>(url, request)
      .subscribe();
  }

  cameraPtzHome(request: PtzModels.PtzHomeRequestBody) {
    const url = `${environment.apiUrl}/onvif/ptz-go-home`;
    return this.http.post<SQSMsgInfo>(url, request)
      .subscribe();
  }

  startLocalCamerasStream(edgeId: string, cameraIds: string[]) {
    const data: LiveStreamModels.LiveStreamOutgoingMessage = {
      edgeId,
      cameraIds,
      type: LiveStreamModels.StreamTypes.Websocket,
      action: LiveStreamModels.StreamAction.StartLiveStream,
    };
    return this.cameraStream(data);
  }

  startLivekitStream(edgeId: string, cameraIds: string[], token: string) {
    const livekitData: LiveStreamModels.LiveKitData = {
      token,
      url: api.livekit.url,
    };
    const data: LiveStreamModels.LiveStreamOutgoingMessage = {
      edgeId,
      cameraIds,
      type: LiveStreamModels.StreamTypes.Livekit,
      action: LiveStreamModels.StreamAction.StartLiveStream,
      data: livekitData,
    };
    return this.cameraStream(data);
  }

  stopLivekitStream(edgeId: string, cameraIds: string[]) {
    const data: LiveStreamModels.LiveStreamOutgoingMessage = {
      edgeId,
      cameraIds,
      type: LiveStreamModels.StreamTypes.Livekit,
      action: LiveStreamModels.StreamAction.Stop,
    };
    return this.cameraStream(data);
  }

  async getHlsConfig(cameraId: string, resolution: LiveStreamModels.StreamResolution): Promise<Partial<LiveStreamModels.HLS>> {
    let hlsConfig: Partial<LiveStreamModels.HLS>;
    const camHlsConfig = await lastValueFrom(this.store.select(CameraSelectors.selectHlsConfig(cameraId))
      .pipe(take(1)));
    switch (resolution) {
      case LiveStreamModels.StreamResolution.SQ:
        hlsConfig = {
          hlsSQScaledFragmentDuration: camHlsConfig?.hlsSQScaledFragmentDuration ?? hlsDefaults.hlsSQScaledFragmentDuration,
          hlsSQMaxLiveFragments: camHlsConfig?.hlsSQMaxLiveFragments ?? hlsDefaults.hlsSQMaxLiveFragments,
          hlsSQMaxStoredFragments: camHlsConfig?.hlsSQMaxStoredFragments ?? hlsDefaults.hlsSQMaxStoredFragments,
          hlsSQScaledWidth: camHlsConfig?.hlsSQScaledWidth ?? hlsDefaults.hlsSQScaledWidth,
          hlsSQScaledHeight: camHlsConfig?.hlsSQScaledHeight ?? hlsDefaults.hlsSQScaledHeight,
          hlsSQScaledBitrateBps: camHlsConfig?.hlsSQScaledBitrateBps ?? hlsDefaults.hlsSQScaledBitrateBps,
          hlsSQScaledPeakBitrateBps: camHlsConfig?.hlsSQScaledPeakBitrateBps ?? hlsDefaults.hlsSQScaledPeakBitrateBps,
          hlsSQScaledIFrameInterval: camHlsConfig?.hlsSQScaledIFrameInterval ?? hlsDefaults.hlsSQScaledIFrameInterval,
          hlsSQScaledIFrameIntervalFromFPS: camHlsConfig?.hlsSQScaledIFrameIntervalFromFPS ?? hlsDefaults.hlsSQScaledIFrameIntervalFromFPS,
          hlsSQScaledRateControlType: camHlsConfig?.hlsSQScaledRateControlType ?? hlsDefaults.hlsSQScaledRateControlType,
          hlsSQScaledPresetLevel: camHlsConfig?.hlsSQScaledPresetLevel ?? hlsDefaults.hlsSQScaledPresetLevel,
        };
        break;
      case LiveStreamModels.StreamResolution.MQ:
        hlsConfig = {
          hlsMQScaledFragmentDuration: camHlsConfig?.hlsMQScaledFragmentDuration ?? hlsDefaults.hlsMQScaledFragmentDuration,
          hlsMQMaxLiveFragments: camHlsConfig?.hlsMQMaxLiveFragments ?? hlsDefaults.hlsMQMaxLiveFragments,
          hlsMQMaxStoredFragments: camHlsConfig?.hlsMQMaxStoredFragments ?? hlsDefaults.hlsMQMaxStoredFragments,
          hlsMQFromMainstream: camHlsConfig?.hlsMQFromMainstream ?? hlsDefaults.hlsMQFromMainstream,
          hlsMQScaledWidth: camHlsConfig?.hlsMQScaledWidth ?? hlsDefaults.hlsMQScaledWidth,
          hlsMQScaledHeight: camHlsConfig?.hlsMQScaledHeight ?? hlsDefaults.hlsMQScaledHeight,
          hlsMQScaledBitrateBps: camHlsConfig?.hlsMQScaledBitrateBps ?? hlsDefaults.hlsMQScaledBitrateBps,
          hlsMQScaledPeakBitrateBps: camHlsConfig?.hlsMQScaledPeakBitrateBps ?? hlsDefaults.hlsMQScaledPeakBitrateBps,
          hlsMQScaledIFrameInterval: camHlsConfig?.hlsMQScaledIFrameInterval ?? hlsDefaults.hlsMQScaledIFrameInterval,
          hlsMQScaledIFrameIntervalFromFPS: camHlsConfig?.hlsMQScaledIFrameIntervalFromFPS ?? hlsDefaults.hlsMQScaledIFrameIntervalFromFPS,
          hlsMQScaledRateControlType: camHlsConfig?.hlsMQScaledRateControlType ?? hlsDefaults.hlsMQScaledRateControlType,
          hlsMQScaledPresetLevel: camHlsConfig?.hlsMQScaledPresetLevel ?? hlsDefaults.hlsMQScaledPresetLevel,
        };
        break;
      case LiveStreamModels.StreamResolution.HQ:
        hlsConfig = {
          hlsFragmentDuration: camHlsConfig?.hlsFragmentDuration ?? hlsDefaults.hlsFragmentDuration,
          hlsMaxLiveFragments: camHlsConfig?.hlsMaxLiveFragments ?? hlsDefaults.hlsMaxLiveFragments,
          hlsMaxStoredFragments: camHlsConfig?.hlsMaxStoredFragments ?? hlsDefaults.hlsMaxStoredFragments,
        };
        break;
      default:
        hlsConfig = camHlsConfig;
        break;
    }
    return hlsConfig;
  }

  async startHLSStreamOld(edgeId: string, cameraIds: string[], resolution = LiveStreamModels.StreamResolution.AUTO, playerWidth: number, playerHeight: number, hlsErrorCounter: number) {
    const hlsConfig: Partial<LiveStreamModels.HLS> = await this.getHlsConfig(cameraIds[0], resolution);

    const hlsData: LiveStreamModels.HlsData = {
      resolution,
      playerHeight,
      playerWidth,
      hlsErrorCounter,
      hlsConfig,
    };

    const data: LiveStreamModels.LiveStreamOutgoingMessage = {
      edgeId,
      cameraIds,
      type: LiveStreamModels.StreamTypes.HLS,
      action: LiveStreamModels.StreamAction.StartLiveStream,
      data: hlsData,
    };
    return this.cameraStream(data);
  }

  cameraStream(data: LiveStreamModels.LiveStreamOutgoingMessage) {
    const url = api.cameras.liveStream;
    return this.httpService.http.post<SQSMsgInfo>(url, data);
  }

  startHLSStream(request: LiveStreamModels.StartHLSLocalStreamRequest) {
    return this.httpService.http.post<SQSMsgInfo>(api.cameras.localHls, request);
  }

  startLocalHls(request: LiveStreamModels.StartHLSLocalStreamRequest) {
    return this.httpService.http.post(api.liveView.localStart, request);
  }

  startHlsPlayback(request: LiveStreamModels.StartHlsPlaybackRequest) {
    if (request.sessionId) {
      this.httpService.cancelPendingRequests();
    }
    const sharedToken = this.sessionStorage.getItem(SHARE_ACCESS_TOKEN_KEY);
    let apiUrl = api.liveView.playbackStart;
    if (sharedToken) {
      apiUrl = api.shareApi.playbackStart;
    }
    return this.httpService.http.post<LiveStreamModels.StartHlsPlaybackResponse>(apiUrl, request, {
        params: {
          sharedToken: true,
        },
      })
      .pipe(takeUntil(this.httpService.onCancelPendingRequests()));
  }

  startHlsPlaybackV2(request: LiveStreamModels.StartHlsPlaybackRequest) {

    return this.httpService.http.post<LiveStreamModels.StartHlsPlaybackResponse>(api.liveView.playbackStart, request, {
      params: {
        sharedToken: true,
      },
    });
  }

  stopHlsPlayback(request: LiveStreamModels.StopHlsPlaybackRequest) {
    return this.httpService.http.post<LiveStreamModels.StartHlsPlaybackResponse>(api.liveView.playbackStop, request, {
        params: {
          sharedToken: true,
        },
      })
      .pipe(takeUntil(this.httpService.onCancelPendingRequests()));
  }

}

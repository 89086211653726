import { Component, Input, OnInit } from '@angular/core';
import { UiBreadCrumbItem } from '@models/route.models';


@Component({
  selector: 'ui-header',
  templateUrl: './ui-header.component.html',
  styleUrls: ['./ui-header.component.scss'],
})
export class UiHeaderComponent implements OnInit {
  @Input()
  public breadCrumbs: UiBreadCrumbItem[];

  constructor() {
  }

  ngOnInit(): void {
  }

}

import { UiUptimeBarColors, UiUserSettingsDateFormat } from '@enums/shared.enum';
import { HoursFormat } from '@enums/user-settings.enum';

export const uiUptimeBarColors: { [Property in UiUptimeBarColors]: string } = {
  [UiUptimeBarColors.primary]: 'var(--color-primary-500)',
  [UiUptimeBarColors.gray]: 'var(--color-primary-50)',
  [UiUptimeBarColors.red]: 'var(--color-red-500)',
  [UiUptimeBarColors.pink]: 'var(--color-pink-500)',
  [UiUptimeBarColors.green]: 'var(--color-secondary-green-checkmark)',
  [UiUptimeBarColors.offline]: 'var(--color-red-500)',
  [UiUptimeBarColors.online]: 'var(--color-secondary-green-400)',
  [UiUptimeBarColors.green50]: 'var(--color-secondary-green-50)',
};

export const isJson = (str): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const passwordValidationErrors = {
  required: 'Field is required',
  lower: 'Field must contain lower case symbol',
  upper: 'Field must contain upper case symbol',
  specialSymbol: 'Field must contain special symbol',
  digit: 'Field must contain the number',
  length: 'The length of password should be more than 8 symbols',
};

export const SYSTEM_EMAIL = 'support@lumix.ai';

export const hoursFormatMap: { [Property in HoursFormat]: string } = {
  [HoursFormat.hours12]: '12 hours',
  [HoursFormat.hours24]: '24 hours',
};

export namespace LocalStorageKeys {
  export const WALL_MUTED = 'wall_muted';
}

export const archiveAccessPeriod: any[] = [
  {
    name: '1 hour',
    value: 1,
  },
  {
    name: '8 hours',
    value: 8,
  },
  {
    name: '1 day',
    value: 24,
  },
  {
    name: '7 days',
    value: 24 * 7,
  },
  {
    name: 'lifetime',
    value: -1,
  },
];

export const DEFAULT_ACCESS_PERIOD_HOURS = 24 * 7;

export const hoursArray = [
  '12 AM',
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
  '11 PM',
  '12 AM',
];

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';
import { Observable, of } from 'rxjs';
import { StatResult } from '@states/stats/stats.reducer';
import { KeyValuePairs } from '../core/interfaces';
import { WebRTCPeerControlTypes, WebRTCPeerInterfaceControl, WebRtcPeerType, WebRTCPlaybackInfo, WebRTCPlaybackInfoRequest, WebRTCPlaybackOldestVideos, WebRTCPlaybackOldestVidsRequest } from '@models/webrtc.model';
import { AblyTopics, AblyTopicsStr } from '@models/ably.model';
import * as Ably from 'ably';
import * as uuid from 'uuid';
import { Store } from '@ngrx/store';
import { StorageModel } from '@models/storage.model';
import { CamerasThumbnailsService } from '../cameras/camera-thumbnails/camera-thumnails.service';
import { StorageActions } from '@states/storage/storage.action-types';
import { Dictionary } from '@ngrx/entity/src/models';
import { SessionStorageService } from '../core/session-storage.service';
import { SHARE_ACCESS_TOKEN_KEY } from '../authentication/authentication.service';

export const DAY_IN_MSECS = 24 * 60 * 60 * 1000;

@Injectable()
export class StatsService {
  constructor(private http: HttpClient, private store$: Store, private cameraThumbnailsService: CamerasThumbnailsService,
              private sessionStorageService: SessionStorageService) {
  }

  public getCameraStats(dto: { edgeId: string; cameraId: string; period: number; bar: number }): Observable<number[]> {
    const url = `${api.stats.camera}?edgeId=${dto.edgeId}&cameraId=${dto.cameraId}&period=${dto.period}&bar=${dto.bar}`;
    return this.http.get<number[]>(url);
  }

  public getEdgeStats(dto: { edgeId: string; period: number; bar: number }): Observable<number[]> {
    const url = `${api.stats.edge}?edgeId=${dto.edgeId}&period=${dto.period}&bar=${dto.bar}`;
    return this.http.get<number[]>(url);
  }

  public getEdgeStatsAll(dto: { edgeId: string; locationId: string; period: number; bar: number, analytics: boolean }): Observable<StatResult[]> {
    const url = `${api.stats.edgeAll}?edgeId=${dto.edgeId}&locationId=${dto.locationId}&period=${dto.period}&bar=${dto.bar}&analytics=${dto.analytics ?? false}`;
    return this.http.get<StatResult[]>(url);
  }

  public getAnalyticStats(dto: { edgeId: string; cameraId: string; period: number; bar: number }): Observable<number[]> {
    const url = `${api.stats.analytics}?edgeId=${dto.edgeId}&cameraId=${dto.cameraId}&period=${dto.period}&bar=${dto.bar}`;
    return this.http.get<number[]>(url);
  }

  // public getStorageStats(dto: { edgeId: string; cameraId: string; start: number; end: number }): Observable<KeyValuePairs<number[][]>> {
  //   const url = `${api.stats.storage}?edgeId=${dto.edgeId}&cameraId=${dto.cameraId}&start=${dto.start}&end=${dto.end}`;
  //   return this.http.get<KeyValuePairs<number[][]>>(url);
  // }

  public getEdgeNetworkBandwidthFromGrafana(dto: { locationId: string; edgeId: string; timeBack: number }): Observable<KeyValuePairs<any>> {
    const url = `${api.stats.grafana}/bandwidth/?locationId=${dto.locationId}&edgeId=${dto.edgeId}&timeBack=${dto.timeBack}`;
    return this.http.get<any>(url);
  }

  public getCameraFpsFromGrafana(dto: { locationId: string; edgeId: string; cameraId: string; timeBack: number; subStream: boolean }): Observable<KeyValuePairs<any>> {
    const url = `${api.stats.grafana}/camera/fps?locationId=${dto.locationId}&edgeId=${dto.edgeId}&cameraId=${dto.cameraId}&timeBack=${dto.timeBack}&subStream=${dto.subStream}`;
    return this.http.get<any>(url);
  }

  public getEdgeFromGrafana(dto: { edgeId: string; timeBack: number; queryType: string }): Observable<KeyValuePairs<any>> {
    const url = `${api.stats.grafana}/edge?edgeId=${dto.edgeId}&queryType=${dto.queryType}&timeBack=${dto.timeBack}`;
    return this.http.get<any>(url);
  }

  public getCameraBitrateFromGrafana(dto: { locationId: string; edgeId: string; cameraId: string; timeBack: number; subStream: boolean }): Observable<KeyValuePairs<any>> {
    const url = `${api.stats.grafana}/camera/bitrate?locationId=${dto.locationId}&edgeId=${dto.edgeId}&cameraId=${dto.cameraId}&timeBack=${dto.timeBack}&subStream=${dto.subStream}`;
    return this.http.get<any>(url);
  }

  public getCameraResolutionFromGrafana(dto: { locationId: string; edgeId: string; cameraId: string; timeBack: number; subStream: boolean }): Observable<KeyValuePairs<any>> {
    const url = `${api.stats.grafana}/camera/resolution?locationId=${dto.locationId}&edgeId=${dto.edgeId}&cameraId=${dto.cameraId}&timeBack=${dto.timeBack}&subStream=${dto.subStream}`;
    return this.http.get<any>(url);
  }

  public getCameraStorageFromGrafana(dto: { locationId: string; edgeId: string; cameraId: string; timeBack: number; }): Observable<KeyValuePairs<any>> {
    const url = `${api.stats.grafana}/camera/storage?locationId=${dto.locationId}&edgeId=${dto.edgeId}&cameraId=${dto.cameraId}&timeBack=${dto.timeBack}`;
    return this.http.get<any>(url);
  }

  public getCameraSmartStorageFromGrafana(dto: { locationId: string; edgeId: string; cameraId: string; timeBack: number; }): Observable<KeyValuePairs<any>> {
    const url = `${api.stats.grafana}/camera/smart-storage?locationId=${dto.locationId}&edgeId=${dto.edgeId}&cameraId=${dto.cameraId}&timeBack=${dto.timeBack}`;
    return this.http.get<any>(url);
  }

  public getCameraVideoCodecFromGrafana(dto: { locationId: string; edgeId: string; cameraId: string; timeBack: number; }): Observable<KeyValuePairs<any>> {
    const url = `${api.stats.grafana}/camera/video-codec?locationId=${dto.locationId}&edgeId=${dto.edgeId}&cameraId=${dto.cameraId}&timeBack=${dto.timeBack}`;
    return this.http.get<any>(url);
  }

  public getCameraJitterFromGrafana(dto: { locationId: string; edgeId: string; cameraId: string; timeBack: number; subStream: boolean }): Observable<KeyValuePairs<any>> {
    const url = `${api.stats.grafana}/camera/jitter?locationId=${dto.locationId}&edgeId=${dto.edgeId}&cameraId=${dto.cameraId}&timeBack=${dto.timeBack}&subStream=${dto.subStream}`;
    return this.http.get<any>(url);
  }

  public getCameraClockRateFromGrafana(dto: { locationId: string; edgeId: string; cameraId: string; timeBack: number; subStream: boolean }): Observable<KeyValuePairs<any>> {
    const url = `${api.stats.grafana}/camera/clock-rate?locationId=${dto.locationId}&edgeId=${dto.edgeId}&cameraId=${dto.cameraId}&timeBack=${dto.timeBack}&subStream=${dto.subStream}`;
    return this.http.get<any>(url);
  }

  public getEdgeHealthFromGrafana(dto: { locationId: string; edgeId: string; }): Observable<KeyValuePairs<any>> {
    const url = `${api.stats.grafana}/health/?locationId=${dto.locationId}&edgeId=${dto.edgeId}`;
    return this.http.get<any>(url);
  }

  public getCoreSwVersionFromGrafana(dto: { locationId: string; edgeId: string; }): Observable<KeyValuePairs<any>> {
    const url = `${api.stats.grafana}/health/?locationId=${dto.locationId}&edgeId=${dto.edgeId}`;
    return this.http.get<any>(url);
  }

  getBaseInLocale(date: Date) {
    return this.cameraThumbnailsService.getBaseInLocale(date);
  }

  public getStorageStatsAbly(dto: { edgeId: string; cameraId: string; start?: number; end?: number }): Observable<unknown> {
    const baseStart = this.getBaseInLocale(new Date(dto.start));
    const baseEnd = this.getBaseInLocale(new Date(dto.end));
    const ablyOptions = {
      key: 'E1YILg.eTBPUQ:m4VkVmbMO6ftJBdS4GLcayKXXn8bp8cXj3B-jBkjIDA',
      echoMessages: false,
    };
    const ablyClient = new Ably.Realtime(ablyOptions); /* inferred type Ably.Realtime */
    const ablyChannel = ablyClient.channels.get(dto.edgeId); /* inferred type Ably.Types.RealtimeChannel */

    const baseStartSessionId = uuid.v4();
    const baseEndSessionId = uuid.v4();

    let responseCount = 0;
    const responseExpect = baseStart === baseEnd ? 1 : 2;

    ablyChannel.attach();
    // Getting presence on a channel
    ablyChannel.presence.get();

    ablyChannel
      .subscribe(AblyTopics[AblyTopics.Playback], async (message) => {
        const peerDataRaw: WebRTCPeerInterfaceControl = message.data;
        if (peerDataRaw.control !== WebRTCPeerControlTypes.PlaybackInfo) {
          return;
        }
        const peerData: WebRTCPlaybackInfo = message.data as WebRTCPlaybackInfo;
        const base = peerData.sessionId === baseStartSessionId ? baseStart : baseEnd;
        const storageStats: StorageModel.StorageStatsOfDay[] = [
          {
            edgeId: dto.edgeId,
            cameraId: dto.cameraId,
            base,
            cacheId: `${dto.edgeId}:${dto.cameraId}:${base}`,
            noStorage: peerData.noStorage,
            smartStorage: peerData.smartStorage,
          },
        ];
        const result: StorageModel.StorageStatsTimeRangeResponse = {
          storageStats,
        };
        this.store$.dispatch(StorageActions.getCamerasStorageStatsSuccess({ result }));
        responseCount++;
        if (responseCount === responseExpect) {
          ablyChannel.unsubscribe();
          if (ablyChannel) {
            ablyChannel.detach();
          }
          if (ablyClient) {
            ablyClient.close();
          }
        }
      });

    const peerData = <WebRTCPlaybackInfoRequest>{
      edgeId: dto.edgeId,
      cameraId: dto.cameraId,
      timestamp: new Date().getTime(),
      type: WebRtcPeerType.CONTROL,
      control: WebRTCPeerControlTypes.PlaybackInfoRequest,
      startTS: baseStart,
      endTS: baseEnd === baseStart ? baseStart + DAY_IN_MSECS : baseEnd,
      sessionId: baseStartSessionId,
    };
    // console.log(`[ABLY PLAYBACK INFO REQUEST] ${peerData.startTS}[${new Date(peerData.startTS)}] - ${peerData.endTS}${new Date(peerData.endTS)}`);
    ablyChannel.publish(AblyTopicsStr[AblyTopics.Playback], JSON.stringify({ data: peerData, time: new Date().getTime() }));

    if (baseEnd !== baseStart) {
      const peerData = <WebRTCPlaybackInfoRequest>{
        edgeId: dto.edgeId,
        cameraId: dto.cameraId,
        timestamp: new Date().getTime(),
        type: WebRtcPeerType.CONTROL,
        control: WebRTCPeerControlTypes.PlaybackInfoRequest,
        startTS: baseEnd,
        endTS: baseEnd + DAY_IN_MSECS,
        sessionId: baseEndSessionId,
      };
      // console.log(`[ABLY PLAYBACK INFO REQUEST] ${peerData.startTS}[${new Date(peerData.startTS)}] - ${peerData.endTS}${new Date(peerData.endTS)}`);
      ablyChannel.publish(AblyTopicsStr[AblyTopics.Playback], JSON.stringify({ data: peerData, time: new Date().getTime() }));

    }
    setTimeout(() => {
      ablyChannel.unsubscribe();
      if (ablyClient?.connection?.state !== 'closed') {
        ablyClient.close();
      }
    }, 3000);
    return of();
  }

  public getStorageOldestVidsAbly(dto: { edgeId: string; cameraId: string; }): Observable<unknown> {
    const ablyOptions = {
      key: 'E1YILg.eTBPUQ:m4VkVmbMO6ftJBdS4GLcayKXXn8bp8cXj3B-jBkjIDA',
      echoMessages: false,
    };
    const ablyClient = new Ably.Realtime(ablyOptions); /* inferred type Ably.Realtime */
    const ablyChannel = ablyClient.channels.get(dto.edgeId); /* inferred type Ably.Types.RealtimeChannel */

    const sessionId = uuid.v4();

    let responseCount = 0;
    const responseExpect = 1;

    ablyChannel.attach();
    // Getting presence on a channel
    ablyChannel.presence.get();

    ablyChannel
      .subscribe(AblyTopics[AblyTopics.Playback], async (message) => {
        const peerDataRaw: WebRTCPeerInterfaceControl = message.data;
        if (peerDataRaw.control !== WebRTCPeerControlTypes.PlaybackOldestVids) {
          return;
        }
        const peerData: WebRTCPlaybackOldestVideos = message.data as WebRTCPlaybackOldestVideos;
        // console.log(`[ABLY PLAYBACK OLDEST VIDS RESPONSE]`, peerData);
        const storageStats: StorageModel.StorageOldest[] = [
          {
            edgeId: dto.edgeId,
            cameraId: dto.cameraId,
            cacheId: `${dto.edgeId}:${dto.cameraId}`,
            oldestVideoTS: peerData.oldestVideoTS,
            oldestSmartVideoTS: peerData.oldestSmartVideoTS,
          },
        ];
        const result: StorageModel.StorageStatsTimeRangeResponse = {
          storageStats,
        };
        this.store$.dispatch(StorageActions.getCamerasOldestVidsSuccess({ result }));
        responseCount++;
        if (responseCount === responseExpect) {
          ablyChannel.unsubscribe();
          if (ablyClient?.connection?.state !== 'closed') {
            ablyClient.close();
          }
        }
      });

    const peerData = <WebRTCPlaybackOldestVidsRequest>{
      edgeId: dto.edgeId,
      cameraId: dto.cameraId,
      timestamp: new Date().getTime(),
      type: WebRtcPeerType.CONTROL,
      control: WebRTCPeerControlTypes.PlaybackOldestVids,
      sessionId: sessionId,
    };
    // console.log(`[ABLY PLAYBACK OLDEST VIDS REQUEST] ${peerData.cameraId}`);
    ablyChannel.publish(AblyTopicsStr[AblyTopics.Playback], JSON.stringify({ data: peerData, time: new Date().getTime() }));

    setTimeout(() => {
      ablyChannel.unsubscribe();
      if (ablyClient?.connection?.state !== 'closed') {
        ablyClient.close();
      }
    }, 3000);
    return of();
  }

  public getStorageStats(dto: { edgeId: string; cameraId: string; start: number; end: number }): Observable<StorageModel.StorageItem[]> {
    const url = `${api.videoStorage.crud}?edgeId=${dto.edgeId}&cameraId=${dto.cameraId}&start=${dto.start}&end=${dto.end}`;
    return this.http.get<StorageModel.StorageItem[]>(url, {
        params: {
          sharedToken: true,
        },
      },
    );
  }

  public getHlsStorageStats(dto: { edgeId: string; cameraId: string; start: number; end: number }, useSharedToken: boolean = false): Observable<StorageModel.StorageItem[]> {
    const urlBase = useSharedToken ? api.shareApi.playbackVideoTimeline : api.liveView.playbackVideoTimeline;
    const url = `${urlBase}?edgeId=${dto.edgeId}&cameraId=${dto.cameraId}&start=${dto.start}&end=${dto.end}&includeSmartStorage=true`;
    return this.http.get<StorageModel.StorageItem[]>(url, {
        params: {
          sharedToken: true,
        },
      },
    );
  }

  public getEdgesHealthFromGrafana(dto: { locationId: string; edgeId: string; }[]): Observable<Dictionary<{
    cameras: Dictionary<any>,
    locationId: string
  }>> {
    function convertArrayToQueryParams(pairs: { edgeId: string, locationId: string }[]): string {
      return pairs
        .map((pair, index) =>
          `pairs[${index}][locationId]=${pair.locationId}&pairs[${index}][edgeId]=${pair.edgeId}`,
        )
        .join('&');
    }

    const queryParams = convertArrayToQueryParams(dto);


    const url = `${api.stats.edgesHealth}?${queryParams}`;
    return this.http.get<any>(url);
  }

  public getEdgesSwVersionFromGrafana(dto: { locationId: string; edgeId: string; }[]): Observable<Dictionary<string>> {
    function convertArrayToQueryParams(pairs: { edgeId: string, locationId: string }[]): string {
      return pairs
        .map((pair, index) =>
          `pairs[${index}][locationId]=${pair.locationId}&pairs[${index}][edgeId]=${pair.edgeId}`,
        )
        .join('&');
    }

    const queryParams = convertArrayToQueryParams(dto);


    const url = `${api.stats.edgesSwVersion}?${queryParams}`;
    return this.http.get<any>(url);
  }


  public getEdgeLastMp4(edgeId: string) {
    //todo replace to this
    const sharedToken = this.sessionStorageService.getItem(SHARE_ACCESS_TOKEN_KEY);
    let url = api.videoStorage.last(edgeId);
    if (sharedToken) {
      url = api.shareApi.getEdgeLastMp4(edgeId);
    }
    return this.http.get<number>(url, {
      params: {
        sharedToken: true,
      },
    });
  }


}

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { environment } from '../../../environments/environment';
import { CamerasThumbnailsService } from '../../cameras/camera-thumbnails/camera-thumnails.service';
import { CameraThumbnailsData, Thumb, ThumbOptions } from '../../cameras/camera-thumbnails/camera-thumbnails.model';
import { catchError, concatMap, debounceTime, filter, Observable, of, Subject, Subscription, take, timeout } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ThumbnailDialogComponent, ThumbnailDialogData, ThumbnailDialogType } from './thumbnail-dialog/thumbnail-dialog.component';
import { MainMenuService } from '../../layout/main-menu.service';
import * as moment from 'moment';
import { PlaybackPlayerService } from '../playback-player/playback-player.service';
import { select, Store } from '@ngrx/store';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { ThumbnailAttributes, ThumbnailSection } from './thumbnail.model';
import { AuthenticationService } from '../../authentication/authentication.service';
import { PLAYBACK_OFFLINE_INTERVAL_MS, PlaybackPlayerComponent } from '../playback-player/playback-player.component';
import { CamerasService } from '../../cameras/cameras.service';
import * as ArchiveAction from '@states/archive/archive.actions';
import * as TrackObjectActions from '@states/track-object/track-object.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Search, SearchType } from '../search.model';
import { Router } from '@angular/router';
import { TrackObjectState } from '@states/track-object/track-object.reducer';
import { ThumbnailsSelectors } from '@states/thumbnails/thumbnails.selector-types';
import { AddArchiveModalComponent } from '../modals/add-archive-modal/add-archive-modal.component';
import { CameraSelectorComponent, CameraSelectorDialogData } from '../camera-selector/camera-selector.component';
import { EdgeCamera } from '../../cameras/camera.model';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { MultiPlaybackSelectors } from '@states/multi-playback/multi-playback.selector-types';
import { ThumbnailHistogramColor } from '@consts/thumbnail.const';
import { ThumbnailLayoutsOnSelectedResult } from '@models/thumbnail.model';
import { UtilsV2Service } from '../../services/utils-v2.service';
import { GroupModels, GroupStatus, Person } from '@models/people.model';
import { PeopleService } from '../../development/people.service';
import { UiPersonDialogComponent, UiPersonDialogData } from '../ui-kit/ui-person-dialog/ui-person-dialog.component';
import { PeopleActions } from '@states/people/people.action-types';
import { EdgeHeartbeatPulsationSelectors } from '@states/edge-heartbeat-pulsation/edge-heartbeat-pulsation.selector-types';
import { PulsationModels } from '@models/pulsation.model';
import { ShareAccessComponent } from '../modals/share-access/share-access.component';
import { GrantedAccessType } from '@enums/granted-access.enum';
import { MultiPlaybackMove } from '@models/multi-playback.model';
import { TimelineDragEvent } from '../timeline/timeline-series/timeline-series.component';
import * as _ from 'lodash';
import { MediaCacheService } from '../media-cache/media-cache.service';
import { SelectedCamera } from '@models/alert-events.model';
import { GrantedAccessService } from '../../development/granted-access.service';
import { StorageSelectors } from '@states/storage/storage.selector-types';
import { TimelineComponent } from '../timeline/timeline.component';
import { SearchObjectTypes } from '@enums/search.enum';
import { StateChange } from 'ng-lazyload-image';
import { AlertsService } from '../../development/alerts.service';
import { AlertsActivity } from '@models/alerts-v2.model';
import { alertActivityTypeMap } from '@consts/alerts-v2.const';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { PreloaderColor } from '@enums/shared.enum';
import { features } from '@consts/text.const';
import { OrganizationUsersActions } from '@states/organization-users/organization-users.action-types';
import { OrganizationUsersSelectors } from '@states/organization-users/organization-users.selector-types';
import { SharedActions } from '@states/shared/shared.action-types';
import { MultiSearchSelectors } from '@states/multi-search/multi-search.selector-types';
import { PermissionSelectors } from '@states/permissions/permissions.selector-types';
import { PermissionModel } from '@models/permission.model';
import { UserSelectors } from '@states/user/user.selector-types';
import { PeopleSelectors } from '@states/people/people.selector-types';
import { PeopleAddBucketDialogComponent, PeopleAddBucketDialogData } from '../../pages/database/pages/people/dialogs/people-add-bucket-dialog/people-add-bucket-dialog.component';
import { VisibilityChanged } from '../directives/visibility-change.directive';
import { AppUser } from 'src/app/user/user.model';
import { VideoService } from '../../development/video.service';
import { DAY_IN_MSECS, StatsService } from '../../development/stats.service';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { AuthenticationActions } from '@states/authentication/authentication.action-types';
import { SharedApiService } from '../../services/shared-api.service';
import { WallV2Model } from '@models/wall-v2.model';
import Permissions = PermissionModel.Permissions;
import { CameraSettingsModel } from '@models/camera-settings.model';

export const THUMBNAIL_DEFAULT_FREQUENCY = 20000;
export const THUMBNAIL_DIALOG_FREQUENCY = 2000;
export const FAILED_IMAGE_THRESHOLD = 20;
export const CACHE_UPDATE_FREQUENCY_SEC = 1000;
const FIVE_MINUTES = 5 * 60 * 1000;
const TWO_MINUTES = 2 * 60 * 1000;

export enum ThumbnailTemplate {
  TEMPLATE1,
  TEMPLATE2,
  TEMPLATE3
}

@UntilDestroy()
@Component({
  selector: 'app-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
})
export class ThumbnailComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  private fetchThumbsDebouncer: Subject<void> = new Subject<void>();

  ThumbnailSection: typeof ThumbnailSection = ThumbnailSection;
  ThumbnailTemplate: typeof ThumbnailTemplate = ThumbnailTemplate;
  ThumbnailHistogramColor: typeof ThumbnailHistogramColor = ThumbnailHistogramColor;
  public SearchObjectTypes = SearchObjectTypes;
  public ThumbnailDialogType: typeof ThumbnailDialogType = ThumbnailDialogType;

  public WallLayout: typeof WallV2Model.WallLayout = WallV2Model.WallLayout;
  layout = this.WallLayout.GRID_1;
  displayCount = WallV2Model.wallLayoutCameraCountV2[this.WallLayout.GRID_1];
  displayIndexes = [...Array(this.displayCount)
    .keys()];

  public SearchType = SearchType;

  public selectIsDeveloper$: Observable<boolean> = this.store$.pipe(select(UserSelectors.isDeveloper));

  public selectSearchType$: Observable<SearchType> = this.store$.pipe(select(MultiSearchSelectors.selectSearchType));

  public selectSelectedCameras$: Observable<EdgeCamera.CameraItem[]> = this.store$.select(MultiPlaybackSelectors.selectSelectedCameras)
    .pipe(untilDestroyed(this));

  public selectCameraEvents$: Observable<number[][]> = this.store$.select(MultiPlaybackSelectors.selectCameraEvents)
    .pipe(untilDestroyed(this));

  public selectedPositions$: Observable<number[]> = this.store$.select(MultiPlaybackSelectors.selectPositions)
    .pipe(untilDestroyed(this));

  public selectMove$: Observable<MultiPlaybackMove> = this.store$.select(MultiPlaybackSelectors.selectMove)
    .pipe(untilDestroyed(this));

  public afterViewInit = false;

  public showLeftGif = false;
  public showEnterGif = false;

  @Output() showChart: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onOpen: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() onShortcutClicked: EventEmitter<CameraSettingsModel.Shortcut> = new EventEmitter<CameraSettingsModel.Shortcut>();
  private failedImage = 0;

  public isPlayback = true;

  @ViewChild('layoutsWrapper') layoutsWrapper: ElementRef;
  @ViewChild('vehicleLabel') vehicleLabel: ElementRef;
  @ViewChild('timelineWrapper') timelineWrapper: ElementRef;
  @ViewChild('timeline') timeline: TimelineComponent;
  @ViewChild('scroller')
  scroller;

  @ViewChild('container')
  container: ElementRef;

  @ViewChild('playbackWrapper')
  playbackWrapper: ElementRef;

  @ViewChild('mainWrapper')
  mainWrapper: ElementRef;

  @ViewChild('playback')
  playback: PlaybackPlayerComponent;

  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  @Output()
  onTimePeriodChangeEvent: EventEmitter<{ startTime: number, endTime: number }> = new EventEmitter<{ startTime: number, endTime: number }>();

  @Input() thumbnailTemplate = ThumbnailTemplate.TEMPLATE2;

  @Input() timeIsClickable = false;

  @Input() dialogRef: MatDialogRef<ThumbnailDialogComponent>;

  @Input()
  events: number[];

  @Input()
  searchEvents: number[][];

  @Input()
  objects: Search.SearchObject[];
  public _objects: Search.SearchObject[];
  public _faces: Search.SearchObject[];

  @Input() customEvents = false;
  public customEventsDataStr = '';

  people: Person[];

  @Input()
  edgeId: string;

  @Input()
  cameraId: string;

  locationId: string | undefined;

  @Input()
  isDialog: boolean = false;

  @Input()
  isLiveView: boolean = false;

  @Input()
  public isAlertPlayback: boolean = false;

  @Input()
  displayName = false;

  @Input()
  hideDebug = true;

  @Input()
  showEventsCounter = true;

  @Input()
  seconds: boolean;

  @Input()
  optionsInput: ThumbOptions = {
    clipInSeconds: 7200,
    offsetResInDurations: 6,
    startTime: 0,
    endTime: 0,
    duration: THUMBNAIL_DEFAULT_FREQUENCY,
  };

  @Input() alertPreview: boolean = false;
  @Input() mainThumbnail: string;

  options: ThumbOptions = {
    clipInSeconds: 7200,
    offsetResInDurations: 6,
    startTime: 0,
    endTime: 0,
    duration: THUMBNAIL_DEFAULT_FREQUENCY,
  };

  @Input()
  rtl = false;

  @Input()
  defaultThumbnail: string;

  @Input()
  type: ThumbnailDialogType;

  @Input()
  showObjects = false;

  @ViewChild('best')
  best: ElementRef;

  @Input()
  isShareVisible: boolean = false;

  @Input() shareName: string;
  @Input() alertId: string;
  @Input() alertInstanceId: string;

  @Input() allowTrack = false;

  @Input() alertName: string;
  @Input() alertTs: number;

  /**
   * Disable open dialod modal on click on thumbnail.
   */
  @Input() public allowDialogOpen = true;

  @Input() name: string;

  @Input() isSharedAlert: boolean = false;
  @Input() selectedCamera: SelectedCamera;
  @Input() online: boolean = false;

  /**
   * If enabled we check the permissions to access for some features.
   * Could be disabled for sharing for example.
   */
  @Input() checkPermissionEnabled = true;

  @Input() initialTs: number;

  @Input() cameraView = false;

  public streamLiveView = false;
  public startedDrag = false;

  public thumbsStorageUpdateCounter = CACHE_UPDATE_FREQUENCY_SEC;

  public hideCameraView = false;

  startTime = 0;
  endTime = 0;

  displayVideo: boolean = true;

  streamBase: string;
  src: string;

  date: string;
  period: string;
  startHour: string;
  endHour: string;

  base: number;
  base24: number;

  defaultImage = 'assets/thumb_placeholder.png';
  img: string;
  latestImg: string;
  // time: string;
  dragTs: number;
  currentTs: number;

  timeFormat: string;
  scrollerPosition = 0;

  thumb: Blob;
  thumbIdx = 0;
  @Input() thumbnailsData: CameraThumbnailsData = {};
  baseUrl: string;
  loaded? = false;

  @Input()
  thumbnailsTs: number[];

  @Input() showShortcuts: boolean = false;

  thumbnailsUris: string[] = [];
  first: string;

  thumbs: Thumb[] = [];

  detailsSub: Subscription;

  fetchFromDb = false;

  currentEvent = 0;

  offsetResInDurations: number;
  seekerPos = 20;

  /**
   *  Player variables
   *  */
  selectedSection = ThumbnailSection.IMAGES;
  playerDuration: number;

  playbackWebrtc = true;
  playbackWebrtcSeek = true;
  playerTime: number;

  playing$: Observable<boolean>;
  playing = false;

  startedPlaying = false;

  subscriptions: Subscription[] = [];

  offline = false;
  noStorage = true;

  public isChartVisible: boolean = true;
  developerRole = ['user'];

  aspectRatio = 1;

  cameraName$: Observable<string>;

  selectedCameras: EdgeCamera.CameraItem[] = new Array(16).fill(undefined);

  baseStart: number;
  baseEnd: number;

  objectAttributes: ThumbnailAttributes = {
    personCount: 0,
    vehicleCount: 0,
    vehicles: [],
  };

  vehicleTooltip = false;
  peopleTooltip = false;

  peopleNames: string[] = [];

  displayTime: number;


  public selectedCamerasCount$: Observable<number> = this.store$.select(MultiPlaybackSelectors.selectSelectedCamerasCount)
    .pipe(untilDestroyed(this));

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (!this.isDialog) {
      return;
    }
    this.keepRatio();
  }

  parent: HTMLDivElement;
  observer: ResizeObserver;

  timestamp: number;
  resetTimestamp: number;
  percentage: number;

  @Input() timezone;
  containerMaxHeight;

  alertProcessing = false;
  alertProcessingTime = 0;

  text: string = 'First line\nSecond line';

  public noResults = features.alertActivityLogs.noData;


  public alertActivityTypeMap = alertActivityTypeMap;
  public alertActivity: AlertsActivity[] = [];

  public viewPortHeight: number;
  @ViewChild('virtualScroll') virtualScroll: CdkVirtualScrollViewport;
  public loadingAlertActivity = false;

  public imageLoader = false;

  public PreloaderColor = PreloaderColor;

  public liveViewCamera: AppUser.LiveViewCamera;

  public _reset: boolean = false;

  private windowHidden = false;
  private hideTs: number;
  private showTs: number;

  public dragging = false;

  public selectEdgeLastMp4Ts$: Observable<number>;

  public lastMp4Ts: number;

  hintDebouncer: Subject<void> = new Subject<void>();
  showHint = false;
  @Input() public hideHint = false;
  @Output() public hintShown: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('mouseenter')
  onMouseEnter() {
    this.elementRef.nativeElement.focus({ preventScroll: true });
  }

  @HostListener('keydown', ['$event'])
  async onKeyDown(event: KeyboardEvent) {
    if (this.isDialog && this.selectedSection === ThumbnailSection.VIDEO) {
      return;
    }
    if (event.key === 'Enter') {
      this._hideHint();
      this.showLeftGif = false;
      this.showEnterGif = false;
      if (!this.isDialog) {
        this.openDialog();
      }
    }
    if (event.key === 'ArrowLeft') {
      this._hideHint();
      this.showLeftGif = false;
      this.showEnterGif = false;

      // Handle left arrow key press
      const offsetDuration = this.offsetResInDurations;
      this.offsetResInDurations = 1;
      await this.prevThumb();
      this.offsetResInDurations = offsetDuration;
    } else if (event.key === 'ArrowRight') {
      this._hideHint();
      this.showLeftGif = false;
      this.showEnterGif = false;

      // Handle right arrow key press
      const offsetDuration = this.offsetResInDurations;
      this.offsetResInDurations = 1;
      await this.nextThumb();
      this.offsetResInDurations = offsetDuration;
    }
  }

  constructor(
    private cameraThumbnailsService: CamerasThumbnailsService,
    private store$: Store,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private mainMenuService: MainMenuService,
    private playbackPlayerService: PlaybackPlayerService,
    private authenticationService: AuthenticationService,
    private camerasService: CamerasService,
    private router: Router,
    public elementRef: ElementRef,
    private utilsService: UtilsV2Service,
    private peopleService: PeopleService,
    private mediaCacheService: MediaCacheService,
    private grantedAccessService: GrantedAccessService,
    private alertsService: AlertsService,
    private renderer: Renderer2,
    private videoService: VideoService,
    private statsService: StatsService,
    private sharedApiService: SharedApiService,
  ) {
  }

  private _hideHint() {
    this.hideHint = true;
    this.hintShown.emit();
  }

  public isNewAlert(object: Search.SearchObject) {
    const now = Date.now();
    return now - object.idBase < TWO_MINUTES;
  }

  public get playbackError() {
    return this.playback?.playbackErrors?.internal || this.playback?.playbackErrors?.maxSessions;
  }

  public get time() {

    let timestamp;
    const startTime = this.cameraThumbnailsService.convertTsToZone(this.startTime, this.timezone!, moment.tz.guess());
    if (this.timestamp) {
      timestamp = this.cameraThumbnailsService.convertTsToZone(this.timestamp, this.timezone!, moment.tz.guess());
    }
    const defaultTs = (this.optionsInput.endTime + this.optionsInput.startTime) / 2;
    return this.displayTime ? formatDate(this.cameraThumbnailsService.convertTsToZone(this.displayTime, this.thumbnailsData?.timezone!, moment.tz.guess()), this.timeFormat, 'en-US') : formatDate(this.cameraThumbnailsService.convertTsToZone(defaultTs, this.thumbnailsData?.timezone!, moment.tz.guess()), this.timeFormat, 'en-US');

  }

  private async nextThumb() {
    this.timestamp = await this.mediaCacheService.getNextThumb(this.edgeId, this.cameraId, this.timestamp);
    await this.loadKeyboardThumb();
  }

  private async prevThumb() {
    this.timestamp = await this.mediaCacheService.getPrevThumb(this.edgeId, this.cameraId, this.timestamp);

    await this.loadKeyboardThumb();
  }

  private async loadKeyboardThumb() {
    if (this.isDialog) {
      this.setMove();
      return;
    }
    this.img = await this._img();
    const width = this.elementRef.nativeElement.getBoundingClientRect().width;
    const percent = (this.timestamp - this.options.startTime) / (this.options.endTime - this.options.startTime);
    this.scrollerPosition = width * percent;
  }

  buildObjectsAttributes() {
    // this.objectAttributes.personCount = this.objects?.filter(obj => obj.type === SearchObjectTypes.PERSON).length;
    // this.objectAttributes.vehicleCount = this.objects?.filter(obj => obj.type === SearchObjectTypes.VEHICLE).length;
    this.objectAttributes.personCount = _.max(this.objects?.map(obj => obj.maxPerson));
    this.objectAttributes.vehicleCount = _.max(this.objects?.map(obj => obj.maxVehicle));
    this.objectAttributes.vehicles = this.objects
      .filter(obj => obj?.type === SearchObjectTypes.VEHICLE && !!obj?.attributes && Object.keys(obj?.attributes)?.length > 0)
      .map(obj => obj?.attributes);
  }

  public get savedPeople() {
    const ids = this.objects.map(obj => obj.groupId);
    return this.people?.filter(person => !!person?.personId && ids.includes(person?.personId)) ?? [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['thumbnailsData']) {
      this.updateThumbsData(this.thumbnailsData);
    }
    if (changes['online']) {
      this.displayVideo = this.online;
    }
    if (changes['objects']) {
      this._objects = this.objects ? this.objects?.filter(obj => !!obj.url || !!obj.zoomImage) : [];
      this._faces = this.objects ? this.objects?.filter(obj => obj.type === SearchObjectTypes.PERSON && !!obj.zoomImage) : [];

      if (!!this.objects?.length) {
        this.buildObjectsAttributes();
      }

      if (this.objects && this.objects[0] && !!this.objects[0]['fields']) {
        this.customEventsDataStr = Object.keys(this.objects[0]['fields'])
          .map(key => {
            return `${key}: ${this.objects[0]['fields'][key]}`;
          })
          .join(', ');
      }
    }
  }

  getEvents() {
    return this.store$.pipe(
      select(
        ThumbnailsSelectors.selectEventsByEdgeIdCameraIdAndBase({
          edgeId: this.edgeId,
          cameraId: this.cameraId,
          base: this.base,
        }),
      ),
    );
  }

  keepRatio() {
    const width = this.container.nativeElement.clientWidth;
    if (this.playback?.elemRef?.nativeElement) {
      this.renderer.setStyle(this.playback?.elemRef?.nativeElement, 'min-height', this.container.nativeElement.clientHeight + 25 + 'px');
    }
  }

  ngAfterViewInit(): void {
    this.afterViewInit = true;
    if (this.isDialog) {
      if (this.isSharedAlert) {
        this.aspectRatio = 16 / 9;
        //todo must be in effects
        if (this.isSharedAlert) {
          this.sharedApiService.getCameraRatio(this.selectedCamera)
            .subscribe(res => {
              this.aspectRatio = res.aspectRatio;
              const width = this.container?.nativeElement?.clientWidth;
              this.container.nativeElement.style.height = `${(width * 1) / this.aspectRatio}px`;
            });
        } else {
          this.grantedAccessService.getCameraRatio(this.selectedCamera)
            .subscribe(res => {
              this.aspectRatio = res.aspectRatio;
              const width = this.container?.nativeElement?.clientWidth;
              this.container.nativeElement.style.height = `${(width * 1) / this.aspectRatio}px`;
            });
        }

      }
      this.elementRef.nativeElement.focus({ preventScroll: true });
      this.videoService.setSpeed(1);
    }

    this.parent = this.elementRef.nativeElement.parentElement;


    this.observer = new ResizeObserver(entries => {
      window.requestAnimationFrame(() => {
        this.setPeriodTitle();
        if (!!this.timeline) {
          this.timeline?.resize();
        }
        if (!!this.playback) {
          this.playback?.resize();
        }
      });
    });
    this.observer.observe(this.parent);

    const hasImage = !!this.objects?.length && !!this.objects?.filter(obj => !!obj.url || !!obj.zoomImage)?.length;

    if (this.isDialog && this.showObjects && hasImage) {
      this.selectBestImageSection();
    }
    this.setContainerMaxHeight();

    if (this.isLiveView) {
      // keep timeline synced with current time in case dialog is closed
      this.toggleToLiveView(false);
    }
  }

  setContainerMaxHeight() {
    if (!!this.timelineWrapper) {
      this.cd.detectChanges();
    }
  }

  setPeriodTitle() {
    if (!this.container) {
      return;
    }
    const width = this.container?.nativeElement?.clientWidth;
    this.container.nativeElement.style.height = `${(width * 1) / this.aspectRatio}px`;
    const innerWidth = this.parent.clientWidth;
    const startT = this.cameraThumbnailsService.convertTsToZone(this.options.startTime, this.thumbnailsData.timezone!, moment.tz.guess());
    const endT = this.cameraThumbnailsService.convertTsToZone(this.options.endTime, this.thumbnailsData.timezone!, moment.tz.guess());
    if (innerWidth < 1000) {
      const startHour = formatDate(startT, this.seconds ? 'h:mm:ss a' : 'MM/dd. h:mm', 'en-US');
      const endHour = formatDate(endT + 1, this.seconds ? 'h:mm:ss a' : 'MM/dd. h:mm', 'en-US');
      this.period = this.rtl ? `${endHour} - ${startHour}` : `${startHour} - ${endHour}`;
    } else {
      const startHour = formatDate(startT, this.seconds ? 'h:mm:ss a' : 'MMM, dd. h:mm a', 'en-US');
      const endHour = formatDate(endT + 1, this.seconds ? 'h:mm:ss a' : 'MMM, dd. h:mm a', 'en-US');
      this.period = this.rtl ? `${endHour} - ${startHour}` : `${startHour} - ${endHour}`;
    }
  }

  get developer() {
    return this.mainMenuService.developerRole();
  }

  getTimestampForIdx(index) {
    return this.base + index * (this.lessThanAMinute ? THUMBNAIL_DIALOG_FREQUENCY : this.options.duration);
  }

  getIndexForTimestamp(timestamp) {
    return Math.floor((timestamp - this.base) / this.options.duration);
  }

  getEventForPercentage(percentage: number) {
    const idx = Math.floor(this.events?.length * percentage);
    return this.events[idx];
  }

  buildUris() {
    this.thumbnailsUris = [];
    for(let idx in this.events) {
      if (this.events[idx] === 0) {
        this.thumbnailsUris.push('');
        continue;
      }

      const ts = this.getTimestampForIdx(idx);

      if (!this.first) {
        if (!!this.defaultThumbnail) {
          const ts = +this.defaultThumbnail.split('-')[1];
          const replica = +this.defaultThumbnail.split('-')[2];
          this.first = this.buildFileName(ts, replica);
        } else {
          this.first = this.buildFileName(ts);
        }
        this.currentTs = +this.getTsFromFilename(this.first);
      }

      if (this.events[idx] === 1) {
        this.thumbnailsUris.push(this.buildFileName(ts));
        continue;
      }

      for(let i = 0; i < this.events[idx]; i++) {
        this.thumbnailsUris.push(this.buildFileName(ts, i));
      }
    }
  }

  public timezoneAbbreviation() {
    const cameraZone = moment()
      .tz(this.thumbnailsData?.timezone ?? moment.tz.guess())
      .format('z');
    return cameraZone;
  }

  async checkStorage(time) {
    const noStorage = await this.isOffline(time);
    this.noStorage = noStorage;
  }

  ngOnInit(): void {
    this.developerRole = this.developer;
    if (this.isDialog && !this.cameraId) {
      this.store$.dispatch(AuthenticationActions.Logout({ reason: 'cameraId was not provided' }));
      throw new Error('cameraId was not provided');
    }
    if (this.isLiveView) {
      this.selectedSection = ThumbnailSection.VIDEO;
      this.streamLiveView = true;
      this.setData();
    }

    this.options = _.cloneDeep(this.optionsInput);


    this.base = this.options.base ? this.options.base : this.options.startTime;
    this.baseStart = this.cameraThumbnailsService.getBaseInLocale(new Date(this.options.startTime));
    this.baseEnd = this.cameraThumbnailsService.getBaseInLocale(new Date(this.options.endTime));

    this.currentTs = this.base;

    if (!this.edgeId) {
      this.store$
        .select(CameraSelectors.selectCameraById(this.cameraId))
        .pipe(
          take(1),
          filter(camera => !!camera),
        )
        .subscribe(camera => {
          this.edgeId = camera.edgeId;
          this.initLastMp4();
          this.selectedCameras[0] = camera;
          this.liveViewCamera = {
            edgeId: camera.edgeId,
            locationId: camera.locationId,
            cameraId: camera.edgeOnly.cameraId,
          };

          this.store$.dispatch(MultiPlaybackActions.setSelectedCameras({ selectedCameras: this.selectedCameras }));
        });
    }

    if (this.isSharedAlert) {
      this.edgeId = this.selectedCamera?.edgeId;
      this.initLastMp4();
      this.selectedCameras[0] = this.selectedCamera;
      this.store$.dispatch(MultiPlaybackActions.setSelectedCameras({ selectedCameras: this.selectedCameras }));


    }

    if (!!this.cameraId) {
      this.cameraName$ = this.camerasService.getCameraName(this.cameraId);
    }

    if (!this.alertPreview && !this.isLiveView) {
      this.cameraThumbnailsService.thumbnailsData$.pipe(take(1))
        .subscribe(data => {
          this.thumbnailsData = data;
        });
    }

    // if (!this.events?.length) {
    //   const eventsLen = Math.ceil((this.options.endTime - this.options.startTime) / this.options.duration);
    //
    //   this.events = new Array<number>(eventsLen).fill(0);
    //   this.store$
    //     .select(
    //       ThumbnailsSelectors.selectEventsByEdgeIdCameraIdAndRange({
    //         edgeId: this.edgeId,
    //         cameraId: this.cameraId,
    //         end: this.options.endTime,
    //         start: this.options.startTime,
    //       }),
    //     )
    //     .pipe(filter(events => !!events))
    //     .subscribe(events => {
    //       this.events = events;
    //       this.buildUris();
    //       this.reset();
    //       this.cd.detectChanges();
    //     });
    // }

    this.buildUris();

    this.playing$ = this.playbackPlayerService.playing$;

    this.subscriptions.push(
      this.store$
        .pipe(select(CameraSelectors.selectCameraById(this.cameraId)))

        .subscribe(camera => {
          if (!camera) {
            return;
          }
          if (this.isDialog) {
            this.store$.dispatch(ArchiveAction.changeArchiveProperty({ property: 'selectedCamera', value: camera }));
            const width = camera?.edgeOnly?.mainStream?.main?.width ?? 16;
            const height = camera?.edgeOnly?.mainStream?.main?.height ?? 9;
            this.aspectRatio = width / height;
          }
          this.locationId = camera?.locationId;
          const edgeId = camera?.edgeId;
          this.edgeId = camera?.edgeId;
          this.initLastMp4();
          if (this.isDialog) {
            this.subscriptions.push(
              this.playbackPlayerService.playbackBase$.pipe(filter(res => !!res.sessionId))
                .subscribe(res => {
                  this.src = `${this.streamBase}/${res.sessionId}/s.m3u8`;
                  this.playback?.reloadPlayer();
                }),
            );

            this.subscriptions.push(
              this.playbackPlayerService.streamBase$.pipe(concatMap(_ => this.playbackPlayerService.playbackBase$))
                .subscribe(res => {
                  this.src = `${this.streamBase}/${res.sessionId}/s.m3u8`;
                  this.playback?.reloadPlayer();
                }),
            );
          }
        }),
    );

    this.subscriptions.push(
      this.playing$.subscribe(playing => {
        this.playing = playing;
      }),
    );

    if (this.thumbnailsData) {
      this.updateThumbsData(this.thumbnailsData);
    } else {
      this.detailsSub = this.cameraThumbnailsService.thumbnailsData$.subscribe(data => {
        this.updateThumbsData(data);
      });
    }

    const startT = this.cameraThumbnailsService.convertTsToZone(this.options.startTime, this.thumbnailsData.timezone!, moment.tz.guess());
    const endT = this.cameraThumbnailsService.convertTsToZone(this.options.endTime, this.thumbnailsData.timezone!, moment.tz.guess());
    if (this.thumbnailTemplate === ThumbnailTemplate.TEMPLATE1) {
      this.date = formatDate(startT, 'EEE, MMMM d, y', 'en-US');
      const endDate = formatDate(endT, 'EEE, MMMM d, y', 'en-US');
      if (this.date !== endDate) {
        const start = formatDate(startT, 'EEE, MMMM d - ', 'en-US');
        const end = formatDate(endT, 'd, y', 'en-US');
        this.date = start + end;
      }
    }
    if (this.thumbnailTemplate === ThumbnailTemplate.TEMPLATE2) {
      this.date = formatDate(startT, 'MM/d/yy', 'en-US');
      const endDate = formatDate(endT, 'MM/d/yy', 'en-US');
      if (this.date !== endDate) {
        const start = formatDate(startT, 'MM/d/yy - ', 'en-US');
        const end = formatDate(endT, 'MM/d/yy', 'en-US');
        this.date = start + end;
      }
    }

    if (this.thumbnailTemplate === ThumbnailTemplate.TEMPLATE1) {
      const startHour = formatDate(startT, this.seconds ? 'h:mm:ss a' : 'h:mm a', 'en-US');
      const endHour = formatDate(endT + 1, this.seconds ? 'h:mm:ss a' : 'h:mm a', 'en-US');
      this.startHour = startHour;
      this.endHour = endHour;
      this.period = this.rtl ? `${endHour} - ${startHour}` : `${startHour} - ${endHour}`;
    }

    if (this.thumbnailTemplate === ThumbnailTemplate.TEMPLATE2) {
      const startYear = formatDate(startT, 'yyyy', 'en-US');
      const endYear = formatDate(endT, 'yyyy', 'en-US');

      const startMonth = formatDate(startT, 'MMM', 'en-US');
      const endMonth = formatDate(endT, 'MMM', 'en-US');

      const startDay = formatDate(startT, 'dd', 'en-US');
      const endDay = formatDate(endT, 'dd', 'en-US');

      const sameYear = startYear === endYear;
      const sameMonth = startMonth === endMonth;

      const sameDay = startDay === endDay && endT - startT < 86400000;

      const startHour = formatDate(startT, this.seconds ? 'h:mm:ss a' : 'yyyy MMM dd, h:mm a', 'en-US');
      const endHour = formatDate(endT + 1, this.seconds ? 'h:mm:ss a' : ` ${sameYear ? '' : 'yyyy '}${sameMonth ? '' : 'MMM '} ${sameDay ? '' : 'dd, '}h:mm a`, 'en-US');

      this.startHour = startHour;
      this.endHour = endHour;
      this.period = this.rtl ? `${endHour} - ${startHour}` : `${startHour} - ${endHour}`;
    }

    if (this.isDialog) {
      if (this.isSharedAlert) {
        this.displayVideo = this.online;
      } else {
        this.store$.select(EdgeHeartbeatPulsationSelectors.selectEdgeStatusByEdgeId(this.edgeId))
          .subscribe(status => {
            if (status?.status === PulsationModels.ComponentStatus.Online) {
              this.displayVideo = true;
            } else {
              this.displayVideo = false;
            }
          });
      }

      const baseStart = this.cameraThumbnailsService.getBaseInLocale(new Date(this.options.startTime));
      const baseEnd = baseStart + DAY_IN_MSECS;

      this.selectSelectedCameras$.pipe(untilDestroyed(this), filter(selectedCameras => !!selectedCameras.length))
        .subscribe(selectedCameras => {
          this.selectedCameras = selectedCameras;
          this.setContainerMaxHeight();
          // this.mediaCacheService.getStorageOldestVids(this.selectedCameras.filter(camera => !!camera));
          if (this.isDialog) {
            this.mediaCacheService.getStorageStats(selectedCameras.filter(camera => !!camera), baseStart, baseEnd - 2000, this.isSharedAlert);
            this.mediaCacheService.getThumbnails(selectedCameras.filter(camera => !!camera), baseStart, baseEnd - 2000, this.isSharedAlert);
          }
        });


      // Check storage of start base
      this.store$.select(
          StorageSelectors.selectStorageEventsByEdgeIdCameraIdAndBase({ edgeId: this.edgeId, cameraId: this.cameraId, base: baseStart }),
        )
        .pipe(filter(stats => !!stats), take(1), timeout(3000),
          catchError(err => {
            this.noStorage = true;
            return of(null);
          }),
        )
        .subscribe(stats => {
          this.checkStorage(this.currentTs);
        });

      this.selectMove$.subscribe(async (move: MultiPlaybackMove) => {
        if (!!this.edgeId && !!this.cameraId && !!move.timestamp && (move.percentage || move.percentage === 0)) {
          this.timestamp = move.timestamp;
          this.percentage = Math.max(move.percentage, 0);
          if (this.playback?.webrtcPlayer?.isPlaying && this.percentage >= 0.99 && this.isSharedAlert) {
            this.playback.webrtcPlayer.stop(false, true);
          }
          const index = this.getThumbnailIndex(this.percentage);
          this.dragTs = !!this.thumbnailsUris[index] ? +this.getTsFromFilename(this.thumbnailsUris[index]) : this.timestamp;
          this.checkStorage(this.timestamp);

          if (!!this?.playback?.isEmulating) {
            if (move.percentage >= 1) {
              this.timeline.moveTimeline(PLAYBACK_OFFLINE_INTERVAL_MS);
              this.options.startTime += PLAYBACK_OFFLINE_INTERVAL_MS;
              this.options.endTime += PLAYBACK_OFFLINE_INTERVAL_MS;
              this.setRange();
              // this.setData();

            } else {
              this.timeline.renderScrollerPosition();
            }
          }
        }
      });
      if (!this.isLiveView) {
        this.mediaCacheService.getThumbnailBits(this.selectedCameras.filter(camera => !!camera), this.options.startTime, this.options.endTime, this.isSharedAlert);
      }
    }

    if (this.containsPeople) {
      this.getPeople();
    }

    this.currentTs = this.options.startTime;
    this.timestamp = this.optionsInput.startTime;
    this.resetTimestamp = this.optionsInput.startTime;
    if (this.searchEvents?.length || this.alertPreview) {
      if (this.alertPreview) {
        this.resetTimestamp = this.normalizeTimestamp(this.alertTs, 2000);
      } else {
        this.resetTimestamp =
          this.normalizeTimestamp(this.optionsInput.startTime, 20000);
      }
    }
    if (this.searchEvents?.length) {
      const ts = this.resetTimestamp;
      this.timestamp = this.resetTimestamp;
    }
    this.percentage = 0;
    this.setImage();
    this.store$.select(
        ThumbnailsSelectors.selectEventsByEdgeIdCameraIdAndBase({ edgeId: this.edgeId, cameraId: this.cameraId, base: this.getBaseInLocale(new Date(this.options?.startTime)) }),
      )
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (!!data) {
          this.setImage();
        }
      });

    this.fetchThumbsDebouncer.pipe(debounceTime(3000))
      .subscribe(() => {
        let cameras = [];
        if (this.isDialog) {
          cameras = this.selectedCameras.filter(camera => !!camera);
        } else {
          cameras = [{ edgeId: this.edgeId, cameraId: this.cameraId }];
        }
        this.mediaCacheService.getThumbnails(cameras, this.options.startTime, this.options.endTime, this.isSharedAlert);
      });

    this.hintDebouncer.pipe(untilDestroyed(this), debounceTime(500), take(1))
      .subscribe(async () => {
        this.showHint = true;
        setTimeout(() => {
          this.showLeftGif = true;
        }, 500);
        setTimeout(() => {
          this.showEnterGif = true;
        }, 300);
        const offsetDuration = this.offsetResInDurations;
        this.offsetResInDurations = 1;
        await this.loadKeyboardThumb();
        this.offsetResInDurations = offsetDuration;
      });
    if (this.initialTs && !this.isLiveView) {
      this.timestamp = this.initialTs;
      this.percentage = (this.timestamp - this.options.startTime) / (this.options.endTime - this.options.startTime);
      setTimeout(() => {
        this.setMove();
      }, 200);
    } else {
      if (this.isLiveView) {
        this.timestamp = Date.now();
      }
    }
  }

  getBaseInLocale(date: Date) {
    return this.cameraThumbnailsService.getBaseInLocale(date, this.timezone);
  }

  private updateThumbsData(data: CameraThumbnailsData) {
    if (!data) {
      return;
    }
    this.thumbnailsData = data;
    if (!data.timezone) {
      this.thumbnailsData.timezone = 'GMT';
    }

    if (!!data.cameraId) {
      this.cameraId = data.cameraId;
    } else {
      this.cameraId = this.options?.cameraId ?? this.cameraId ?? '';
    }

    if (!!data.edgeId) {
      this.edgeId = data.edgeId;
    } else {
      this.edgeId = this.options?.edgeId ?? this.edgeId ?? '';
    }
    this.initLastMp4();

    this.base24 = this.cameraThumbnailsService.getBaseInLocale(new Date(this.options.startTime));

    if (this.options.offsetResInDurations) {
      this.offsetResInDurations = this.options.offsetResInDurations ?? 6;
    } else {
      this.offsetResInDurations = this.thumbnailsData.offsetResInDurations ?? 6;
    }

    if (this.isDialog) {
      this.offsetResInDurations = 1;
    }

    this.playerDuration = this.options.endTime - this.options.startTime;

    if (!!data.edgeId && !!data.cameraId) {
      this.baseUrl = `${environment.thumbnailsUrl}/thumbnails/${data.edgeId}/${data.cameraId}`;
    } else {
      this.baseUrl = `${environment.thumbnailsUrl}/thumbnails/${this.edgeId}/${this.cameraId}`;
    }
    this.base = this.options.startTime;
    this.reset();

  }

  async setImage() {
    const base = this.cameraThumbnailsService.getBaseInLocale(new Date(this.timestamp));
    return this.store$.select(
        ThumbnailsSelectors.selectEventsByEdgeIdCameraIdAndBase({ edgeId: this.edgeId, cameraId: this.cameraId, base }),
      )
      .pipe(filter(thumbs => !!thumbs), take(1), timeout(3000),
        catchError(err => {
          // this.offline = true;
          return of(null);
        }),
      )
      .subscribe(async _ => {
        this.offline = false;
        if (!this.isDialog) {
          this.img = await this._img();
        }
      });
  }

  checkProcessing() {
    if (this.alertProcessing) {
      return true;
    }
    const timestamp = this.alertTs;
    this.alertProcessingTime = timestamp + 65 * 1000 - Date.now();
    if (this.alertProcessingTime > 0) {
      this.alertProcessing = true;
      setTimeout(async () => {
        this.alertProcessing = false;
        const baseStart = this.cameraThumbnailsService.getBaseInLocale(new Date(this.options.startTime));
        const baseEnd = this.cameraThumbnailsService.getBaseInLocale(new Date(this.options.endTime));
        // await this.mediaCacheService.getStorageStats(this.selectedCameras.filter(camera => !!camera), baseStart, baseEnd - 2000);
        setTimeout(() => {
          this.play();
        }, THUMBNAIL_DEFAULT_FREQUENCY);
      }, this.alertProcessingTime);
      return true;
    }
    return false;
  }

  public roundToNext5Seconds(seconds: number): number {
    return Math.round(seconds / 5) * 5;
  }

  ngOnDestroy(): void {
    if (!!this.detailsSub) {
      this.detailsSub.unsubscribe();
    }
    for(let sub of this.subscriptions) {
      sub.unsubscribe();
    }
    if (this.observer) {
      this.observer.unobserve(this.parent);
      this.observer.disconnect();
    }
  }

  getTsFromFilename(filename: string) {
    return filename?.split('-')[0];
  }

  buildFileName(ts: number, replica: number = 0) {
    return `${ts}-${replica}-0`;
  }

  buildUrl(filename: string) {
    return `${this.baseUrl}/thumbnail-${filename}.jpg`;
  }

  buildMainThumbnailUrl(mainThumbnail: string) {
    return `${this.baseUrl}/${mainThumbnail}`;
  }

  bestImageUri(object: string) {
    return this.type === ThumbnailDialogType.ALERT
      ? `https://d32bjbwejsjjzu.cloudfront.net/alerts/${this.edgeId}/${this.cameraId}/${object}`
      : `${environment.trainingThumbnailsUrl}/crop/${this.edgeId}/${this.cameraId}/${object}`;
  }

  convertTsToLocation(ts) {
    return this.cameraThumbnailsService.convertTsToZone(ts, this.thumbnailsData.timezone!, moment.tz.guess());
  }

  setMove() {
    this.store$.dispatch(MultiPlaybackActions.setMove({ move: { percentage: this.percentage, timestamp: this.timestamp } }));
  }

  setDefault() {
    this.store$.dispatch(MultiPlaybackActions.setDefault());
  }

  setData() {
    this.store$.dispatch(MultiPlaybackActions.setData({
      data: {
        timezone: this.thumbnailsData?.timezone ?? moment.tz.guess(),
        offsetResInDurations: 1, //this.offsetResInDurations,
        startTime: Math.floor(this.options.startTime),
        endTime: this.options.endTime,
        duration: this.options.duration,
        base: this.base,
      },
    }));
  }

  setRange() {
    this.store$.dispatch(MultiPlaybackActions.setRange({
      startTime: Math.floor(this.options.startTime),
      endTime: this.options.endTime,
    }));
  }

  async reset() {
    this.timeFormat = 'h:mm:ss a';
    this.offline = false;
    this.setDefault();
    this.timestamp = this.resetTimestamp;
    this.img = await this._img();
    this.scrollerPosition = 0;
  }

  getThumbnailIndex(percentage: number) {
    const offsetResInDurations = this.isDialog ? 1 : this.offsetResInDurations;
    const calc = offsetResInDurations
      ? Math.floor((this.thumbnailsUris.length / offsetResInDurations) * percentage) * offsetResInDurations
      : Math.floor(this.thumbnailsUris.length * percentage);
    return calc;
  }

  getThumbnailTimestamp(percentage: number) {
    // const index = this.getThumbnailIndex(percentage);
    // const ts = this.getTsFromFilename(this.thumbnailsUris[index]);
    // if (!!ts) {
    //   // return this.cameraThumbnailsService.convertTsToZone(+ts, this.thumbnailsData.timezone!, moment.tz.guess());
    //   return +ts;
    // }
    // const idx = Math.floor(((this.options.endTime - this.options.startTime) / (this.lessThanAMinute ? THUMBNAIL_DIALOG_FREQUENCY : this.options.duration)) * percentage);
    // return this.getTimestampForIdx(idx);
    return Math.floor((this.options.endTime - this.options.startTime) * percentage) + this.options.startTime;
  }

  getThumbnailExists(percentage: number) {
    const index = this.getThumbnailIndex(percentage);
    return !!this.thumbnailsUris[index];
  }


  async move(event: MouseEvent) {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const offset = event.clientX - rect.left;
    const width = rect.width;

    if (offset < 0 || !width) {
      return;
    }

    this.scrollerPosition = offset;
    const percentage = this.rtl ? 1 - offset / width : offset / width;
    let timeStamp = this.getThumbnailTimestamp(percentage);

    this.timestamp = this.normalizeTimestamp(timeStamp, 2000);
    this.percentage = percentage;
    if (this.isDialog) {
      this.setMove();
    }

    this.img = await this._img();
    if (!!this.showHint) {
      this._hideHint();
    }
    this.showLeftGif = false;
    this.showEnterGif = false;

    this.hintDebouncer.next();
  }

  loadThumbnails() {
    if (this.loaded) {
      return;
    }
    this.cameraThumbnailsService
      .getThumbnailsByDateFromDb(this.thumbnailsData.edgeId, this.thumbnailsData.cameraId, this.options.startTime, this.options.endTime, null, null, this.isSharedAlert)
      .subscribe(timestamps => {
        this.loaded = true;
      });
  }

  public openDialog() {

    this.store$.dispatch(MultiPlaybackActions.resetToInitialState());
    this.setData();

    let initialTs = this.displayTime;
    if (this.searchEvents) {
      initialTs = Math.max(this.options.startTime, Math.max(this.cameraThumbnailsService.normalizeTimestamp(this.searchEvents[0][0], 20000, false, false), this.searchEvents[0][0] - 10000));
    }
    const data: ThumbnailDialogData = {
      isShareVisible: this.isShareVisible,
      options: this.options,
      events: this.events,
      searchEvents: this.searchEvents,
      objects: this.objects,
      edgeId: this.edgeId,
      defaultThumb: this.defaultThumbnail,
      cameraId: this.cameraId,
      rtl: this.rtl,
      showObjects: this.showObjects,
      initialTs: initialTs, //this.displayTime,
      cameraView: this.cameraView,
    };

    this.onOpen.emit();

    this.dialog.open(ThumbnailDialogComponent, {
        panelClass: 'thumbnail-dialog-wrapper',
        width: '110vh',
        maxHeight: '97vh',
        data,
      })
      .afterClosed()
      .subscribe(() => {
        this.onClosed.emit();
      });
  }

  countEvents() {
    return this.events.filter(events => events > 1).length;
  }

  seek(event: MouseEvent) {
  }

  public toggleChart(visible: boolean): void {
    return;
    this.showChart.emit(visible);
  }

  startPlayback(ts: number) {
    this.playbackPlayerService.setPlaybackBase({
      cameraId: this.cameraId,
      edgeId: this.edgeId,
      locationId: this.locationId!,
    });
    this.playback.startPlayback(ts);
  }

  locationPlayback(ts: number) {
    this.playback.locationPlayback(this.currentTs);
  }

  public isHlsPlayback(ts?: number) {
    return ts > this.lastMp4Ts;
  }

  initLastMp4() {
    if (!this.edgeId) {
      console.log('Try to init lastMp4 without edgeId configured');
      return;
    }
    if (!this.lastMp4Ts) {
      this.selectEdgeLastMp4Ts$ = this.store$.pipe(select(EdgeSelectors.selectEdgeLastMp4Ts(this.edgeId)));
      this.selectEdgeLastMp4Ts$.pipe(untilDestroyed(this), take(1))
        .subscribe(ts => {
          if (!!ts) {
            this.lastMp4Ts = ts;
          } else {
            //todo move to effects
            if (this.isSharedAlert) {
              this.sharedApiService.getEdgeLastMp4(this.edgeId)
                .subscribe(lastMp4Ts => {
                  this.lastMp4Ts = lastMp4Ts;
                });
            } else {
              this.statsService.getEdgeLastMp4(this.edgeId)
                .subscribe(lastMp4Ts => {
                  this.lastMp4Ts = lastMp4Ts;
                });
            }
          }
        });
    }
  }

  public get lastFiveMinutes() {
    const lastFiveMinutes = Date.now() - 5 * 60 * 1000;
    return this.currentTs >= lastFiveMinutes;
  }

  play(init = false) {
    if (!!this.alertTs && !this.isHlsPlayback(this.alertTs)) {
      const processing = this.checkProcessing();
      if (processing) {
        this.selectedSection = ThumbnailSection.VIDEO;
        return;
      }
    }


    if (this.noStorage && !this.lastFiveMinutes) {
      return;
    }
    if (!this.isSharedAlert && !this.displayVideo) {
      return;
    }

    if (!this.currentTs) {
      return;
    }
    this.videoService.setLive(false);
    this.startedPlaying = true;
    this.cd.detectChanges();
    this.playerTime = this.currentTs;
    this.playbackPlayerService.setTime(this.playerTime);
    this.playbackPlayerService.initTimeLine(this.currentTs);
    this.playback.setBaseTime(this.playerTime);
    if (this.playback?.elemRef?.nativeElement) {
      this.renderer.setStyle(this.playback?.elemRef?.nativeElement, 'min-height', this.container.nativeElement.clientHeight + 25 + 'px');
    }
    if (!this.playing) {
      this.playback.startPlayback(this.currentTs);
    } else {
      this.locationPlayback(this.currentTs);
    }
    setTimeout(() => {
      this.selectedSection = ThumbnailSection.VIDEO;

    }, 100);
    this.setDialogSize();
    setTimeout(() => {
      this.timeline.redrawAll();
    });
    this.videoService.OnPlay();
  }

  selectImagesSection() {
    if (this.selectedSection === ThumbnailSection.VIDEO) {
      // this.playback.stopPlayback();
      this.videoService.OnStop();
    }
    this.selectedSection = ThumbnailSection.IMAGES;
  }

  selectBestImageSection() {
    if (!this._objects?.length) {
      return;
    }
    if (this.selectedSection === ThumbnailSection.VIDEO) {
      this.playback.webrtcPlayer.stop(false, true);
    }
    this.selectedSection = ThumbnailSection.BEST_IMAGE;

    this.toggleChart(true);
  }

  selectAlertActivitySection() {
    if (this.selectedSection === ThumbnailSection.VIDEO) {
      this.playback.webrtcPlayer.stop(false, true);
    }
    this.selectedSection = this.selectedSection = ThumbnailSection.ALERT_ACTIVITY;
    this.store$.dispatch(OrganizationUsersActions.getOrganizationUsers());
    this.loadingAlertActivity = true;
    this.alertsService.getActivity(this.alertId)
      .pipe(
        catchError(err => {
          this.loadingAlertActivity = false;
          this.store$.dispatch(SharedActions.showMessage({ error: err.error?.message ?? 'Failed to get alert actions' }));
          return of();
        }),
      )
      .subscribe(res => {
        debugger
        this.alertActivity = res;

        this.loadingAlertActivity = false;
      });
  }

  selectFacesSection() {
    if (!(!!this._objects?.length && this.isFaces(this._objects))) {
      return;
    }
    if (this.selectedSection === ThumbnailSection.VIDEO) {
      this.playback.webrtcPlayer.stop(false, true);
    }
    this.selectedSection = this.selectedSection = ThumbnailSection.FACES;
    this.toggleChart(true);
  }

  selectChartSection() {
    this.selectedSection = this.selectedSection = ThumbnailSection.CHART;
    this.toggleChart(true);
  }

  selectVideoSection() {
    if (!this.startedPlaying) {
      if (this.defaultThumbnail) {
        const ts = +this.defaultThumbnail.split('-')[1];
        this.currentTs = ts;
      } else {
        // this.currentTs = (this.options.startTime + this.options.endTime) / 2;
      }
      this.play(true);
      return;
    }
    this.setDialogSize();
    this.selectedSection = ThumbnailSection.VIDEO;
  }

  bestImageRatio(event: Event) {
    const best: HTMLImageElement = this.best.nativeElement;
    const ratio = best.width / best.height;
    if (ratio > 1) {
      best.style.width = '100%';
    } else {
      best.style.height = '100%';
    }
  }

  track(searchObject: Search.SearchObject, face: boolean = false) {
    const trackObjectState: TrackObjectState = {
      ...searchObject,
      onlyFaceId: face,
      cameraId: this.cameraId,
      url: searchObject.zoomImage ? this.bestImageUri(searchObject.zoomImage) : this.bestImageUri(searchObject.url),
      multi: this.thumbnailsData.multiSearch,
    };
    this.store$.dispatch(TrackObjectActions.setTrackObject({ trackObjectState }));
    this.router.navigateByUrl('/cameras/track');
    this.closeDialog.emit(null);
  }

  public async isSmartStorage() {
    return await this.mediaCacheService.isSmartStorage(this.edgeId, this.cameraId, this.timestamp);
  }

  public async showCreateArchiveModal() {
    const isSmartStorage = await this.isSmartStorage();
    if (isSmartStorage.exists) {
      this.store$.dispatch(
        ArchiveAction.updateAddArchiveModalConfig({
          property: 'hasSmartStorage',
          disable: true,
        }),
      );
      this.store$.dispatch(
        ArchiveAction.changeArchiveProperty({
          property: 'smartStorageInterval',
          value: isSmartStorage.interval,
        }),
      );
    } else {
      this.store$.dispatch(
        ArchiveAction.updateAddArchiveModalConfig({
          property: 'hasSmartStorage',
          disable: false,
        }),
      );
    }
    this.store$.dispatch(
      ArchiveAction.updateAddArchiveModalConfig({
        property: 'disableSelectedCamera',
        disable: true,
      }),
    );
    this.store$.dispatch(
      ArchiveAction.changeArchiveProperty({
        property: 'start',
        value: moment(this.currentTs)
          .toString(),
      }),
    );
    this.store$.dispatch(
      ArchiveAction.changeArchiveProperty({
        property: 'name',
        value: this.searchEvents ? 'Untitled search archive' : this.alertName ? `Alert archive - ${this.alertName}` : 'Untitled Alert Archive',
      }),
    );
    this.store$.dispatch(
      ArchiveAction.changeArchiveProperty({
        property: 'selectedCamera',
        value: this.selectedCameras[0],
      }),
    );
    this.store$.dispatch(
      ArchiveAction.changeArchiveProperty({
        property: 'isArchiveValid',
        value: true,
      }),
    );
    this.store$.dispatch(
      ArchiveAction.changeArchiveProperty({
        property: 'end',
        value: moment(this.options.startTime)
          .add('2', 'minute')
          .toString(),
      }),
    );
    this.store$.dispatch(
      ArchiveAction.changeArchiveProperty({
        property: 'timezone',
        value: this.thumbnailsData.timezone ?? moment.tz.guess(),
      }),
    );
    this.dialog
      .open(AddArchiveModalComponent, {
        panelClass: 'thumbnail-dialog-wrapper',
        width: '502px',
      })
      .afterClosed()
      .subscribe();
  }

  deleteCamera(index?: number) {
    this.selectedCameras = [...this.selectedCameras];
    this.selectedCameras[index] = undefined;
    const count = this.selectedCameras.filter((camera) => !!camera).length;
    this.displayCount = count;
    if (count === 1) {
      this.setDialogSize();
      this.layout = this.WallLayout.GRID_1;
      if (this.isFullscreen) {
        this.maximize();
      }
    }
    this.store$.dispatch(MultiPlaybackActions.setCameraEvents({ idx: index, events: [] }));
    this.store$.dispatch(MultiPlaybackActions.setSelectedCameras({ selectedCameras: this.selectedCameras }));
    this.cd.detectChanges();
  }

  selectCameras(selectResult?: ThumbnailLayoutsOnSelectedResult) {
    const index = selectResult?.position;
    const data: CameraSelectorDialogData = {
      selectedCameras: index ? undefined : this.selectedCameras,
      multi: !index,
      atLeastOne: true,
    };
    this.dialog.open(CameraSelectorComponent, {
        panelClass: 'modal-no-padding',
        width: '572px',
        data,
      })
      .afterClosed()
      .subscribe((cameras: EdgeCamera.CameraItem[]) => {
        if (!cameras) {
          return;
        }
        this.selectedSection = ThumbnailSection.IMAGES;
        this.selectedCameras = [...this.selectedCameras];
        if (index && cameras[0]) {
          this.selectedCameras[index] = cameras[0];
        } else {
          this.selectedCameras = cameras;
        }
        this.store$.dispatch(MultiPlaybackActions.setSelectedCameras({ selectedCameras: this.selectedCameras }));
        if (index === undefined) {
          this.selectLayoutForCount(this.selectedCameras.length);
        }
        this.timeline.redrawAll();
        this.cd.detectChanges();
      });

  }

  selectLayoutForCount(count: number) {
    switch (count) {
      case 1:
        this.changeLayout(this.WallLayout.GRID_1);
        break;
      case 2:
        this.changeLayout(this.WallLayout.GRID_2);
        break;
      case 3:
        this.changeLayout(this.WallLayout.GRID_3);
        break;
      case 4:
        this.changeLayout(this.WallLayout.GRID_4);
        break;
      case 5:
      case 6:
      default:
        this.changeLayout(this.WallLayout.GRID_6);
        break;
    }
  }

  setDialogSize(width = '110vh', height = 'auto') {
    if (this.isSharedAlert) {
      return;
    }
    this.dialogRef?.updateSize(width, height);
  }

  setLayoutSize() {
    const count = this.selectedCameras.filter((camera) => !!camera).length;
    if (count === 1) {
      this.setDialogSize();
      return;
    }
    switch (this.layout) {
      case this.WallLayout.GRID_2:
        this.setDialogSize('130vh', 'auto');
        break;
      case this.WallLayout.GRID_3:
      case this.WallLayout.GRID_4:
      case this.WallLayout.GRID_6:
        this.setDialogSize('140vh', 'auto');
        break;
      case this.WallLayout.GRID_9:
        this.setDialogSize('70vw', '100%');
        break;
      default:
        this.setDialogSize('110vh', 'auto');
        break;
    }
  }

  public changeLayout(layout: WallV2Model.WallLayout) {
    this.layout = layout;
    this.displayCount = WallV2Model.wallLayoutCameraCountV2[layout];
    this.displayIndexes = [...Array(this.displayCount)
      .keys()];
    this.setLayoutSize();
    this.store$.dispatch(MultiPlaybackActions.setLayout({ layout }));
  }

  get isFullscreen(): boolean {
    return this.utilsService?.isFullscreen();
  }

  async maximize(el?: HTMLDivElement) {
    // this.player.maximize();
    const elem = el ? el : this.selectedSection === ThumbnailSection.VIDEO ? this.playbackWrapper?.nativeElement : this.mainWrapper?.nativeElement;
    if (!this.isFullscreen) {
      if (elem.requestFullscreen) {
        await elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        await elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        await elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        await elem.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
      } else if (document['mozCancelFullScreen']) {
        await document['mozCancelFullScreen']();
      } else if (document['webkitCancelFullScreen']) {
        await document['webkitCancelFullScreen']();
      }
      this.onResize();
    }
    this.cd.detectChanges();
  }

  public isOneDisplayed(objects: Search.SearchObject[]) {
    return objects.filter((object) => object.url || object.zoomImage).length >= 1;
  }

  public isFaces(objects: Search.SearchObject[]) {
    return !!this._faces.length;
    // return objects.filter((object) => object.type === SearchObjectTypes.PERSON && object.zoomImage).length >= 1;
  }

  onPeriodClick(e: Event) {
    if (!this.searchEvents) {
      e.stopPropagation();
    }
    if (this.disableFocus) {
      return;
    }
    this.onTimePeriodChangeEvent.emit({ startTime: this.options.startTime, endTime: this.options.endTime });
  }

  public get containsPeople() {
    if (this.objects) {
      for(let object of this.objects) {
        if (!!object?.groupId) {
          return true;
        }
      }
    }
    return false;
  }

  public async addFace(object: Search.SearchObject) {
    const trackerId = object.id;
    const existingImage = this.bestImageUri(object?.zoomImage);
    this.peopleService.bucketForTracker(trackerId)
      .pipe()
      .subscribe(async (res) => {
        console.log(`bucket for tracker res`, res);
        const suggested: GroupModels.SuggestedPersonOrPersonId = res;
        const data: PeopleAddBucketDialogData = {
          suggested,
          existingImage,
        };
        this.dialog.open(PeopleAddBucketDialogComponent, {
            width: '400px',
            panelClass: 'modal-no-padding',
            data,
          })
          .afterClosed()
          .subscribe((groupId) => {
            if (groupId > 0) {
              object.groupId = groupId;
              this.store$.dispatch(PeopleActions.getPeople({ status: GroupStatus.Saved }));
            }
          });
      });
  }

  public addPerson(person: Person) {
    this.dialog.open(UiPersonDialogComponent, {
        height: '425px',
        width: '400px',
        panelClass: 'modal-no-padding',
        data: { person },
      })
      .afterClosed()
      .subscribe((name) => {
        if (name) {
          person.name = name;
          this.store$.dispatch(PeopleActions.addPerson({ person: { ...person, name } }));
        }
      });
  }

  getPeople() {
    const ids = this.objects.filter(obj => !!obj.groupId)
      .map(obj => obj.groupId);
    this.peopleService.getList(ids)
      .subscribe(res => {
        this.people = res;
      });
  }

  public addExisting(person: Person) {
    const data: UiPersonDialogData = {
      person,
      toExisting: true,
    };
    this.dialog.open(UiPersonDialogComponent, {
        height: '664px',
        width: '850px',
        maxWidth: '85vw',
        panelClass: 'modal-no-padding',
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (!!res) {
          const idx = this.objects.findIndex((object) => object.personId === res.bestTracker);
          this.objects[idx].personId = res;
          this.getPeople();
        }
      });
  }

  public getPerson(groupId: number) {
    return this.store$.select(PeopleSelectors.selectPersonById(groupId));
  }

  public getPersonName(personId: number) {
    return this.store$.select(PeopleSelectors.selectPersonNameById(personId));
  }

  public deleteFace(object: Search.SearchObject) {
    const trackerId = object?.id;
    this.peopleService.deleteTrackerFromPerson(trackerId)
      .pipe(catchError(err => {
        this.store$.dispatch(SharedActions.showMessage({ error: `Failed to remove person ${err}` }));
        return of();
      }))
      .subscribe(
        (res) => {
          this.store$.dispatch(PeopleActions.getPeople({ status: GroupStatus.Saved }));
          object.groupId = -1;
        },
      );
  }

  public deleteFromTracker(person: Person) {
    const idx = this.objects.findIndex((object) => object.personId === person.personId || object.groupId === person.personId);
    const object = this.objects[idx];
    this.peopleService.deleteFromTracker(person, object.idBase, object.idIndex)
      .subscribe((res) => {
        object.personId = null;
        const i = this.people.findIndex((p) => p.personId === person.personId);
        this.people.splice(i, 1);
        delete object?.groupId;
      });
  }

  public share() {
    this.dialog
      .open(ShareAccessComponent, {
        panelClass: 'thumbnail-dialog-wrapper',
        width: '502px',
        maxHeight: '802px',
        data: {
          title: 'alert',
          name: this.shareName,
          entityId: this.alertId,
          type: GrantedAccessType.ALERT,
        },
      })
      .afterClosed();
  }

  public seekEnd(event: TimelineDragEvent) {
    if (this.selectedSection === ThumbnailSection.VIDEO) {
      this.playback.startPlayback(event.ts);
    } else {
      this.currentTs = event.ts;
    }
    this.dragging = false;
  }

  // public pause(user = false, paused = false) {
  //   // if (!this.isLiveView) {
  //   //   this.currentTs += this.playback.videoCurrentTime * 1000;
  //   //   this.timestamp = this.currentTs;
  //   // }
  //   this.setMove();
  //   this.playback.pause(user, paused, this.currentTs);

  public pause(user = false) {
    this.videoService.OnPause();
  }

  public seekStart() {
    // this.playback.pause();
    this.dragging = true;

  }

  public timelineDrag(event: TimelineDragEvent) {
    if (event?.ts) {
      this.currentTs = event.ts;
      // this.playback.startPlayback(event.ts);
    }
    this.options.startTime = event.start;
    this.options.endTime = event.end;
    const now = Date.now();
    if (this.streamLiveView || now - this.timestamp < 10 * 60 * 1000) {
      if (this.thumbsStorageUpdateCounter === 0) {
        this.updateThumbnailsAndStats();
        this.thumbsStorageUpdateCounter = CACHE_UPDATE_FREQUENCY_SEC;
      } else {
        this.thumbsStorageUpdateCounter--;
      }
    }
    this.cd.detectChanges();

  }

  public timelineDragStart() {
    this.playback?.webrtcPlayer?.pauseVideo();
  }

  public timelineDragEnd(event: TimelineDragEvent) {
    if (this.selectedSection === ThumbnailSection.VIDEO) {
      this.streamLiveView = false;
      this.cd.detectChanges();
      // this.playback.startPlayback(event.ts ?? this.timestamp);
    }
    // this.mediaCacheService.getStorageOldestVids(this.selectedCameras.filter(camera => !!camera));
    if (!this.streamLiveView) {
      this.updateThumbnailsAndStats();
    }
  }

  public updateThumbnailsAndStats() {
    const baseStart = this.cameraThumbnailsService.getBaseInLocale(new Date(this.options.startTime));
    const baseEnd = this.cameraThumbnailsService.getBaseInLocale(new Date(this.options.endTime));
    const baseToday = this.cameraThumbnailsService.getBaseInLocale(new Date());

    if (baseStart !== this.baseStart || baseEnd !== this.baseEnd || baseStart === baseToday || baseEnd === baseToday) {
      this.baseStart = baseStart;
      this.baseEnd = baseEnd;
      const dayBefore = baseStart - DAY_IN_MSECS;
      this.mediaCacheService.getStorageStats(this.selectedCameras.filter(camera => !!camera), baseStart, baseEnd - 2000, this.isSharedAlert);
      // Get also the day before
      this.mediaCacheService.getStorageStats(this.selectedCameras.filter(camera => !!camera), dayBefore, baseStart - 2000, this.isSharedAlert);
      this.mediaCacheService.getThumbnails(this.selectedCameras.filter(camera => !!camera), baseStart, baseEnd - 2000, this.isSharedAlert);
      this.mediaCacheService.getThumbnailBits(this.selectedCameras.filter(camera => !!camera), baseStart, baseEnd - 2000, this.isSharedAlert);
    }
    this.setPeriodTitle();
    this.setData();
  }

  public async isOffline(time?: number) {
    const offline = await this.mediaCacheService.isOffline(this.edgeId, this.cameraId, time ?? this.currentTs);
    return offline;
  }

  public emulate() {
    if (!this.timeline) {
      return;
    }
    this.timeline.emulatePlayback();
  }

  public noMore(noMore: boolean) {
    if (!this.timeline) {
      return;
    }
    this.timeline.noMore = noMore;
  }

  public async noThumbnail(align20 = false) {
    const timestamp = align20 ? this.cameraThumbnailsService.normalizeTimestamp(this.timestamp) : this.timestamp;
    // const resolution = this.offsetResInDurations * THUMBNAIL_DIALOG_FREQUENCY;
    // const timestamp = this.normalizeTimestamp(this.timestamp, resolution);
    return await this.mediaCacheService.noThumbnail(this.edgeId, this.cameraId, timestamp);
  }

  public async getBitByTimestamp() {
    return await this.mediaCacheService.getBitByTimestamp(this.edgeId, this.cameraId, this.timestamp);
  }

  async hasThumb() {
    const { noThumbnail } = await this.noThumbnail(true);
    const hasBit = await this.getBitByTimestamp();
    return !noThumbnail || hasBit;
  }

  public async _img() {
    const now = Date.now();
    if (this.searchEvents?.length && !this.isDialog && this.timestamp === this.resetTimestamp) {
      this.offline = false;
    } else {
      this.offline = !(await this.hasThumb());
      if (this.offline) {
        this.failedImage++;
        if (this.failedImage >= FAILED_IMAGE_THRESHOLD) {
          this.fetchThumbsDebouncer.next();
          this.failedImage = 0;
        }
      }
    }
    const { isAlert, replicas } = await this.isAlert();
    let replica;
    if (isAlert) {
      // compute the replica according to offset between the 2 seconds and the number of replicas
      const offset = this.timestamp - this.normalizeTimestamp(this.timestamp, 2000);
      replica = Math.max(Math.floor(offset / 2000 * replicas.length), 0);
      let filename = replicas?.length ? replicas[replica] : this.buildFileName(this.normalizeTimestamp(this.timestamp, 2000), 0);
      this.displayTime = this.normalizeTimestamp(this.timestamp, 2000);
      const url = this.buildUrl(filename);
      return url;
    }
    const { noThumbnail, next } = await this.noThumbnail();
    const resolution = this.offsetResInDurations * THUMBNAIL_DIALOG_FREQUENCY;

    if (noThumbnail) {
      if (this.mediaCacheService.isTsAlignedTo20Seconds(this.timestamp)) {
        // this.offline = true;
        if (next) {
          let filename = this.buildFileName(this.normalizeTimestamp(next, resolution, true), 0);
          this.displayTime = this.normalizeTimestamp(next, resolution, true);
          const url = this.buildUrl(filename);
          return url;
        }
        return '';
      } else {
        // this.offline = false;
        return this.img;
      }
    }
    this.offline = false;
    let filename = this.buildFileName(this.normalizeTimestamp(this.timestamp, resolution), 0);
    this.displayTime = this.normalizeTimestamp(this.timestamp, resolution);
    const url = this.buildUrl(filename);
    return url;
  }

  public async isAlert() {
    return await this.mediaCacheService.isAlert(this.edgeId, this.cameraId, this.timestamp);
  }

  public get lessThanAMinute() {
    return (this.options.endTime - this.options.startTime) <= 120000;
  }

  normalizeTimestamp(timestamp: number, thumbnailsDuration?: number, ceil = false) {
    if (this.isDialog || this.alertPreview || this.searchEvents?.length || this.lessThanAMinute) {
      thumbnailsDuration = THUMBNAIL_DIALOG_FREQUENCY;
    }
    return this.cameraThumbnailsService.normalizeTimestamp(timestamp, thumbnailsDuration, ceil);
  }

  // normalizeTimestamp(timestamp: number, freq = 2000) {
  //   return this.cameraThumbnailsService.normalizeTimestamp(timestamp, freq);
  // }

  public imgStateChange(event: StateChange) {
    switch (event.reason) {
      case 'start-loading':
        this.imageLoader = true;
        // The image is in the viewport so the image will start loading
        break;
      case 'loading-succeeded':
        // The image has successfully been loaded and placed into the DOM
        this.latestImg = this.img;
        this.imageLoader = false;
        this.cd.detectChanges();
        // this.offline = false;
        break;
      case 'loading-failed':
        this.img = this.latestImg;
        this.imageLoader = false;
        // this.offline = true;
        break;
    }
  }

  public onLoaded(ev: number): void {
    this.viewPortHeight = ev - 44;//44 header height
    this.virtualScroll?.ngOnInit();
  }

  public userEmailByUserId(id: string) {
    return this.store$.select(OrganizationUsersSelectors.selectUserEmailById(id));
  }

  public get isCameraPlaybackPermission(): Observable<boolean> {
    if (this.checkPermissionEnabled) {
      return this.store$.pipe(select(PermissionSelectors.checkAccessPermissions([Permissions.CameraPlayback], [this.cameraId, this.edgeId, this.locationId])),
        take(1));
    } else {
      return of(true);
    }
  }


  public get isCameraPlaybackSharePermission(): Observable<boolean> {
    return this.store$.pipe(select(PermissionSelectors.checkAccessPermissions([Permissions.CameraPlaybackShare], [this.cameraId, this.edgeId, this.locationId])),
      take(1));
  }

  public get isCreateArchivePermission(): Observable<boolean> {
    return this.store$.pipe(select(PermissionSelectors.checkAccessPermissions([Permissions.ArchiveCreate], [])),
      take(1));
  }

  public toggleToLiveView(restart = true) {
    this.streamLiveView = true;
    this.videoService.setSpeed(1);
    if (this.isLiveView) {
      this.currentTs = Date.now();
      this.timestamp = this.currentTs;
    }
    const delta = this.options.endTime - this.options.startTime;
    this.options.startTime = this.currentTs - delta / 2;
    this.options.endTime = this.currentTs + delta / 2;
    this.optionsInput.startTime = this.currentTs - delta / 2;
    this.optionsInput.endTime = this.currentTs + delta / 2;
    this.percentage = 0.5;
    this.setMove();
    this.setData();
    this.cd.detectChanges();
    if (restart) {
      this.videoService.setLive(true);
      this.cd.detectChanges();
      this.videoService.OnPlay();
    }
  }

  public stopInactive() {
    this.playback.stopPlayback(true);
  }

  async checkHiddenDocument(visibilityChanged: VisibilityChanged) {
    this.windowHidden = visibilityChanged.hidden;
    let hideShowDelta = 0;
    if (this.windowHidden) {
      this.hideTs = Date.now();
    } else {
      this.showTs = Date.now();
      hideShowDelta = this.showTs - this.hideTs;
      if (this.streamLiveView) {
        // this.toggleToLiveView(false);
      }
    }
  }

  public get disableFocus() {
    return this.options?.endTime - this.options?.startTime <= 40000;
  }

}

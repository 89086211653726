import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocationModel } from '../../../../../../locations/location.model';
import { BehaviorSubject } from 'rxjs';
import { Edge } from '../../../../../../edge/edge.model';

@Component({
  selector: 'location-row',
  templateUrl: './location-row.component.html',
  styleUrl: './location-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationRowComponent implements OnInit {
  @Input() isExpanded: boolean = false;
  @Input() location: LocationModel.LocationItem;

  public isExpanded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isExpanded);

  public ngOnInit() {
    this.isExpanded$.next(this.isExpanded);
  }

  get edges(): Edge.EdgeItem[] {
    return Object.values(this.location.edges ?? {});
  }

  public toggleExpand() {
    const val = this.isExpanded$.getValue();
    this.isExpanded$.next(!val);
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, debounceTime, exhaustMap, mergeMap, share, switchMap, takeUntil, timeout, TimeoutError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AlarmActions } from '@states/alarm/alarm.action-types';
import { AlarmService } from '../../services/alarm.service';
import { AlertEventsService } from '../../development/alert-events.service';
import { ScheduleService } from '../../services/schedule.service';
import { SharedActions } from '@states/shared/shared.action-types';
import { withLatestFrom } from 'rxjs/operators';
import { AlarmEdgeSyncStatusWorkerService } from '../../services/alarm-edge-sync-status.worker.service';
import { getAlarmEdgeStatusSyncSuccess } from '@states/alarm/alarm.actions';

@Injectable()
export class AlarmEffects {

  public getAlerts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlarmActions.getAlerts),
      switchMap(() => {
          return this.alertEventsService.getAllNoPaging()
            .pipe(
              switchMap((res) => {
                return [
                  AlarmActions.getAlertsSuccess({ alertEvents: res }),
                ];
              }),
              catchError((res: HttpErrorResponse) => {
                const actions: Action[] = [
                  AlarmActions.getAlertsFail(),
                ];
                return actions;
              }),
            );
        },
      ),
      share(),
    ));

  public getScheduleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlarmActions.getScheduleList),
      switchMap(() => {
        return this.scheduleService.getList()
          .pipe(
            switchMap(res => {
              return [
                AlarmActions.getScheduleListSuccess({ documents: res }),
              ];
            }),
          );
      }),
      share(),
      catchError(response => {
        return [
          AlarmActions.getScheduleListFail(),
        ];
      }),
    ),
  );

  public startCreateSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlarmActions.startCreateAlarm),
      withLatestFrom(this.store$.pipe(select(state => state.alarmState))),
      switchMap(([{ document }, { selectedAlarmDocument }]) => {
        const actions: Action[] = [
          AlarmActions.setIsCreatingLoader({ isCreating: true }),
        ];
        if (selectedAlarmDocument) {
          actions.push(AlarmActions.updateAlarmServerCall({
            document: {
              ...selectedAlarmDocument,
              ...document,
            },
          }));
        } else {
          actions.push(AlarmActions.saveCreateServerCall({ document }));
        }
        return actions;

      }),
    ),
  );

  public saveAlarmServerCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlarmActions.saveCreateServerCall),
      switchMap(({ document }) => {
        return this.alarmService.create(document)
          .pipe(
            timeout(15000),
            switchMap(res => {
              return [
                AlarmActions.createAlarmServerCallSuccess(),
                SharedActions.showMessage({ success: 'Alarm has been created' }),
                AlarmActions.setIsCreatingLoader({ isCreating: false }),
              ];
            }),
            catchError((res: HttpErrorResponse) => {
              return [
                AlarmActions.createAlarmServerCallFail(),
                SharedActions.showMessage({ error: res?.error?.message ?? 'Alarm create failed' }),
                AlarmActions.setIsCreatingLoader({ isCreating: false }),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public getAlarms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlarmActions.getAlarms),
      withLatestFrom(this.store$.pipe(select(state => state.alarmState))),
      switchMap(([, { filters }]) => {
        return this.alarmService.getAlarms(filters)
            .pipe(
              switchMap((res) => {
                return [
                  AlarmActions.getAlarmsSuccess({ documents: res }),
                ];
              }),
              catchError((res: HttpErrorResponse) => {
                const actions: Action[] = [
                  AlarmActions.getAlarmsFail(),
                ];
                return actions;
              }),
            );
        },
      ),
      share(),
    ));


  public enableAlarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlarmActions.enableAlarm),
      mergeMap(({ alarmId, enabled }) => {
          return this.alarmService.enable(alarmId, enabled)
            .pipe(
              timeout(15000),
              mergeMap((res) => {
                return [
                  AlarmActions.enableAlarmSuccess({ alarmId, alarmDocument: res }),
                  SharedActions.showMessage({ success: `Alarm has been ${enabled ? 'enabled' : 'disabled'}` }),
                ];
              }),
              catchError((res: HttpErrorResponse) => {
                const actions: Action[] = [
                  SharedActions.showMessage({ error: res?.error?.message ?? `Alarm ${enabled ? 'enabled' : 'disabled'} failed` }),
                  AlarmActions.enableAlarmFail({ alarmId, enabled: !enabled }),
                ];
                return actions;
              }),
            );
        },
      ),
      share(),
    ));

  public deleteAlarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlarmActions.deleteAlarm),
      mergeMap(({ alarmId }) => {
          return this.alarmService.delete(alarmId)
            .pipe(
              timeout(15000),
              mergeMap((res) => {
                return [
                  AlarmActions.deleteAlarmSuccess({ alarmId }),
                  SharedActions.showMessage({ success: 'Alarm has been deleted' }),
                ];
              }),
              catchError((res: HttpErrorResponse) => {
                let msg = res?.error?.message ?? 'Alarm delete failed';
                if (res instanceof TimeoutError) {
                  msg = 'Timeout is occurred';
                }
                const actions: Action[] = [
                  SharedActions.showMessage({ error: msg }),
                  AlarmActions.deleteAlarmFail({ alarmId }),
                ];
                return actions;
              }),
            );
        },
      ),
      share(),
    ));


  public getScheduleById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlarmActions.getAlarmById),
      switchMap(({ id }) => {
        return this.alarmService.getAlarmById(id)
          .pipe(
            switchMap(res => {
              return [
                AlarmActions.getAlarmByIdSuccess({ document: res }),
              ];
            }),
            catchError(response => {
              return [
                AlarmActions.getAlarmByIdFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public updateAlarmServerCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlarmActions.updateAlarmServerCall),
      switchMap(({ document }) => {
        return this.alarmService.update(document)
          .pipe(
            timeout(15000),
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Alarm has been updated' }),
                AlarmActions.updateAlarmServerCallSuccess({ alarmId: document._id }),
                AlarmActions.setIsCreatingLoader({ isCreating: false }),
              ];
            }),
            catchError((res: HttpErrorResponse) => {
              let msg = res?.error?.message ?? 'Alarm update failed';
              if (res instanceof TimeoutError) {
                msg = 'Timeout is occurred';
              }
              return [
                SharedActions.showMessage({ error: msg }),
                AlarmActions.updateAlarmServerCallFail(),
                AlarmActions.setIsCreatingLoader({ isCreating: false }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public setFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlarmActions.setFilter),
      debounceTime(400),
      exhaustMap(() => [AlarmActions.resetEntities(), AlarmActions.getAlarms()]),
    ),
  );


  public createScheduleServerCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlarmActions.createScheduleServerCall),
      switchMap(({ schedule }) => {
        return this.scheduleService.create(schedule)
          .pipe(
            switchMap(res => {
              return [
                AlarmActions.createScheduleServerCallSuccess({ schedule: res }),
                SharedActions.showMessage({ success: 'Schedule has been created' }),
              ];
            }),
            catchError((res: HttpErrorResponse) => {
              return [
                SharedActions.showMessage({ error: res?.error?.message ?? 'Schedule creation failed' }),
                AlarmActions.createScheduleServerCallFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getAlarmEdgeStatusSync$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlarmActions.getAlarmEdgeStatusSync),
      mergeMap(({ alarmId }) => {
          return this.alarmEdgeSyncService.fetchData(alarmId)
            .pipe(
              mergeMap((res) => {
                return [
                  AlarmActions.getAlarmEdgeStatusSyncSuccess({ alarmId, data: res }),
                ];
              }),
              catchError((res: HttpErrorResponse) => {
                const actions: Action[] = [
                  AlarmActions.getAlarmEdgeStatusSyncFail(),
                ];
                return actions;
              }),
            );
        },
      ),
      share(),
    ));


  public syncAlarmCores$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlarmActions.syncAlarmCores),
      mergeMap(({ alarmId }) => {
          return this.alarmService.syncEdges(alarmId)
            .pipe(
              mergeMap((res) => {
                return [
                  SharedActions.doNothing(),
                ];
              }),
              catchError((res: HttpErrorResponse) => {
                const actions: Action[] = [
                  AlarmActions.syncAlarmCoresFail(),
                ];
                return actions;
              }),
            );
        },
      ),
      share(),
    ));

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private alarmService: AlarmService,
    private alertEventsService: AlertEventsService,
    private scheduleService: ScheduleService,
    private alarmEdgeSyncService: AlarmEdgeSyncStatusWorkerService,
  ) {
  }

  private catchError(response): string {
    return response?.message || response?.error?.message;
  }

}

import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { LocationsService } from '../locations.service';
import { ComplianceOption, LocationModel } from '../location.model';
import { catchError, concatMap, filter, map, Observable, startWith, Subscription, tap, throwError } from 'rxjs';
import { EdgeService } from '../../edge/edge.service';
import { Camera, CameraDeviceInfoInterface } from '../../cameras/camera.model';
import { MatDialog } from '@angular/material/dialog';
import { AddCameraComponent, AddCameraData, AddCameraDialogResult } from '../../edge/add-camera/add-camera.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AppState } from 'src/app/reducers';
import { select, Store } from '@ngrx/store';
import { LocationActions } from '@states/location/location.action-types';
import { EdgeActions } from '@states/edge/edge.action-types';
import { TokenDataStatus } from '../../core/messaging.interfaces';
import { SearchDevicesCameraDiscoveryToken } from '../../core/sessions/search-devices-camera-discovery-session';
import * as moment from 'moment';
import { LocationSelectors } from '@states/location/location.selector-types';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { GetEdgeIpAddressService } from '../../core/api/get-edge-ip-address.service';
import { Edge, EdgeHeartBeatStatus } from '../../edge/edge.model';
import { GetEdgeIpAddressToken } from '../../core/sessions/get-edge-ip-address-session';
import { EdgeStatusService } from '../../edge/edge-status.service';
import { SharedService } from '../../development/shared.service';
import * as _ from 'lodash';
import { UpdateEdgeToken } from '../../core/sessions/update-edge-session';
import { EdgeSettingsComponent } from '../edge-settings/edge-settings.component';
import { routerSegments } from '@consts/routes';
import { PulsationModels } from '@models/pulsation.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatTableDataSource } from '@angular/material/table';
import { UiBreadCrumbItem } from '@models/route.models';

export enum AddLocationStep {
  ADD_LOCATION,
  LOCATION_COMPLIANCE,
  ADD_EDGE,
  ADD_CAMERAS,
}

@UntilDestroy()
@Component({
  selector: 'app-location-add',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss'],
})
export class AddLocationComponent implements OnInit, OnDestroy {

  public loading = true;

  public routerSegments = routerSegments;
  @ViewChild('edgeSettings')
  edgeSettings: EdgeSettingsComponent;

  EdgeHeartBeatStatus: typeof EdgeHeartBeatStatus = EdgeHeartBeatStatus;
  discoverySubscription: Subscription;

  discoveryInSession = false;

  options$: Observable<LocationModel.AddLocationOptions | undefined>;
  AddLocationStep: typeof AddLocationStep = AddLocationStep;

  newLocationId: string;

  currentLocation: LocationModel.LocationItem | undefined;

  connectedEdgeId: string;
  connectedEdgeName: string;
  connectLoader = false;
  camerasLoader = true;
  discoveryError = false;
  connectedEdgeIdError: string;
  connectedEdgeIdErrorLastStatusCode: number;

  // Camera Discovery
  discoveredCamerasRaw: CameraDeviceInfoInterface[];
  discoveredCameras: Camera[] = [];
  displayedColumns: string[] = ['name', 'status', 'mac', 'ip', 'onvif', 'actions'];

  dataSource = new MatTableDataSource<Camera>([]);

  addedCameras = new Set<Camera>();
  alreadyAddedCameras: LocationModel.AddedCameras[] | undefined;

  @ViewChild('stepper')
  stepper: MatStepper;

  isLinear = true;
  addLocationFormGroup: UntypedFormGroup;
  addEdgeFormGroup: UntypedFormGroup;
  addCamerasFormGroup: UntypedFormGroup;
  lastToken;
  baseStep: AddLocationStep = AddLocationStep.ADD_LOCATION;

  // For testing purposes
  selectedIndex = 0;
  dev = false;
  tzNames: string[] = [];
  tzNamesFilter: Observable<string[]>;

  editLocation: boolean = false;
  editEdge: boolean = false;
  editEdgeWide: boolean = false;
  edge: Edge.EdgeDocument;
  edgeUpdate: Partial<Edge.EdgeDocument> = {};
  updateLoader = false;

  locationId: string;
  locationName: string;
  edgeId: string;
  edgeName: string;

  ipLoading = true;
  eth0;
  eth1;

  sendUpdate: boolean = false;

  subscriptions: Subscription[] = [];

  settingsOpened = false;

  breadCrumbs: UiBreadCrumbItem[] = [];

  public ComplianceOption = ComplianceOption;
  public ComplianceOptionKeys = Object.keys(ComplianceOption)
    .filter(key => !isNaN(Number(key)));

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private locationsService: LocationsService,
    private edgeService: EdgeService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private getEdgeIpAddressService: GetEdgeIpAddressService,
    private edgeStatusService: EdgeStatusService,
    private sharedService: SharedService,
  ) {
  }

  ngOnDestroy(): void {
    for(let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.tzNames.filter(option => option.toLowerCase()
      .includes(filterValue));
  }

  tzFormat(name: string) {
    return moment.tz(name)
      .format('Z z');
  }

  getEdgeStatus(edgeId: string): Observable<PulsationModels.ComponentStatus> {
    return this.edgeStatusService.getEdgePulsationStatus(edgeId);
  }

  getLocalIpAddresses() {
    const data: Edge.GetEdgeIpAddressRequest = {
      locationId: this.locationId,
      edgeId: this.edgeId,
    };
    return this.getEdgeIpAddressService
      .subscribeToGetEdgeIpAddressInfo(data)
      .pipe()
      .subscribe((session: GetEdgeIpAddressToken.AllSessionData) => {
        const ipAddresses: Edge.EdgeIpAddressInfo = session.result?.edgeIpAddressInfo;
        for(let key in ipAddresses) {
          if (key !== 'eth0' && key !== 'eth1') {
            continue;
          }
          const ip = ipAddresses[key][0].address;
          if (key === 'eth0') {
            this.eth0 = ip;
          }
          if (key === 'eth1') {
            this.eth1 = ip;
          }
        }
        this.ipLoading = false;
      });
  }

  setBreadCrumbs() {
    this.breadCrumbs = [];
    this.breadCrumbs.push({ name: 'Home', route: routerSegments.locationV2 });
    const base =
      this.currentLocation || this.locationName
        ? this.locationName
          ? this.locationName
          : this.currentLocation.name
        : this.editEdge
          ? 'Edit core'
          : 'Create location';
    this.breadCrumbs.push({ name: base });
    if (this.baseStep === AddLocationStep.ADD_EDGE) {
      this.breadCrumbs.push({ name: 'Add core' });
    }
    if (this.connectedEdgeName) {
      this.breadCrumbs.push({ name: this.connectedEdgeName });
    }
    if (this.baseStep === AddLocationStep.ADD_CAMERAS) {
      this.breadCrumbs.push({ name: 'Add cameras' });
    }
  }

  ngOnInit() {
    this.addLocationFormGroup = this._formBuilder.group({
      name: ['Untitled location', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', []],
      zip: ['', []],
      timezone: [moment.tz.guess(), Validators.required],
      contact: ['', []],
      phone: ['', []],
      complianceOption: ['0'],
      genderClassification: [true, []],
      faceRecognition: [true, []],
    });

    this.addLocationFormGroup.get('complianceOption')
      .valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (+value === ComplianceOption.INDEPENDENT) {
          this.addLocationFormGroup.patchValue({
            genderClassification: true,
            faceRecognition: true,
          });
        } else {
          this.addLocationFormGroup.patchValue({
            genderClassification: false,
            faceRecognition: false,
          });
        }
      });
    this.addEdgeFormGroup = this._formBuilder.group({
      name: ['Untitled edge', Validators.required],
      edgeId: [null, Validators.required],
      maxStorage: [''],
    });
    this.addCamerasFormGroup = this._formBuilder.group({
      cameras: ['', Validators.required],
    });

    this.tzNames = moment.tz.names();
    this.tzNamesFilter = this.addLocationFormGroup.get('timezone')!.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );

    const routeParams = this.route.params.subscribe(routeParams => {
      if (!!routeParams['locationId']) {
        this.locationId = routeParams['locationId'];
        if (!!routeParams['edgeId']) {
          this.edgeId = routeParams['edgeId'];
          this.editEdge = true;
          this.baseStep = AddLocationStep.ADD_EDGE;
          this.edgeId = routeParams['edgeId'];
          this.subscriptions.push(this.getLocalIpAddresses());
        } else {

          this.editLocation = true;
          this.baseStep = AddLocationStep.ADD_LOCATION;
        }

        if (this.editLocation) {

          this.store.pipe(select(LocationSelectors.selectLocationById(this.locationId!)))
            .subscribe(location => {
              this.locationName = location.name;
              this.addLocationFormGroup.patchValue({
                name: location.name,
                address: location.address,
                city: location.city,
                state: location.state,
                zip: location.zip,
                timezone: location.timezone,
                contact: location.contact,
                phone: location.phone,
              });
            });
        } else if (this.editEdge) {
          this.baseStep = AddLocationStep.ADD_EDGE;
          this.store.pipe(select(EdgeSelectors.selectEdgeById(this.edgeId!)))
            .subscribe(edge => {
              const { provisioned, confirmed, cameras, ...req } = edge;
              this.edge = req;
              this.edgeName = edge.name;
              this.addEdgeFormGroup.patchValue({
                name: edge.name,
                edgeId: edge.edgeId,
                maxStorage: edge.maxStorage,
              });
              this.addEdgeFormGroup.get('edgeId')
                .disable();
            });

          this.subscriptions.push(
            this.getEdgeStatus(this.edgeId)
              .subscribe(status => {
                if (status === PulsationModels.ComponentStatus.Online) {
                  this.addEdgeFormGroup.get('maxStorage')
                    .enable();
                  this.sendUpdate = true;
                } else {
                  this.addEdgeFormGroup.get('maxStorage')
                    .disable();
                  this.sendUpdate = false;
                }
              }),
          );

          this.subscriptions.push(
            this.addEdgeFormGroup.valueChanges.subscribe(() => {
              const dirty = this.sharedService.getDirtyValues(this.addEdgeFormGroup);
              this.edgeUpdate = dirty;
            }),
          );
        }
      }
    });

    this.options$ = this.locationsService.addLocationOptions$;

    this.options$.subscribe(options => {

      if (!!options?.step && !this.editEdge && !this.editLocation) {
        this.baseStep = options.step;
      }
      if (!!options?.location?._id) {
        this.currentLocation = options.location;
        this.newLocationId = options.location._id;
        this.loading = false;
      } else {
        this.loading = false;
        // this.router.navigate([routerSegments.locationV2]);
      }
      if (!!options?.edgeId) {
        this.connectedEdgeId = options.edgeId;
        this.connectedEdgeName = options.edgeName!;
        this.discoverCameras(true);
        this.alreadyAddedCameras = options.cameras;
        this.loading = false;
      } else {
      }

    });
    // For dev purposes
    if (this.dev) {
      this.selectedIndex = AddLocationStep.ADD_EDGE;
      this.newLocationId = '62309f45b6c09b5701a7e50d';
      this.connectedEdgeId = 'N0sitmQhJb';
    }

    this.setBreadCrumbs();
  }

  getExisting(camera: Camera) {
    for(let discovered of this.discoveredCameras) {
      if (discovered.ipAddress === camera.ipAddress) {
        return discovered;
      }
    }
    return undefined;
  }

  markCameras() {
    if (!!this.alreadyAddedCameras) {
      for(let camera of this.alreadyAddedCameras) {
        for(let discovered of this.discoveredCameras) {
          if (discovered.ipAddress === camera.ipAddress) {
            discovered.cameraId = camera.cameraId;
            discovered.name = camera.name;
            discovered.onvifCompliant = camera.onvifCompliant;
            this.addedCameras.add(discovered);
          }
        }
      }
      this.dataSource = new MatTableDataSource(this.discoveredCameras);
    }
  }

  addLocation(request: LocationModel.LocationCreateRequest) {
    if (this.editLocation) {
      request._id = this.locationId;
      return this.locationsService.updateLocation(request);
    }

    if (this.newLocationId) {
      request._id = this.newLocationId;
    }
    return this.locationsService.createLocation(request);
  }

  pollCreateEdge(token, edgeId) {
    this.edgeService
      .pollConfirmed(token)
      .pipe(
        catchError(error => {
          this.connectLoader = false;
          let errParsed;
          if (error.message === 'Timeout has occurred') {
            errParsed = { msg: 'Core confirmation timed out, please try again' };
          } else {
            errParsed = JSON.parse(error.message);
          }
          // TODO: Manage timeout error
          this.connectedEdgeIdError = errParsed.msg || 'Core confirmation timed out, please try again';
          this.connectedEdgeIdErrorLastStatusCode = error?.error?.statusCode || 500;
          return throwError(() => error);
        }),
      )
      .subscribe(res => {
        this.store.dispatch(EdgeActions.CreateLocationEdgeNoBackendCall({ request: res.result! }));
        this.connectLoader = false;
        this.store.dispatch(LocationActions.GetLocations());
        this.connectedEdgeId = edgeId;
        this.connectedEdgeName = this.addEdgeFormGroup.get('name')?.value;
        this.addEdgeFormGroup.disable();
      });
  }

  addEdgeToLocation() {
    this.connectedEdgeIdError = '';
    const request: LocationModel.AddEdgeToLocationRequest = this.addEdgeFormGroup.value;
    const edgeId = this.addEdgeFormGroup.get('edgeId')?.value;
    request.locationId = this.newLocationId;
    this.connectLoader = true;
    this.connectedEdgeIdError = '';
    if (this.connectedEdgeIdErrorLastStatusCode === 409 && this.lastToken) {
      this.pollCreateEdge(this.lastToken, edgeId);
      return;
    }
    this.locationsService
      .addEdgeToLocation(request)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          const e: HttpErrorResponse = err;
          this.connectLoader = false;
          this.connectedEdgeIdError = err.error.message;
          this.connectedEdgeIdErrorLastStatusCode = err.error.statusCode;

          return throwError(() => err);
        }),
      )
      .subscribe(sqsInfo => {
        this.lastToken = sqsInfo.token.session;
        this.pollCreateEdge(this.lastToken, edgeId);
      });
    return;
  }

  isSaveEnabled() {
    return this.stepper.selectedIndex === AddLocationStep.ADD_EDGE && this.connectedEdgeId;
  }

  numSteps() {
    return 3 - this.baseStep;
  }

  stepsArray() {
    return [...Array(this.numSteps())
      .keys()];
  }

  onCameraDiscoverySuccess(session: SearchDevicesCameraDiscoveryToken.AllSessionData) {
    const payload = session.result?.discoveredCameras!;
    const raw = JSON.parse(payload);

    this.discoveredCameras = raw.map((d: CameraDeviceInfoInterface) => {
      return {
        name: decodeURI(d.name),
        cameraId: decodeURI(d.macAddress),
        ipAddress: d.ipAddress,
        status: d.status,
        mac: d.macAddress,
        onvifData: {
          urn: d.urn,
          name: d.name,
          hardware: d.hardware,
          location: d.location,
          types: d.types,
          xaddrs: d.xaddrs,
          scopes: d.scopes,
        },
      } as Camera;
    });

    this.dataSource = new MatTableDataSource(this.discoveredCameras);
    this.markCameras();

    this.camerasLoader = false;
    this.discoveryError = false;
    this.discoveryInSession = false;
  }

  onCameraDiscoveryError(message = 'unknown error occured') {
    this.snackBar.open(message, '', { duration: 5000 });
    this.discoveryError = true;
    this.camerasLoader = false;
    this.discoveryInSession = false;
  }

  discoverCameras(sendDiscover = true) {
    if (sendDiscover) {
      this.discoveryError = false;
      this.camerasLoader = true;
      this.discoveryInSession = true;

      const dispatch$ = this.edgeService.discoverCameras(this.connectedEdgeId);
      const firebase$ = (token: string) =>
        this.edgeService.subscribeToSessionStatus(token)
          .pipe(
            map(res => {
              return { ...res, token: token };
            }),
          );
      const session$ = (token: string) =>
        this.edgeService.getSessionData<SearchDevicesCameraDiscoveryToken.AllSessionData>(token)
          .pipe(
            map(res => {
              return { ...res, token: token };
            }),
          );
      const delete$ = (token: string) => this.edgeService.deleteDocument(token);

      dispatch$
        .pipe(
          concatMap(res => firebase$(res.token.session)),
          filter(state => state?.status === TokenDataStatus.COMPLETED),
          concatMap(state => session$(state.token)),
          tap(session => this.onCameraDiscoverySuccess(session)),
          concatMap(state => delete$(state.token)),
        )
        .subscribe({
          complete: () => {
          },
          error: (err: HttpErrorResponse | Error) => {
            let msg = err instanceof HttpErrorResponse ? (err as HttpErrorResponse)?.error?.message : (err as Error)?.message;

            this.onCameraDiscoveryError(msg);
          },
        });
    }
  }

  addCamera(camera?: Camera) {
    let data: AddCameraData = {
      edgeId: this.connectedEdgeId,
      locationId: this.newLocationId,
    };

    if (camera) {
      data = { ...data, ...camera };
    }

    this.dialog
      .open(AddCameraComponent, {
        width: '540px',
        minHeight: '350px',
        data: data,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((res: AddCameraDialogResult) => {
        if (res.cameraId) {
          if (camera) {
            camera.name = res.name;
            camera.description = res.description;
            camera.cameraId = res.cameraId;
            this.addedCameras.add(camera);
            this.snackBar.open(`Camera ${camera.name} has been added to ${this.connectedEdgeName}`, 'Dismiss');
          } else {
            const camera: Camera = {
              name: res.name,
              description: res.description,
              cameraId: res.cameraId,
              ipAddress: res.ipAddress,
              mac: res.mac,
            };
            const existing = this.getExisting(camera);
            if (!!existing) {
              existing.name = res.name;
              this.addedCameras.add(existing);
            } else {
              this.discoveredCameras.push(camera);
              this.addedCameras.add(camera);
            }
            this.dataSource = new MatTableDataSource(this.discoveredCameras);
            this.markCameras();
            this.snackBar.open(`Camera ${res.name} has been added to ${this.connectedEdgeName}`, 'Dismiss');
          }
        }
      });
  }

  isAdded(camera) {
    return this.addedCameras.has(camera);
  }

  getCameraStatus(cameraId: string): Observable<PulsationModels.ComponentStatus> {
    return this.edgeStatusService.getCameraPulsationStatus(cameraId);
  }

  async back() {
    this.stepper.previous();
  }

  startEdgeUpdateSession(request: LocationModel.UpdateEdgeInLocationRequest) {
    this.updateLoader = true;
    const dispatch$ = this.locationsService.updateEdgeInLocation(request);
    const firebase$ = (token: string) =>
      this.edgeService.subscribeToSessionStatus(token)
        .pipe(
          map(res => {
            return { ...res, token: token };
          }),
        );
    const session$ = (token: string) =>
      this.edgeService.getSessionData<UpdateEdgeToken.AllSessionData>(token)
        .pipe(
          map(res => {
            return { ...res, token: token };
          }),
        );
    const delete$ = (token: string) => this.edgeService.deleteDocument(token);

    dispatch$
      .pipe(
        concatMap(res => firebase$(res.token.session)),
        filter(state => state?.status === TokenDataStatus.COMPLETED),
        concatMap(state => session$(state.token)),
        tap(session => {
          this.store.dispatch(
            EdgeActions.UpdateEdgeNoBackendCall({
              request,
            }),
          );
          this.snackBar.open(`Edge ${request.edge.name} updated successfully`, 'OK', { duration: 5000 });
          this.router.navigateByUrl(`location/page/${this.locationId}`);
        }),
        concatMap(state => delete$(state.token)),
      )
      .subscribe({
        complete: () => {
          this.updateLoader = false;
        },
        error: (err: HttpErrorResponse | Error) => {
          this.updateLoader = false;
          this.snackBar.open(`Edge ${request.edge.name} update failed`, 'OK', {
            duration: 5000,
          });
        },
      });
  }

  async next() {
    if (!this.stepper.selected?.stepControl.valid) {
      this.stepper.selected?.stepControl.markAllAsTouched();
      if (!(this.stepper.selectedIndex + this.baseStep === AddLocationStep.ADD_EDGE)) {
        return;
      }
    }
    switch (this.baseStep + this.stepper.selectedIndex) {
      case AddLocationStep.ADD_LOCATION:
        this.stepper.next();
        break;
      case AddLocationStep.LOCATION_COMPLIANCE:
        const request: LocationModel.LocationCreateRequest = this.addLocationFormGroup.value;
        this.addLocation(request)
          .subscribe(res => {
            this.store.dispatch(
              LocationActions.CreateLocationNoBackendCall({
                request: {
                  ...request,
                  _id: this.editLocation ? this.locationId : res._id,
                },
              }),
            );
            if (this.editLocation) {
              this.router.navigateByUrl(`location/page/${this.locationId}`);
            } else {
              this.locationsService.getLocations();
              this.newLocationId = res._id;
              this.currentLocation = { ...request, _id: res._id };
            }
            this.stepper.next();
            this.setBreadCrumbs();
          });
        break;
      case AddLocationStep.ADD_EDGE:
        if (this.editEdge) {
          const request: LocationModel.UpdateEdgeInLocationRequest = {
            sendUpdate: this.sendUpdate,
            edgeId: this.edgeId,
            locationId: this.locationId,
            name: this.addEdgeFormGroup.get('name').value,
            update: this.edgeUpdate,
            edge: _.merge(this.edge, this.edgeUpdate),
          };
          if (!this.sendUpdate) {
            this.locationsService.updateEdgeInLocation(request)
              .subscribe(() => {
                this.store.dispatch(
                  EdgeActions.UpdateEdgeNoBackendCall({
                    request,
                  }),
                );
                this.router.navigateByUrl(`location/page/${this.locationId}`);
              });
          } else {
            this.startEdgeUpdateSession(request);
          }
        }
        if (this.connectedEdgeId) {
          // this.discoverCameras(true);
          // this.stepper.next();
          // this.setBreadCrumbs();
          this.router.navigateByUrl('location');
        }
        break;
      case AddLocationStep.ADD_CAMERAS:
        this.router.navigateByUrl('location');
        break;
    }
  }

  stepEnabled(step: AddLocationStep) {
    return step >= this.baseStep;
  }

  cancelFinish() {
    if (this.currentLocation) {
      this.locationsService.setLocation(this.currentLocation);
      this.router.navigateByUrl(`location/page/${this.currentLocation._id}`);
    } else {
      if (this.editLocation || this.editEdge) {
        this.router.navigateByUrl(`location/page/${this.locationId}`);
      }
      this.router.navigateByUrl('location');
    }
  }

  settings(edgeId: string) {
    this.edgeSettings.load(edgeId);
    this.edgeSettings.selectedTabIndex = 0;
    this.settingsOpened = true;
  }

  // ngOnDestroy(): void {
  //   this.discoverySubscription.unsubscribe()
  // }
}

<div class="flex-row space-between-center camera-row c-p" hoverHint [hint]="hint">
  <div class="flex-row start-center gap-12">
    <div class="snapshot-wrapper">
      <ui-camera-status-snapshot [edgeId]="camera.edgeId"
                                 [cameraId]="camera.edgeOnly.cameraId"
                                 [showStatus]="false"
                                 [showName]="false"
                                 [iconSizePx]="16"
      ></ui-camera-status-snapshot>

    </div>
    <span>{{ camera.edgeOnly.name }}</span>
  </div>
  <ui-camera-status [cameraId]="camera.edgeOnly.cameraId"></ui-camera-status>
</div>


<ng-template #hint>
  <div class="hint">
    <ui-camera-status-snapshot [edgeId]="camera.edgeId"
                               [cameraId]="camera.edgeOnly.cameraId"
                               [showStatus]="false"
                               [showName]="false"
                               [iconSizePx]="40"
    ></ui-camera-status-snapshot>
  </div>
</ng-template>

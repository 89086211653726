import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, NgIf, NgOptimizedImage } from '@angular/common';
import { WallV2RoutingModule } from './wall-v2-routing.module';
import { WallV2ListComponent } from './pages/wall-v2-list/wall-v2-list.component';
import { WallV2CreateComponent } from './pages/wall-v2-create/wall-v2-create.component';
import { UiKitModule } from '../../shared/ui-kit/ui-kit.module';
import { LetDirective, PushPipe } from '@ngrx/component';
import { UiAccordionComponent } from './pages/wall-v2-create/components/ui-accordion/ui-accordion.component';
import { CdkDrag, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { SetLayoutComponent } from './pages/wall-v2-create/components/set-layout/set-layout.component';
import { MatSelectModule } from '@angular/material/select';
import { V2WallTileComponent } from './pages/wall-v2-create/components/v2-wall-tile/v2-wall-tile.component';
import { LocationRowComponent } from './pages/wall-v2-create/components/location-row/location-row.component';
import { CoreRowComponent } from './pages/wall-v2-create/components/core-row/core-row.component';
import { CameraRowComponent } from './pages/wall-v2-create/components/camera-row/camera-row.component';
import { Layout4Component } from './pages/wall-v2-create/components/layouts/layout4/layout4.component';
import { UiCameraSnapshotComponent } from '../../shared/ui-kit/ui-camera-snapshot/ui-camera-snapshot.component';
import { UiCameraStatusComponent } from '../../shared/ui-kit/ui-camera-status/ui-camera-status.component';
import { SharedModule } from '../../shared/shared.module';
import { Layout1Component } from './pages/wall-v2-create/components/layouts/layout1/layout1.component';
import { Layout2Component } from './pages/wall-v2-create/components/layouts/layout2/layout2.component';
import { Layout3Component } from './pages/wall-v2-create/components/layouts/layout3/layout3.component';
import { Layout5Component } from './pages/wall-v2-create/components/layouts/layout5/layout5.component';
import { Layout615Component } from './pages/wall-v2-create/components/layouts/layout615/layout615.component';
import { Layout624Component } from './pages/wall-v2-create/components/layouts/layout624/layout624.component';
import { Layout6Component } from './pages/wall-v2-create/components/layouts/layout6/layout6.component';
import { Layout734Component } from './pages/wall-v2-create/components/layouts/layout734/layout734.component';
import { Layout8Component } from './pages/wall-v2-create/components/layouts/layout8/layout8.component';
import { Layout9Component } from './pages/wall-v2-create/components/layouts/layout9/layout9.component';
import { Layout918Component } from './pages/wall-v2-create/components/layouts/layout918/layout918.component';
import { Layout1028Component } from './pages/wall-v2-create/components/layouts/layout1028/layout1028.component';
import { Layout12111Component } from './pages/wall-v2-create/components/layouts/layout12111/layout12111.component';
import { Layout12Component } from './pages/wall-v2-create/components/layouts/layout12/layout12.component';
import { Layout131centerComponent } from './pages/wall-v2-create/components/layouts/layout131center/layout131center.component';
import { Layout13112Component } from './pages/wall-v2-create/components/layouts/layout13112/layout13112.component';
import { Layout14212Component } from './pages/wall-v2-create/components/layouts/layout14212/layout14212.component';
import { Layout15114Component } from './pages/wall-v2-create/components/layouts/layout15114/layout15114.component';
import { Layout16Component } from './pages/wall-v2-create/components/layouts/layout16/layout16.component';
import { Layout20Component } from './pages/wall-v2-create/components/layouts/layout20/layout20.component';
import { Layout30Component } from './pages/wall-v2-create/components/layouts/layout30/layout30.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import {
  AlertsIncomingComponent,
} from './pages/wall-v2-create/components/alerts-incoming-v2/alerts-incoming.component';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { ResizableModule } from 'angular-resizable-element';
import { AlertTileComponent } from './pages/wall-v2-create/components/v2-wall-tile/components/alert-tile/alert-tile.component';
import {
  AlertActionRowComponent,
} from './pages/wall-v2-create/components/alerts-incoming-v2/alert-action-row/alert-action-row.component';
import {
  UiPlaybackErrorHandlerComponent,
} from '../../shared/ui-kit/ui-playback-error-handler/ui-playback-error-handler.component';
import { TileEventListComponent } from './pages/wall-v2-create/components/tile-event-list/tile-event-list.component';
import {
  UiCameraStatusSnapshotComponent,
} from '../../shared/ui-kit/ui-camera-status-snapshot/ui-camera-status-snapshot.component';
import { HoverHintDirective } from '../../shared/directives/hover-hint.directive';
import { SafeUrlPipe } from '../../pipes/safe-url.pipe';
import { YoutubePreviewPipe } from '../../pipes/youtube-preview.pipe';
import { RbacOrgAllowDirective } from '../../authorization/rbac-org-allow.directive';
import { UniqueAlertsPipe } from '../../pipes/unique-alerts.pipe';
import { AlertsPanelComponent } from './pages/wall-v2-create/components/alerts-panel/alerts-panel.component';
import {
  UiHeaderWithBreadcrumbsComponent,
} from '../../shared/ui-kit/ui-header-with-breadcrumbs/ui-header-with-breadcrumbs.component';


@NgModule({
  declarations: [
    WallV2ListComponent,
    WallV2CreateComponent,
    V2WallTileComponent,
    SetLayoutComponent,
    LocationRowComponent,
    CoreRowComponent,
    CameraRowComponent,
    Layout4Component,
    Layout1Component,
    Layout2Component,
    Layout3Component,
    Layout5Component,
    Layout615Component,
    Layout624Component,
    Layout6Component,
    Layout734Component,
    Layout8Component,
    Layout9Component,
    Layout918Component,
    Layout1028Component,
    Layout12111Component,
    Layout12Component,
    Layout131centerComponent,
    Layout13112Component,
    Layout14212Component,
    Layout15114Component,
    Layout16Component,
    Layout20Component,
    Layout30Component,
    AlertsIncomingComponent,
    AlertTileComponent,
    AlertActionRowComponent,
    AlertsPanelComponent,
  ],
  imports: [
    CommonModule,
    WallV2RoutingModule,
    UiKitModule,
    LetDirective,
    UiAccordionComponent,
    CdkDropListGroup,
    PushPipe,
    MatSelectModule,
    CdkDrag,
    CdkDropList,
    UiCameraSnapshotComponent,
    UiCameraStatusComponent,
    SharedModule,
    AsyncPipe,
    AsyncPipe,
    NgIf,
    NgIf,
    AsyncPipe,
    AsyncPipe,
    NgIf,
    NgIf,
    NgScrollbarModule,
    DirectivesModule,
    PipesModule,
    ResizableModule,
    UiPlaybackErrorHandlerComponent,
    NgOptimizedImage,
    TileEventListComponent,
    UiCameraStatusSnapshotComponent,
    HoverHintDirective,
    SafeUrlPipe,
    YoutubePreviewPipe,
    RbacOrgAllowDirective,
    UniqueAlertsPipe,
    UiHeaderWithBreadcrumbsComponent,
  ],
  exports: [
    V2WallTileComponent,
    SetLayoutComponent,
  ],
})
export class WallV2Module {
}

<ng-container *ngrxLet="selectIsDeveloper$; let isDeveloper">
  <ng-container *ngrxLet="selectDateTimeRange$; let dateTimeRange">
    <ng-container *ngrxLet="selectObjectSelectionsFormatted$; let objects">
      <div fxLayout="column" fxFlexFill class="search-side-view">
        <div class="ui-breadcrumb" fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center">
            <ng-container *ngIf="!savedSearchName">
              <div class="page">Search</div>
            </ng-container>
            <ng-container *ngIf="savedSearchName">
              <div class="link"
                   (click)="resetSaved()"
              >Search
              </div>
              <img class="arrow" src="assets/input/input-arrow-bottom.svg"/>
              <div class="link"
                   [routerLink]="['/', routerSegments.search, routerSegments.saved]"
              >Saved Search
              </div>
              <img class="arrow" src="assets/input/input-arrow-bottom.svg"/>
              <div class="page">{{ savedSearchName }}</div>
            </ng-container>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="search-side-actions">
         <span class="ui-icon-folder ui-mat-icon-button-16"
               [routerLink]="['/', routerSegments.search, routerSegments.saved]"
               [matTooltip]="'Open a saved search'"
               matTooltipClass="sidebar-tooltip"
         ></span>
            <span *ngIf="!savedSearchName"
                  class="ui-icon-save ui-mat-icon-button-16"
                  (click)="saveSearch()"
                  [matTooltip]="'Save search'"
                  matTooltipClass="sidebar-tooltip"
            >

        </span>
            <span *ngIf="savedSearchName"
                  class="ui-icon-save ui-mat-icon-button-16"
                  [matMenuTriggerFor]="saveSearchMenu"
            >
        </span>
            <mat-menu #saveSearchMenu="matMenu">
              <button
                mat-menu-item
                (click)="updateSearch()"
              >
                Save
              </button>
              <button
                mat-menu-item
                (click)="saveSearch()"
              >
                Save as new
              </button>
            </mat-menu>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" fxFlex>
          <!--        <div class="reset-filters-button">-->
          <!--          <ui-button type="noBorderPrimary" (click)="reset()">Reset filters</ui-button>-->
          <!--        </div>-->
          <div [class.expand]="sidebarExpand" [class.collapsed]="!sidebarExpand" class="sidebar">
            <div class="sidebar-inner" cdkScrollable>
              <span class="ui-icon-expand-arrow expand-sidebar flex-row center-center" (click)="toggleSidebar()"></span>
              <div class="menu-inner">
                <div *ngIf="false" class="menu-header">
                  <div fxLayout="column" class="workspace-widget-description">
                    <span class="title">Select filters</span>
                    <span class="description">Apply filters to the content.</span>
                    <ui-button class="mt-10 w-100"
                               type="default"
                               text="Reset filters"
                               (clickAction)="reset()"
                    >
                    </ui-button>
                  </div>
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    class="workspace-widget-description high-confidence"
                    fxLayoutGap="10px"
                    matTooltip="High Confidence search will only return objects that match the search criteria with high confidence"
                  >
                    <ui-toggle [(ngModel)]="highConfidence" (ngModelChange)="highConfidenceChange()"></ui-toggle>
                    <div style="color: #667085; font-size: 13px" [style.opacity]="highConfidence ? 1 : 0.5">High
                                                                                                            Confidence
                    </div>
                  </div>
                  <div
                    class="workspace-widget-description high-confidence"
                    fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"
                    style="border-top: 1px solid #eaecf0"
                    *ngIf="false"
                  >
                    <ui-toggle [(ngModel)]="useSingleStore"
                               (ngModelChange)="setUseSingleStore()"
                    ></ui-toggle>
                    <div style="color: #667085; font-size: 13px" [style.opacity]="highConfidence ? 1 : 0.5">Use
                                                                                                            SingleStore
                    </div>
                  </div>
                </div>
                <!-- OLD IMPLEMENTATION -->
                <!--            <mat-accordion class="menu-accordion level-2" [togglePosition]="'before'">-->
                <!--              <mat-expansion-panel>-->
                <!--                <mat-expansion-panel-header>-->
                <!--                  <mat-panel-title>Time range</mat-panel-title>-->
                <!--                  <mat-panel-description>-->
                <!--                    <div fxLayout="row" fxLayoutAlign="end center" class="w-100">-->
                <!--                    <span *ngIf="dateTimeRange?.relative && !!dateTimeRange?.relative?.value"-->
                <!--                    >Last {{ dateTimeRange?.relative?.value }} {{ dateTimeRange?.relative?.unit }}</span-->
                <!--                    >-->
                <!--                      <span *ngIf="dateTimeRange?.absolute && !!dateTimeRange?.absolute?.start"-->
                <!--                      >{{ dateTimeRange?.absolute?.start | date: 'MMM. dd, YY' }}-->
                <!--                        - {{ dateTimeRange?.absolute?.end | date: 'MMM. dd, YY' }}</span-->
                <!--                      >-->
                <!--                    </div>-->
                <!--                  </mat-panel-description>-->
                <!--                </mat-expansion-panel-header>-->
                <!--                <ui-calendar-inline-->
                <!--                  [isSaveEmit]="false"-->
                <!--                  [absoluteEnabled]="true"-->
                <!--                  [selectDateRange]="dateTimeRange"-->
                <!--                  (onRangeSelected)="onTimeRangeSelected($event)"-->
                <!--                ></ui-calendar-inline>-->
                <!--              </mat-expansion-panel>-->
                <!--              <mat-expansion-panel>-->
                <!--                <mat-expansion-panel-header>-->
                <!--                  <mat-panel-title> Camera</mat-panel-title>-->
                <!--                  <mat-panel-description>-->
                <!--                    <div fxLayout="row" fxLayoutAlign="end center" class="w-100">-->
                <!--                      {{selectedCameras.length ? selectedCameras.length + ' selected' : ''}}-->
                <!--                    </div>-->
                <!--                  </mat-panel-description>-->
                <!--                </mat-expansion-panel-header>-->
                <!--                <div fxLayout="column"-->
                <!--                     fxLayoutAlign="start center"-->
                <!--                     *ngIf="_reloadPicker"-->
                <!--                     class="camera-picker"-->
                <!--                >-->
                <!--                  <camera-picker-->
                <!--                    #cameraPicker-->
                <!--                    [inline]="true"-->
                <!--                    [multi]="true"-->
                <!--                    [displayActions]="false"-->
                <!--                    [displayTitle]="false"-->
                <!--                    [narrow]="true"-->
                <!--                    [emitOnSelect]="true"-->
                <!--                    [inputSelectedCameras]="selectedCameras"-->
                <!--                    (onSave)="onCamerasSelected($event)"-->
                <!--                    [zoneSelection]="true"-->
                <!--                    class="w-100"-->
                <!--                  ></camera-picker>-->
                <!--                </div>-->
                <!--              </mat-expansion-panel>-->
                <!--            </mat-accordion>-->
                <div class="flex-col filters gap-16">
                  <!--              <div class="label">Filters</div>-->
                  <!--              <div class="flex-row">-->
                  <!--                <mat-select class="ui-selector" [(ngModel)]="searchType" (ngModelChange)="searchTypeChanged()">-->
                  <!--                  <mat-option [value]="SearchType.AI">AI</mat-option>-->
                  <!--                  <mat-option [value]="SearchType.MOTION">Motion</mat-option>-->
                  <!--                  <mat-option [value]="SearchType.EVENT_TYPE">Event type</mat-option>-->
                  <!--                </mat-select>-->
                  <!--              </div>-->
                  <div class="flex-row">
                    <ng-container [ngSwitch]="searchType">
                      <ng-container *ngSwitchCase="SearchType.AI">
                        <mat-accordion class="menu-accordion level-2 w-100"
                                       [togglePosition]="'before'"
                                       [multi]="motionSearch || customEventSearch || unusualEventSearch "
                        >
                          <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <mat-panel-title>Time range</mat-panel-title>
                              <mat-panel-description>
                                <div fxLayout="row" fxLayoutAlign="end center" class="w-100">
                                  <ng-container *ngIf="dateTimeRange.type === pickerTypes.RELATIVE">
                                    <span *ngIf="dateTimeRange?.relative && !!dateTimeRange?.relative?.value"
                                    >Last {{ dateTimeRange?.relative?.value }} {{ dateTimeRange?.relative?.unit }}</span
                                    >
                                  </ng-container>
                                  <ng-container *ngIf="dateTimeRange.type === pickerTypes.ABSOLUTE">
                                    <span *ngIf="dateTimeRange?.absolute && !!dateTimeRange?.absolute?.start"
                                    >{{ dateTimeRange?.absolute?.start | date: 'MMM. dd, YY' }}
                                      - {{ dateTimeRange?.absolute?.end | date: 'MMM. dd, YY' }}</span
                                    >
                                  </ng-container>
                                </div>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                            <ui-calendar-inline
                              [isSaveEmit]="false"
                              [absoluteEnabled]="true"
                              [selectDateRange]="dateTimeRange"
                              [showAdvancedOption]="true"
                              (onRangeSelected)="onTimeRangeSelected($event)"
                            ></ui-calendar-inline>
                          </mat-expansion-panel>
                          <mat-expansion-panel  [class.disabled]="accessControlSearch ">
                            <mat-expansion-panel-header>
                              <mat-panel-title> Camera</mat-panel-title>
                              <mat-panel-description>
                                <div fxLayout="row" fxLayoutAlign="end center" class="w-100">
                                  {{ selectedCameras.length ? selectedCameras.length + ' selected' : '' }}
                                </div>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div fxLayout="column"
                                 fxLayoutAlign="start center"
                                 *ngIf="_reloadPicker"
                                 class="camera-picker"
                            >
                              <camera-picker
                                #cameraPicker
                                [inline]="true"
                                [multi]="true"
                                [displayActions]="false"
                                [displayTitle]="false"
                                [narrow]="true"
                                [emitOnSelect]="true"
                                [inputSelectedCameras]="selectedCameras"
                                (onSave)="onCamerasSelected($event)"
                                [zoneSelection]="true"
                                class="w-100"
                              ></camera-picker>
                            </div>
                          </mat-expansion-panel>
                          <app-object-attributes-content [class.disabled]="motionSearch || customEventSearch || unusualEventSearch  ||accessControlSearch"
                                                         #objectAttributesContent
                                                         [displayOperator]="false"
                                                         [panel]="true"
                                                         [searchPerson]="true"
                                                         (update)="updateObjects($event)"
                          ></app-object-attributes-content>
                          <!--                        <mat-expansion-panel [class.disabled]="motionSearch || customEventSearch || unusualEventSearch">-->
                          <!--                          <mat-expansion-panel-header>-->
                          <!--                            <mat-panel-title>Confidence</mat-panel-title>-->
                          <!--                            <mat-panel-description>-->
                          <!--                              <div fxLayout="row" fxLayoutAlign="end center" class="w-100">-->
                          <!--                    <span-->
                          <!--                    >{{ highConfidence ? 'High' : 'Regular' }}</span>-->
                          <!--                              </div>-->
                          <!--                            </mat-panel-description>-->
                          <!--                          </mat-expansion-panel-header>-->
                          <!--                          <div-->
                          <!--                              fxLayout="row"-->
                          <!--                              fxLayoutAlign="start center"-->
                          <!--                              class="high-confidence"-->
                          <!--                              fxLayoutGap="10px"-->
                          <!--                              matTooltip="High Confidence search will only return objects that match the search criteria with high confidence"-->
                          <!--                          >-->
                          <!--                            <ui-toggle [(ngModel)]="highConfidence"-->
                          <!--                                       (ngModelChange)="highConfidenceChange()"-->
                          <!--                            ></ui-toggle>-->
                          <!--                            <div style="color: #667085; font-size: 13px">High-->
                          <!--                              confidence-->
                          <!--                            </div>-->
                          <!--                          </div>-->
                          <!--                        </mat-expansion-panel>-->
                          <mat-expansion-panel
                            *ngIf="false && isDeveloper"
                            [class.disabled]="motionSearch || customEventSearch || unusualEventSearch ||accessControlSearch "
                          >
                            <mat-expansion-panel-header>
                              <mat-panel-title>Search V2</mat-panel-title>
                              <mat-panel-description>
                                <div fxLayout="row" fxLayoutAlign="end center" class="w-100">
                    <span
                    >{{ searchV2 ? 'Enabled' : 'Disabled' }}</span>
                                </div>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div
                              fxLayout="row"
                              fxLayoutAlign="start center"
                              class="high-confidence"
                              fxLayoutGap="10px"
                            >
                              <ui-toggle [(ngModel)]="searchV2"
                                         (ngModelChange)="searchV2Change()"
                              ></ui-toggle>
                              <div style="color: #667085; font-size: 13px">Search V2
                              </div>
                            </div>
                          </mat-expansion-panel>
                          <mat-expansion-panel
                            #motionPanel
                            (closed)="resetMotion()"
                            (opened)="setMotion()"
                            [class.disabled]="!!objects?.length || customEventSearch || unusualEventSearch || accessControlSearch"
                          >
                            <mat-expansion-panel-header>
                              <mat-panel-title>Motion</mat-panel-title>
                              <mat-panel-description>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="motion-wrapper">
                              <ui-motion (changed)="setMotion($event)"
                              ></ui-motion>
                            </div>
                          </mat-expansion-panel>
                          <ng-container *ngrxLet="selectAllCustomEvents$; let customEvents">
                            <mat-expansion-panel *ngIf="!!customEvents?.length" #eventTagsPanel
                                                 (closed)="resetCustomEventSearch()"
                                                 (opened)="setCustomEventSearch()"
                                                 [class.disabled]="!!objects?.length || motionSearch || unusualEventSearch || accessControlSearch"
                            >
                              <mat-expansion-panel-header>
                                <mat-panel-title>Event tags</mat-panel-title>
                                <mat-panel-description>

                                </mat-panel-description>
                              </mat-expansion-panel-header>
                              <div class="event-types-wrapper">
                                <ui-event-types (changed)="searchEventType($event)"
                                                [getEvents]="false"
                                ></ui-event-types>
                              </div>
                            </mat-expansion-panel>
                          </ng-container>
                          <!--         START Access Control-->
                          <ng-container *ngrxLet="selectAllAccessControlDoors$; let accessControlDoors">
                            <mat-expansion-panel *ngIf="!!accessControlDoors?.length" #accessControlPanel
                                                 (closed)="resetAccessControlSearch()"
                                                 (opened)="setAccessControlSearch()"
                                                 [class.disabled]="!!objects?.length || motionSearch || unusualEventSearch || customEventSearch "
                            >
                              <mat-expansion-panel-header>

                                <mat-panel-title> <div class="access-control-label">Access control</div>
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                              <div class="access-control-wrapper">
                                <ui-access-control-panel
                                  (selectionChange)="onAccessControlSelectionChange($event)"
                                ></ui-access-control-panel>
                              </div>
                            </mat-expansion-panel>
                          </ng-container>
                          <!--       END Access Control-->
                          <mat-expansion-panel #unusualPanel
                                               (closed)="resetUnusualEvent()"
                                               (opened)="setUnusualEvent()"
                                               [class.disabled]="!!objects?.length || customEventSearch || motionSearch  || accessControlSearch"
                          >
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <div>Unusual activity</div>
                                <div class="beta">Beta</div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="motion-wrapper">
                              <ui-unusual-event (changed)="setUnusualEvent($event)"
                              ></ui-unusual-event>
                            </div>
                          </mat-expansion-panel>
                          <mat-expansion-panel [class.disabled]="motionSearch || customEventSearch || unusualEventSearch ||accessControlSearch">
                            <mat-expansion-panel-header>
                              <mat-panel-title>Precision</mat-panel-title>
                              <mat-panel-description>
                                <div fxLayout="row" fxLayoutAlign="end center" class="w-100">
                    <span
                    >{{ precisionStr[precision] }}</span>
                                </div>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div
                              fxLayout="column"
                              fxLayoutAlign="start center"
                              class="high-confidence custom-slider precision p-r"
                              fxLayoutGap="16px"
                            >
                              <ngx-slider #slider fxLayout="row" fxFlex="" [(value)]="precision"
                                          [options]="precisionOptions"
                                          (userChangeEnd)="setPrecision()"
                              ></ngx-slider>
                              <div class="flex-row space-between-center w-100 ticks">
                                <div style="text-align: right">|</div>
                                <div style="text-align: center">|</div>
                                <div style="text-align: left">|</div>
                              </div>
                              <div class="flex-row space-between-center w-100">
                                <div>Low</div>
                                <div>Medium</div>
                                <div>High</div>
                              </div>
                            </div>
                          </mat-expansion-panel>
                          <mat-expansion-panel [class.disabled]="motionSearch || customEventSearch || unusualEventSearch||accessControlSearch ">
                            <mat-expansion-panel-header>
                              <mat-panel-title [style.width.px]="130"> Dwell</mat-panel-title>
                              <mat-panel-description>
                                <div fxLayout="row" fxLayoutAlign="end center" class="w-100">

                      <span *ngIf="(uiPeriod?.start || uiPeriod?.start === 0) && (uiPeriod?.end || uiPeriod?.end === 0)"
                      >
                        <ng-container
                          *ngIf="((uiPeriod?.unit === PeriodUnit.seconds || uiPeriod?.unit === PeriodUnit.minutes) && uiPeriod.end === 61) || (uiPeriod?.unit === PeriodUnit.hours && uiPeriod?.end === 25); else defaultPeriod"
                        >
                          {{ uiPeriod?.start }} {{ uiPeriod?.unit === PeriodUnit.hours ? 'Hrs' : (uiPeriod?.unit  | slice:0: 3) | titlecase }}
                          - Any
                        </ng-container>
                        <ng-template #defaultPeriod>
                          {{ uiPeriod?.start }}
                          - {{ uiPeriod?.end }} {{ uiPeriod?.unit === PeriodUnit.hours ? 'Hrs' : (uiPeriod?.unit  | slice:0: 3) | titlecase }}
                        </ng-template>
                        </span
                        >
                                </div>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                            <ui-period #uiPeriod [value]="period" (rangeChanged)="dwellChanged($event)"></ui-period>
                          </mat-expansion-panel>

                        </mat-accordion>
                      </ng-container>
                      <ng-container *ngSwitchCase="SearchType.MOTION">
                        <div class="flex-col w-100">
                          <ui-motion (changed)="setMotion($event)"
                          ></ui-motion>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="SearchType.EVENT_TYPE">
                        <ui-event-types (changed)="searchEventType($event)"
                                        [getEvents]="false"
                                        class="w-100"
                        ></ui-event-types>
                      </ng-container>
                    </ng-container>

                  </div>
                </div>


                <!--            <mat-expansion-panel *ngIf="isDev" (closed)="resetMotion()" (opened)="setMotion()">-->
                <!--              <mat-expansion-panel-header>-->
                <!--                <mat-panel-title>Motion</mat-panel-title>-->
                <!--                <mat-panel-description>-->
                <!--                </mat-panel-description>-->
                <!--              </mat-expansion-panel-header>-->
                <!--              <ui-motion (changed)="setMotion($event)"-->
                <!--              ></ui-motion>-->
                <!--            </mat-expansion-panel>-->
                <!--            <ng-container *ngrxLet="selectAllCustomEvents$; let customEvents">-->
                <!--              <mat-expansion-panel *ngIf="!!customEvents?.length" (closed)="resetCustomEventSearch()"-->
                <!--                                   (opened)="setCustomEventSearch()"-->
                <!--              >-->
                <!--                <mat-expansion-panel-header>-->
                <!--                  <mat-panel-title>Event type</mat-panel-title>-->
                <!--                  <mat-panel-description>-->

                <!--                  </mat-panel-description>-->
                <!--                </mat-expansion-panel-header>-->
                <!--                <ui-event-types (changed)="searchEventType($event)" [getEvents]="false"></ui-event-types>-->
                <!--              </mat-expansion-panel>-->
                <!--            </ng-container>-->
              </div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="" class="search-content-wrapper">
            <app-search-results
              #searchResults
              [thumbnailTemplate]="ThumbnailTemplate.TEMPLATE2"
              [showChips]="true"
              [sort]="sort"
              [timezone]="timezone"
              [multiSearch]="true"
              [startInput]="start"
              [endInput]="end"
              [maskDateChange]="maskDateChange"
              [active]="true"
              [motionSearch]="motionSearch"
              [unusualEventSearch]="unusualEventSearch"
              [accessDoorsSearch]="accessControlSearch"
              [customEventSearch]="customEventSearch"
              (refresh)="refresh()"
              (resetFilters)="reset()"
              class="h-100 w-100"
            ></app-search-results>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

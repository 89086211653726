import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl } from '@angular/forms';
import { AccessDoorsActions } from '@states/access-doors/access-doors.action-types';
import { Observable } from 'rxjs';
import { AccessDoorsModel } from '@models/access-doors.model';
import { AccessDoorsSelectors } from '@states/access-doors/access-doors.selector-types';
import { filter, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { Search } from '../../search.model';
import { CameraSelectors } from '@states/camera/camera.selector-types';

export interface LocationGroup {
  locationId: string;
  name: string;
  expanded: boolean;
  selected: boolean;
  doors: AccessDoorsModel.DocumentMongo[];
}



@UntilDestroy()
@Component({
  selector: 'ui-access-control-panel',
  templateUrl: './ui-access-control-panel.component.html',
  styleUrls: ['./ui-access-control-panel.component.scss']
})
export class UiAccessControlPanelComponent implements OnInit {
  @Output() selectionChange = new EventEmitter<Search.AccessControlSelectionChange>();

  public selectGeneaDoors$: Observable<AccessDoorsModel.DocumentMongo[]> =
    this.store$.pipe(select(AccessDoorsSelectors.selectAllDoors));

  public statusControl = new FormControl('open');
  public personNameControl = new FormControl('');
  public locations: LocationGroup[] = [];
  public selectedDoors: AccessDoorsModel.DocumentMongo[] = [];
  public query: string = '';

  // New properties for expanded state and image handling
  public expandedLocations = new Set<string>();
  public imageLoader: boolean = false;
  public imgLoadFailed: boolean = false;

  constructor(
    private store$: Store,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store$.dispatch(AccessDoorsActions.getOrganizationAccessDoors({}));

    // Subscribe to doors and organize them by location
    this.selectGeneaDoors$
      .pipe(
        filter(doors => !!doors?.length),
        take(1),
        untilDestroyed(this)
      )
      .subscribe(doors => {
        const groupedDoors = _.groupBy(doors, 'locationId');

        this.locations = Object.entries(groupedDoors).map(([locationId, doors]) => ({
          locationId,
          name: doors[0]?.locationName || '',
          expanded: false,
          selected: false,
          doors: doors
        }));

        // Expand first location by default
        if (this.locations.length > 0) {
          this.expandedLocations.add(this.locations[0].locationId);
        }
      });

    // Subscribe to form control changes
    this.statusControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.emitSelectionChange());

    this.personNameControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.emitSelectionChange());
  }

  // Location and door expansion methods
  public toggleExpand(locations: LocationGroup[]) {
    if (this.expandedLocations.size > 0) {
      this.expandedLocations.clear();
    } else {
      locations.forEach(location => this.expandedLocations.add(location.locationId));
    }
    this.cd.detectChanges();
  }

  public isExpanded(locationId: string): boolean {
    return this.expandedLocations.has(locationId);
  }

  public toggleLocation(location: LocationGroup) {
    if (this.isExpanded(location.locationId)) {
      this.expandedLocations.delete(location.locationId);
    } else {
      this.expandedLocations.add(location.locationId);
    }
    this.cd.detectChanges();
  }

  // Selection methods
  public isDoorSelected(door: AccessDoorsModel.DocumentMongo): boolean {
    return this.selectedDoors.some(selected => selected._id === door._id);
  }

  public locationSelected(location: LocationGroup): boolean {
    return location.doors.every(door => this.isDoorSelected(door));
  }

  public locationSomeSelected(location: LocationGroup): boolean {
    return location.doors.some(door => this.isDoorSelected(door)) &&
      !this.locationSelected(location);
  }

  public toggleLocationSelection(location: LocationGroup) {
    if (this.locationSelected(location)) {
      this.selectedDoors = this.selectedDoors.filter(selected =>
        !location.doors.some(door => door._id === selected._id)
      );
    } else {
      const doorsToAdd = location.doors.filter(door =>
        !this.isDoorSelected(door) && !this.doorDisabled(door)
      );
      this.selectedDoors = [...this.selectedDoors, ...doorsToAdd];
    }
    this.emitSelectionChange();
  }

  public toggleDoorSelection(door: AccessDoorsModel.DocumentMongo) {
    if (this.doorDisabled(door)) return;

    if (this.isDoorSelected(door)) {
      this.selectedDoors = this.selectedDoors.filter(d => d._id !== door._id);
    } else {
      this.selectedDoors = [...this.selectedDoors, door];
    }
    this.emitSelectionChange();
  }

  public allSelected(): boolean {
    const selectableDoors = this.getAllDoors().filter(door => !this.doorDisabled(door));
    return this.selectedDoors.length === selectableDoors.length && selectableDoors.length > 0;
  }

  public someSelected(): boolean {
    return this.selectedDoors.length > 0 && !this.allSelected();
  }

  public toggleSelectAll() {
    if (this.allSelected()) {
      this.selectedDoors = [];
    } else {
      const selectableDoors = this.getAllDoors().filter(door => !this.doorDisabled(door));
      this.selectedDoors = selectableDoors;
    }
    this.emitSelectionChange();
  }

  // Door state methods
  public doorDisabled(door: AccessDoorsModel.DocumentMongo): boolean {
    const selectedCameraIds = this.selectedDoors.map(d => d.cameras?.[0]?.cameraId);
    const doorCameraId = door.cameras?.[0]?.cameraId;
    return selectedCameraIds.includes(doorCameraId) && !this.isDoorSelected(door);
  }


  public getDoorImage(door: AccessDoorsModel.DocumentMongo):  Observable<string>  {
    return this.store$.select(CameraSelectors.getCameraSnapshotById(door.cameras[0]?.cameraId))
  }

  public imgStateChange(event: any) {
    switch (event.reason) {
      case 'start-loading':
        this.imageLoader = true;
        this.imgLoadFailed = false;
        break;
      case 'loading-succeeded':
        this.imageLoader = false;
        this.imgLoadFailed = false;
        break;
      case 'loading-failed':
        this.imageLoader = false;
        this.imgLoadFailed = true;
        break;
    }
    this.cd.detectChanges();
  }

  // Search and filtering methods
  public search(query: string) {
    this.query = query;
    // If there's a search query, expand all locations
    if (query) {
      this.locations.forEach(location => this.expandedLocations.add(location.locationId));
    }
    this.cd.detectChanges();
  }

  public filtered(doors: AccessDoorsModel.DocumentMongo[]) {
    if (!this.query) return doors;
    return doors.filter(door =>
      door.doorName.toLowerCase().includes(this.query.toLowerCase())
    );
  }

  // Helper methods
  private getAllDoors(): AccessDoorsModel.DocumentMongo[] {
    return this.locations.reduce((acc, location) => [...acc, ...location.doors], []);
  }

  private emitSelectionChange() {
    this.selectionChange.emit({
      doors: this.selectedDoors,
      status: this.statusControl.value || '',
      personName: this.personNameControl.value || ''
    });
  }
}

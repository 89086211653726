import { createAction, props } from '@ngrx/store';
import { AlertV2Document } from '@models/alerts-v2.model';
import { ScheduleModel } from '@models/schedule.model';
import { AlarmModel } from '@models/alarm.model';
import { Dictionary } from '@ngrx/entity/src/models';

export const resetToInitialState = createAction('[ALARM] Reset to Initial state');
export const resetEntities = createAction('[ALARM] Reset Entities');


export const getAlerts = createAction('[ALARM] Get Alerts');
export const getAlertsSuccess = createAction('[ALARM] Get Alerts Success', props<{
  alertEvents: AlertV2Document[]
}>());
export const getAlertsFail = createAction('[ALARM] Get Alerts Fail');


export const getScheduleList = createAction('[ALARM] Get Schedule List');
export const getScheduleListSuccess = createAction('[ALARM] Get Schedule ListSuccess', props<{
  documents: ScheduleModel.ScheduleDocument[]
}>());
export const getScheduleListFail = createAction('[ALARM] Get Schedule List Fail');

export const startCreateAlarm = createAction('[ALARM] Start Save Alarm',
  props<{
    document: AlarmModel.AlarmCreateDto
  }>());
export const saveCreateServerCall = createAction('[ALARM] Server Call Create Alarm',
  props<{
    document: AlarmModel.AlarmCreateDto
  }>());
export const createAlarmServerCallSuccess = createAction('[ALARM] Server Call Create Alarm Success');
export const createAlarmServerCallFail = createAction('[ALARM] Server Call Create Alarm Fail');

export const setIsCreatingLoader = createAction('[ALARM] Set Is Creating Loader', props<{
  isCreating: boolean
}>());

export const getAlarms = createAction('[ALARM] Get Alarms');
export const getAlarmsSuccess = createAction('[ALARM] Get Alarms Success', props<{
  documents: AlarmModel.AlarmDocument[]
}>());
export const getAlarmsFail = createAction('[ALARM] Get Alarms Fail');

export const enableAlarm = createAction('[ALARM] Toggle Enable', props<{
  alarmId: string,
  enabled: boolean
}>());

export const enableAlarmSuccess = createAction('[ALARM] Toggle Enable Success', props<{
  alarmId: string,
  alarmDocument: AlarmModel.AlarmDocument
}>());

export const enableAlarmFail = createAction('[ALARM] Toggle Enable Fail',
  props<{
    alarmId: string,
    enabled: boolean
  }>());

export const deleteAlarm = createAction('[ALARM] Delete Alarm',
  props<{
    alarmId: string,
  }>());

export const deleteAlarmSuccess = createAction('[ALARM] Delete Alarm Success',
  props<{
    alarmId: string,
  }>());

export const deleteAlarmFail = createAction('[ALARM] Delete Alarm Fail',
  props<{
    alarmId: string,
  }>());

export const getAlarmById = createAction('[ALARM] Get Alarm By Id',
  props<{
    id: string
  }>());

export const getAlarmByIdSuccess = createAction('[ALARM] Get Alarm By Id Success',
  props<{
    document: AlarmModel.AlarmDocument
  }>());

export const getAlarmByIdFail = createAction('[ALARM] Get Alarm By Id Fail');

export const updateAlarmServerCall = createAction('[ALARM] Server Call Update Alarm',
  props<{
    document: AlarmModel.AlarmDocument
  }>());
export const updateAlarmServerCallSuccess = createAction('[ALARM] Server Call Update Alarm Success',
  props<{
    alarmId: string
  }>());
export const updateAlarmServerCallFail = createAction('[ALARM] Server Call Update Alarm Fail');


export const setFilter = createAction('[ALARM] Set Filter',
  props<{
    field: string,
    value: string
  }>());


export const createScheduleServerCall = createAction('[ALARM] Create Schedule Server Call',
  props<{
    schedule: ScheduleModel.ScheduleCreateDto
  }>());

export const createScheduleServerCallSuccess = createAction('[ALARM] Create Schedule Server Call Success',
  props<{
    schedule: ScheduleModel.ScheduleDocument
  }>());

export const createScheduleServerCallFail = createAction('[ALARM] Create Schedule Server Call Fail');

export const getAlarmEdgeStatusSync = createAction('[ALARM] Get Alarm Edge Status Sync', props<{
  alarmId: string
}>());

export const getAlarmEdgeStatusSyncSuccess = createAction('[ALARM] Get Alarm Edge Status Sync Success',
  props<{
    alarmId: string,
    data: Dictionary<AlarmModel.AlarmEdgeStatus>
  }>());

export const getAlarmEdgeStatusSyncFail = createAction('[ALARM] Get Alarm Edge Status Sync Fail');

export const syncAlarmCores = createAction('[ALARM] Sync alarm cores', props<{
  alarmId: string
}>());

export const syncAlarmCoresSuccess = createAction('[ALARM] Sync alarm cores Success', props<{
  alarm: AlarmModel.AlarmDocument,
  data: Dictionary<AlarmModel.AlarmEdgeStatus>
}>());

export const syncAlarmCoresFail = createAction('[ALARM] Sync alarm cores Fail');

<ng-container *ngIf="{
collapsed:collapsed$|async
} as conditions"
>
  <div class="location">

    <div class="flex-row space-between-center c-p" (click)="toggleLocation();$event.stopPropagation()">
      <div class="flex-row start-center gap-16">
        @if (isMulti) {
          <div [class.disabled]="!edges.length">
            <mat-checkbox type="checkbox"
                          color="primary"
                          name="select-all"
                          [checked]="selected"
                          (click)="$event.stopPropagation()"
                          (change)="selectLocation($event)"
            ></mat-checkbox>
          </div>
        }

        <div class="flex-row start-center gap-8">
          <span class="ui-icon-location"></span>
          {{ location.name }}
        </div>

      </div>
      <span
        *ngIf="edges.length"
        class="ui-icon-expand-arrow"
        [class.rotated]="!conditions.collapsed"
      ></span>
    </div>
    <div *ngIf="!conditions.collapsed" class="edges flex-col gap-8" [class.mt-16]="edges.length">
      <camera-selector-edge-row *ngFor="let edge of edges; trackBy:trackBy"
                                [edge]="edge"
                                [selectedCameras]="selectedCameras"
                                [isCollapsed]="conditions.collapsed"
                                [disabledCameras]="disabledCameras"
                                [isMulti]="isMulti"
                                (onCameraSelected)="selectCamera($event)"
                                (onEdgeSelected)="selectEdge($event)"
      >

      </camera-selector-edge-row>
    </div>
  </div>
</ng-container>

import { select, Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, firstValueFrom, Observable, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppState } from '../store/app.state';
import { ACCESS_TOKEN_KEY, AUTH_PROVIDER_ID_KEY, AuthenticationService, EXPIRES_IN_KEY, ID_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../authentication/authentication.service';
import { SharedSelectors } from '@states/shared/shared.selector-types';
import { SharedActions } from '@states/shared/shared.action-types';
import { PublicRoutesRedirect, routerSegments } from '@consts/routes';
import { SocketEvents, SocketModels } from '../socket/socket.model';
import { LocationActions } from '@states/location/location.action-types';
import { CameraActions } from '@states/camera/camera.action-types';
import { UtilsService } from '../edge/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EdgeActions } from '@states/edge/edge.action-types';
import { SocketMainService } from '../socket/socket-main.service';
import { Auth0Service } from '../authentication/auth0.service';
import { AuthenticationActions } from '@states/authentication/authentication.action-types';
import { LocalStorageService } from '../core/local-storage.service';
import { ActiveOrganization } from '@models/organization.model';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import { AppleTvActions } from '@states/apple-tv/apple-tv.action-types';
import { Archive } from '@models/archive.model';
import { ArchiveActions } from '@states/archive/archive.action-types';
import { HttpStatusCode } from '@angular/common/http';

@UntilDestroy()
@Injectable()
export class AppConfig {
  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );

  constructor(
    private store$: Store<AppState>,
    private authenticationService: AuthenticationService,
    private socketMainService: SocketMainService,
    private utilsService: UtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private auth0Service: Auth0Service,
    private localStorageService: LocalStorageService,
  ) {
  }

  public async load(): Promise<any> {
    const route = window.location.href;
    console.log(route);
    try {
      // const authResponse = await this.authenticationService.extractAuthResponseFromUrlPromise();
      // switch (authResponse.type) {
      //   case AuthResponseFromUrl.EmailVerificationRequired:
      //     await this.router.navigate(['/email-verification'], { queryParams: { error: true, description: authResponse.msg } });
      //     break;
      //   case AuthResponseFromUrl.IpRulesRequired:
      //   case AuthResponseFromUrl.LoginRequired:
      //     this.authenticationService.logout(authResponse?.msg, authResponse?.code);
      //     break;
      //   case AuthResponseFromUrl.Success:
      //     if (authResponse.data) {
      //       this.auth0Service.afterTokenReceivedSuccess(authResponse.data);
      //       return true;
      //     }
      //     break;
      // }

      return await new Promise(async resolve => {
        const url = new URL(window.location.href);
        const code = url.searchParams.get('code');
        const msg = url.searchParams.get('msg');
        const errorDescription = url.searchParams.get('error_description'); //in cause of auth0
        const pathname = url.pathname;
        let redirectUrl = undefined;
        if (pathname?.indexOf(routerSegments.support) > -1) {
          redirectUrl = routerSegments.support;
        }

        if (code && !route.includes('integrations') && !route.includes(routerSegments.emailVerification)) {
          const authResult = await firstValueFrom(this.auth0Service.socialLoginGetTokenByCode(code));
          if (authResult && authResult.access_token && authResult.id_token) {
            const transformedAuthResult = { ...authResult, idToken: authResult.id_token, accessToken: authResult.access_token, refreshToken: authResult.refresh_token };
            this.auth0Service.afterTokenReceivedSuccess(transformedAuthResult);
          }
        } else {
          const pathname = url.pathname?.split('/');

          if (window?.location?.host?.includes('dev.lumix.ai')) {
            const newUrl = window.location.href.replace('dev.lumix.ai', 'dev.lumana.ai');
            window.location.href = newUrl;
            return;
          }

          if (window?.location?.host?.includes('staging.lumix.ai')) {
            const newUrl = window.location.href.replace('staging.lumix.ai', 'staging.lumana.ai');
            window.location.href = newUrl;
            return;
          }

          if (window?.location?.host?.includes('app.lumix.ai')) {
            const newUrl = window.location.href.replace('app.lumix.ai', 'app.lumana.ai');
            window.location.href = newUrl;
            return;
          }


          if (code?.toString() === '403') {
            resolve(true);
            return;
          }

          if (!this.auth0Service.isLoggedIn()) {
            if (pathname?.length >= 2 && PublicRoutesRedirect.includes(pathname[1])) {
              resolve(true);
            } else {
              this.authenticationService.clear();
              await this.router.navigate([routerSegments.auth], {
                queryParams: {
                  msg: msg || errorDescription,
                  redirectUrl,
                },
              });
              resolve(true);
            }
          } else {
            const accessToken = this.localStorageService.getItemWithCheck(ACCESS_TOKEN_KEY);
            const expiresAt = this.localStorageService.getItemWithCheck(EXPIRES_IN_KEY);
            const idToken = this.localStorageService.getItemWithCheck(ID_TOKEN_KEY);
            const authProviderId = this.localStorageService.getItemWithCheck(AUTH_PROVIDER_ID_KEY);
            const refreshToken = this.localStorageService.getItemWithCheck(REFRESH_TOKEN_KEY);

            if (accessToken && idToken && expiresAt) {
              this.store$.dispatch(
                AuthenticationActions.Login({
                  auth: {
                    idToken,
                    accessToken,
                    expiresAt,
                    authProviderId,
                    refreshToken,
                  },
                }),
              );
            }
          }
        }

        this.store$.pipe(
            select(SharedSelectors.selectIsApplicationLoaded))
          .pipe(
            untilDestroyed(this),
            filter(x => !!x),
          )
          .subscribe(_ => {
              resolve(true);
            },
          );
      });
    } catch (e) {
      this.authenticationService.logout();
    }
  }

  public socketsInit() {

    this.socketMainService.consume<void>(SocketEvents.managementForceLogoutMessage)
      .subscribe((res) => {
        this.store$.dispatch(AuthenticationActions.Logout({ reason: 'Socket managementForceLogoutMessage' }));
      });

    this.socketMainService.consume<{ data: Archive.ArchiveDocument, error: string, statusCode: HttpStatusCode }>(SocketEvents.edgeArchiveProgressNotification)
      .subscribe((res) => {
        this.store$.dispatch(ArchiveActions.archiveProgressSocketUpdate({ archive: res.data, error: res.error, statusCode: res.statusCode }));
      });

    this.socketMainService.consume<SocketModels.DeleteOrganizationExternalResponse>(SocketEvents.deleteOrgNotification)
      .subscribe((res) => {
        this.selectActiveOrganization$.pipe(take(1))
          .subscribe(activeOrg => {
            if (activeOrg.orgId === res.organizationId) {
              this.authenticationService.logout();
            }
          });

      });

    this.socketMainService.consume<SocketModels.CreateCameraExternalResponse>(SocketEvents.cameraCreatedNotification)
      .subscribe((res) => {
        const location = res?.location;
        if (location) {
          this.store$.dispatch(LocationActions.UpdateLocationNoBackendCall({ location: location }));
        }
        this.store$.dispatch(CameraActions.CreateLocationEdgeCameraSuccess({ payload: res.camera }));
        this.store$.dispatch(CameraActions.SetCameraSnapshotManually({ cameraId: res?.camera?.edgeOnly?.cameraId, url: `https://dib7tx0qzyy42.cloudfront.net/${res?.camera?.defaultSnapshot}` }));
        this.store$.dispatch(LocationActions.computeLocationLookup());

        if (res.authProviderId !== this.authenticationService.getAuthProviderIdFromLocalStorage()
          ?.toString()) {

          let message = `camera `;

          if (!!res?.camera?.edgeOnly?.name) {
            message = `${message.concat(res?.camera?.edgeOnly?.name)} `;
          }

          message = `${message.concat('was added')} `;

          if (!!res?.email) {
            message = message.concat(`by ${res.email} `);
          }

          this.store$.dispatch(SharedActions.showMessage({ success: message }));

          if (this.router.url === `/location/list`) {
            this.utilsService.setChanges(true);
          }
        }

      });


    this.socketMainService.consume<SocketModels.DeleteCameraExternalResponse>(SocketEvents.cameraDeletedNotification)
      .subscribe((res) => {
        this.store$.dispatch(CameraActions.DeleteCameraNoBackendCall({
            request: {
              locationId: res.locationId,
              edgeId: res.edgeId,
              cameraId: res.cameraId,
            },
          }),
        );

        this.store$.dispatch(EdgeActions.DeleteCameraSuccess({
          edgeId: res.edgeId,
          cameraId: res.cameraId,
        }));

        if (res.authProviderId !== this.authenticationService.getAuthProviderIdFromLocalStorage()
          ?.toString()) {


          let message = `camera `;

          if (!!res?.cameraName) {
            message = `${message.concat(res.cameraName)} `;
          }

          message = `${message.concat('was deleted')} `;

          if (!!res?.email) {
            message = message.concat(`by ${res.email} `);
          }

          this.store$.dispatch(SharedActions.showMessage({ success: message }));

          if (this.router.url === `/cameras/camera-view/${res.locationId}/${res.edgeId}/${res.cameraId}`) {
            this.router.navigate([routerSegments.locationV2, routerSegments.list]);
          }

          if (this.router.url === `/location/list`) {
            this.utilsService.setChanges(true);
          }
        }

      });

    this.socketMainService.consume<SocketModels.UpdateEdgeSwExternalResponse>(SocketEvents.edgeSwUpdateNotification)
      .subscribe();


    this.socketMainService.consume<SocketModels.DeleteEdgeExternalResponse>(SocketEvents.edgeDeletedNotification)
      .subscribe((res) => {

        const location = res?.location;
        if (location) {
          this.store$.dispatch(LocationActions.UpdateLocationNoBackendCall({ location: location }));
        }
      });


    this.socketMainService.consume<SocketModels.CreateEdgeExternalResponse>(SocketEvents.edgeCreatedNotification)
      .subscribe((res) => {

        const location = res?.location;
        if (location) {
          this.store$.dispatch(LocationActions.UpdateLocationNoBackendCall({ location: location }));
        }
      });


    this.socketMainService.consume<SocketModels.UpdateEdgeExternalResponse>(SocketEvents.edgeUpdatedNotification)
      .subscribe((res) => {

        const location = res?.location;
        if (location) {
          this.store$.dispatch(LocationActions.UpdateLocationNoBackendCall({ location: location }));
        }
      });


    this.socketMainService.consume<SocketModels.UpdateCameraExternalResponse>(SocketEvents.cameraUpdatedNotification)
      .subscribe((res) => {

        const location = res?.location;
        if (location) {
          this.store$.dispatch(LocationActions.UpdateLocationNoBackendCall({ location: location }));
        }
      });

    this.socketMainService.consume<any>(SocketEvents.cameraRebootNotification)
      .subscribe((res) => {
        console.log('SocketEvents.cameraRebootNotification', res);
      });

    this.socketMainService.consume<SocketModels.OnSwUpdateExternalResponse>(SocketEvents.onSwUpdateNotification)
      .subscribe((res) => {
        // this.reloadPage();
      });

    this.socketMainService.consume<SocketModels.CreateAppleTv.Response>(SocketEvents.appletvCreatedNotification)
      .subscribe((res) => {
        switch (res.state) {
          case 'COMPLETED':
            this.store$.dispatch(AppleTvActions.onSocketCreatedSuccess({ document: res.data }));
            break;
          case 'ERROR':
            this.store$.dispatch(AppleTvActions.onSocketCreatedError({ error: res.error, authProviderId: res.authProviderId }));
            break;
        }
      });

    this.socketMainService.consume<SocketModels.DeleteAppleTv.Response>(SocketEvents.appletvDeletedNotification)
      .subscribe((res) => {
        switch (res.state) {
          case 'COMPLETED':
            this.store$.dispatch(AppleTvActions.onSocketDeletedSuccess({ document: res.data, authProviderId: res.authProviderId }));
            break;
          case 'ERROR':
            this.store$.dispatch(AppleTvActions.onSocketDeletedFail({ error: res.error }));
            break;
        }
      });

    this.socketMainService.consume<SocketModels.ConfigureAppleTv.Response>(SocketEvents.appletvConfigureWallNotification)
      .subscribe((res) => {
        switch (res.state) {
          case 'COMPLETED':
            this.store$.dispatch(AppleTvActions.onSocketConfigureSuccess({ response: res.data, authProviderId: res.authProviderId }));
            break;
          case 'ERROR':
            this.store$.dispatch(AppleTvActions.onSocketConfigureFail({ error: res.error, authProviderId: res.authProviderId, edgeId: res.edgeId }));
            break;
        }
      });

    this.socketMainService.consume<SocketModels.AppleTvWall.Response>(SocketEvents.appletvGetWallNotification)
      .subscribe((res) => {
        switch (res.state) {
          case 'COMPLETED':
            this.store$.dispatch(AppleTvActions.onSocketGetWallSuccess({ wall: res.data.wall, edgeId: res.edgeId, isEqual: res.data.isEqual }));
            break;
          case 'ERROR':
            this.store$.dispatch(AppleTvActions.onSocketGetWallFail({ error: res.error }));
            break;
        }
      });
  }

}

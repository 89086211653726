<div class="ui-modal">
  <div class="body gap-24">
    <img src="assets/lumana_logo.svg" style="text-align: center; width: 132px"/>
    <div class="text">
      Version {{ version }}
    </div>
    <div class="flex-row inner">
      <div class="flex-col space-evenly-center w-50">
        <ui-button text="Terms of use" type="default" (clickAction)="openTerms()"></ui-button>
        <ui-button text="Privacy policy" type="default" (clickAction)="openPrivacy()"></ui-button>
      </div>
      <div class="vl"></div>
      <div class="flex-col space-evenly-center w-50">
        <div class="text">Scan to download our app</div>
        <div>
          <img src="assets/mobile_app_qr.png" class="qr">
        </div>
      </div>
    </div>
    <div class="copyright">
      Copyright 2025 Lumana
    </div>
  </div>
  <div class="footer">
    <div class="w-100 flex-row end-center">
      <ui-button type="primary" text="Close" (clickAction)="close()"></ui-button>
    </div>
  </div>
</div>
